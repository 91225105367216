<template>
  <div class="content">
    <el-table :data="dataList"
              :header-cell-style="{
        backgroundColor: '#F2F6FC',
        color: '#909399',
        fontSize: '14px',
      }"
              :cell-style="{ fontSize: '13px' }"
              border>
      <el-table-column prop="id"
                       label="ID"
                       align="center"
                       width="80">
        <template slot-scope="scope">
          {{ scope.row.id ? scope.row.id : "-" }}
        </template>
      </el-table-column>
      <el-table-column prop="year"
                       label="年份"
                       align="center">
        <template slot-scope="scope">
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              {{ scope.row.year }}年<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="2024">2024年</el-dropdown-item>
              <el-dropdown-item command="2023">2023年</el-dropdown-item>
              <el-dropdown-item command="2022">2022年</el-dropdown-item>
              <el-dropdown-item command="2021">2021年</el-dropdown-item>
              <el-dropdown-item command="2020">2020年</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
      <!-- <el-table-column
          prop="college_name"
          label="院校"
          align="left"
          :show-overflow-tooltip="true"
          width="120"
        >
          <template slot-scope="scope">
            <span class="pointer" @click="institutionOpen(scope.row)">
              {{ scope.row.college_name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="major_name"
          label="专业"
          align="left"
          width="180"
        >
          <template slot-scope="scope">
            {{ `(${scope.row.major_code})${scope.row.major_name}` }}
          </template>
        </el-table-column>
        <el-table-column
          prop="academy_name"
          label="学院"
          :show-overflow-tooltip="true"
          align="left"
          width="150"
        >
        </el-table-column> -->
      <el-table-column prop="academy_name"
                       label="方向"
                       align="left">
        <template slot-scope="scope">
          <div v-if="show">
            <!-- multiple -->
            <el-select v-model="form.directions"
                       placeholder="请选择方向"
                       clearable
                       style="width: 150px">
              <el-option v-for="(item, index) in directionOptions"
                         :key="index"
                         :label="
                  item.direction == '不限'
                    ? '不限'
                    : `(${item.direction_code})${item.direction}`
                "
                         :value="
                  item.direction == '不限'
                    ? ''
                    : `(${item.direction_code})${item.direction}`
                ">
              </el-option>
            </el-select>
            <i style="margin-left: 5px"
               class="el-icon-finished"
               @click="affirm(scope.row)"></i>
          </div>
          <div v-else>
            {{ scope.row.directions ? scope.row.directions : "不限" }}
            <i style="margin-left: 5px"
               class="el-icon-edit-outline"
               @click="amend()"></i>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="user_count_first"
                       label="一志愿招生人数"
                       align="center">
        <template slot-scope="scope">
          <div v-if="show">
            <el-input v-model="scope.row.user_count_first"
                      placeholder="请输入"
                      style="width: 100px"></el-input>
            <i style="margin-left: 5px"
               class="el-icon-finished"
               @click="affirm(scope.row)"></i>
          </div>
          <div v-else>
            {{
              scope.row.user_count_first || scope.row.user_count_first == 0
                ? `${scope.row.user_count_first}人`
                : "-"
            }}
            <i style="margin-left: 5px"
               class="el-icon-edit-outline"
               @click="amend()"></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="high_score"
                       label="一志愿最高分"
                       align="center">
        <template slot-scope="scope">
          <div v-if="show">
            <el-input v-model="scope.row.high_score"
                      placeholder="请输入"
                      style="width: 100px"></el-input>
            <i style="margin-left: 5px"
               class="el-icon-finished"
               @click="affirm(scope.row)"></i>
          </div>
          <div v-else>
            {{ scope.row.high_score ? `${scope.row.high_score}分` : "-" }}
            <i style="margin-left: 5px"
               class="el-icon-edit-outline"
               @click="amend()"></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="low_score"
                       label="一志愿最低分"
                       align="center">
        <template slot-scope="scope">
          <div v-if="show">
            <el-input v-model="scope.row.low_score"
                      placeholder="请输入"
                      style="width: 100px"></el-input>
            <i style="margin-left: 5px"
               class="el-icon-finished"
               @click="affirm(scope.row)"></i>
          </div>
          <div v-else>
            {{ scope.row.low_score ? `${scope.row.low_score}分` : "-" }}
            <i style="margin-left: 5px"
               class="el-icon-edit-outline"
               @click="amend()"></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="user_count_adjust"
                       label="调剂人数"
                       align="center">
        <template slot-scope="scope">
          <div v-if="show">
            <el-input v-model="scope.row.user_count_adjust"
                      placeholder="请输入"
                      style="width: 100px"></el-input>
            <i style="margin-left: 5px"
               class="el-icon-finished"
               @click="affirm(scope.row)"></i>
          </div>
          <div v-else>
            {{
              scope.row.user_count_adjust || scope.row.user_count_adjust == 0
                ? `${scope.row.user_count_adjust}人`
                : "-"
            }}
            <i style="margin-left: 5px"
               class="el-icon-edit-outline"
               @click="amend()"></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="academy_name"
                       label="参考书目"
                       align="center"
                       width="300"
                       :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <div @click="dialogOpen()"
               style="width: 100%;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
            {{ scope.row.reference_books || "-" }}
            <!-- {{ scope.row.reference_books_exist_status == 1 ? "有" : "-" }} -->
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="academy_name"
                       label="考试大纲"
                       align="center"
                       width="100">
        <template slot-scope="scope">
          <div @click="dialogOpen()">
            {{ scope.row.exam_outline_exist_status == 1 ? "有" : "-" }}
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="提示"
               :visible.sync="dialogVisible"
               width="500px">
      <el-form :model="form"
               label-position="right"
               ref="form"
               label-width="80px"
               size="small">
        <el-form-item :label="'参考书目' + (index + 1)"
                      v-for="(item, index) in form.reference_books"
                      :key="index">
          <el-input style="width: 300px"
                    v-model.trim="item.name"
                    placeholder="请输入参考书目"></el-input>
          <el-button type="primary"
                     style="margin-left: 5px"
                     @click="bookAdd"
                     v-if="index == 0">添加</el-button>
          <el-button type="danger"
                     style="margin-left: 5px"
                     @click="delBook(index)"
                     v-if="index != 0">删除</el-button>
        </el-form-item>

        <el-form-item label="考试大纲">
          <el-upload :limit="2"
                     :file-list="fileList"
                     class="upload-demo"
                     drag
                     accept=".docx ,.pdf"
                     :action="action"
                     multiple
                     :on-success="handleAvatarSuccess"
                     :on-remove="imgListRemove">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__tip"
                 slot="tip">只能上传docx/pdf文件</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="affirm()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  collegeMajorRecruit,
  collegeMajorRecruitAdd,
  collegeMajorRecruitPut,
  collegeMajorRecruitDetail,
} from '@/request/api'
import { imgUploadUrl } from '@/request/http'
import { adminUrl } from '@/utils/helper.js'
export default {
  props: {
    admixtureData: {
      default: {},
    },
  },
  data() {
    return {
      query: {
        isPaging: 0,
        years: 2023,
      },
      form: {
        directions: '',
        reference_books: [
          {
            name: '',
          },
        ],
      },
      dataList: [],
      action: imgUploadUrl(),
      dialogVisible: false,
      show: false,
      directionOptions: [],
      fileList: [],
    }
  },
  created() {
    // console.log("Data",this.admixtureData)
    // this.getDataList();
  },
  watch: {
    admixtureData: {
      handler(val) {
        // console.log("valllll",val)
        this.query = {
          ...this.admixtureData,
          ...this.query,
        }
        this.getDataList()
        this.getMajorInfo(this.query.college_code, this.query.major_code)
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    dialogOpen(flag) {
      let data = this.dataList[0]
      if (!this.dataList[0].id) {
        return
      }

      collegeMajorRecruitDetail({ id: data.id }).then((res) => {
        if (res.data.code == 0) {
          let data = res.data.data
          if (data.reference_books) {
            this.form.reference_books = data.reference_books
              .split(',')
              .map((item) => {
                return { name: item }
              })
          } else {
            this.form.reference_books = [
              {
                name: '',
              },
            ]
          }
          if (flag) {
            if (data.reference_books) {
              this.$set(
                this.dataList[0],
                'reference_books',
                data.reference_books
              )
            }
          } else {
            if (data.exam_outline_file_urls) {
              this.fileList = data.exam_outline_file_urls
                .split(',')
                .map((item, index) => {
                  return {
                    name: item,
                    uid: index,
                    url: item,
                  }
                })
            } else {
              this.fileList = []
            }
            this.dialogVisible = true
          }
        }
      })

      //   console.log(data);
    },

    affirm() {
      let data = this.dataList[0]
      let ex = /^([0]|[1-9][0-9]*)$/
      let ex2 = /^[1-9](\d*)$/
      if (!data.user_count_first) {
        this.$message.error('一志愿招生人数不能为空')
        return
      } else if (!ex2.test(data.user_count_first)) {
        this.$message.error('一志愿招生人数请输入正整数')
        return
      } else if (data.high_score && !ex.test(data.high_score)) {
        this.$message.error('一志愿最高分请输入不为0的正整数')
        return
      } else if (data.low_score && !ex.test(data.low_score)) {
        this.$message.error('一志愿最低分请输入不为0正整数')
        return
      } else if (Number(data.high_score) < Number(data.low_score)) {
        this.$message.error('一志愿最低分不能大于一志愿最高分')
        return
      } else if (data.user_count_adjust && !ex2.test(data.user_count_adjust)) {
        this.$message.error('调剂人数请输入正整数')
        return
      }

      let dataForm = {
        year: data.year,
        enrol_type: 1,
        college_code: this.admixtureData.college_code,
        academy_code: this.admixtureData.academy_code,
        major_code: this.admixtureData.major_code,
        user_count_first: data.user_count_first,
        user_count_adjust: data.user_count_adjust,
        low_score: data.low_score,
        high_score: data.high_score,
        user_count:
          Number(data.user_count_first) + Number(data.user_count_adjust || 0),
        subjects: [],
        directions: this.form.directions,
        // directions: this.form.directions.filter((item) => item).join(","),
        reference_books: this.form.reference_books
          .map((item) => item.name)
          .join(','),
        exam_outline_file_urls: this.fileList.map((item) => item.url).join(','),
      }
      //   console.log(data, "data");
      //   console.log(dataForm, "dataForm");
      if (data.id) {
        collegeMajorRecruitPut({
          ...dataForm,
          enrol_type: data.enrol_type,
          id: data.id,
        }).then((res) => {
          if (res.data.code == 0) {
            this.clear()
            this.getDataList()
            this.$message.success('修改成功！')

            this.dialogVisible = false
            this.show = false
          }
        })
      } else {
        collegeMajorRecruitAdd(dataForm).then((res) => {
          if (res.data.code == 0) {
            this.clear()
            this.getDataList()
            this.$message.success('新增成功！')

            this.show = false
          }
        })
      }
      //   this.show = !this.show;
    },

    clear() {
      this.form = {
        directions: '',
        reference_books: [
          {
            name: '',
          },
        ],
      }
    },

    amend() {
      console.log('form', this.form)
      //   if (this.dataList[0].directions) {
      //     this.$set(
      //       this.form,
      //       "directions",
      //       this.dataList[0].directions
      //     );
      //   }
      //   if(!this.form.directions||this.form.directions.length<0){
      //    this.form,
      //       "directions",
      //       ['']
      //   }
      this.show = !this.show
    },
    handleCommand(command) {
      this.show = false
      this.$set(this.dataList[0], 'year', command)
      this.query.years = command
      this.getDataList()
      //   this.$message("click on item " + command);
    },
    getDataList() {
      collegeMajorRecruit(this.query).then((res) => {
        if (res.data.code == 0) {
          let list = res.data.data.list

          if (list.length > 0) {
            list = list.filter((item) => item.enrol_type == 1)
          }
          if (list.length > 0) {
            list = list.filter((item) => {
              if (item.directions) {
                return item.directions
                  .split(',')
                  .map((its) => its.substr(1, 2))
                  .includes(this.query.direction_code)
              } else {
                return item.id
              }
            })
          }
          console.log('list', list)
          if (list.length <= 0) {
            this.clear()
            this.dataList = [
              {
                year: this.query.years,
              },
            ]
          } else {
            let directionsList = list.filter((item) => item.directions)
            // console.log("directionsList",directionsList)
            if (directionsList.length > 0) {
              this.dataList = [directionsList[0]]
              this.$set(
                this.form,
                'directions',
                `(${this.admixtureData.direction_code})${this.admixtureData.direction_name}`
              )
            } else {
              this.dataList = [list[0]]
            }
            this.dialogOpen(true)
          }

          //   console.log(this.dataList, "dataList");
          //   enrol_type
        }
      })
    },

    getMajorInfo(college_code, major_code) {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/major/info`,
        method: 'get', //可以省略 不写默认是get
        params: {
          college_code: college_code,
          major_code: major_code,
        },
      }).then((response) => {
        let academyOptions = response.data.data.academys
        this.directionOptions = academyOptions.find(
          (item) => item.academy_code == this.query.academy_code
        ).directions
        if (!this.directionOptions.some((item) => item.direction == '不限')) {
          this.directionOptions.unshift({
            direction: '不限',
            direction_code: '',
          })
        }
        // console.log("ppp", this.directionOptions);
      })
    },

    bookAdd() {
      if (this.form.reference_books.length < 20) {
        this.form.reference_books.push({
          name: '',
        })
      } else {
        this.$message.error('最多只能添加20本')
      }
    },
    delBook(index) {
      this.form.reference_books.splice(index, 1)
    },

    handleAvatarSuccess(res, file, fileList) {
      this.fileList.push({
        name: res.data.name,
        url: res.data.url,
        uid: file.uid,
      })
      // console.log("fileList",this.fileList)
    },
    imgListRemove(file, fileList) {
      this.fileList = this.fileList.filter((item) => item.uid != file.uid)
      // console.log("fileList",this.fileList)
    },
  },
}
</script>

<style lang="less" scoped>
.content {
  padding-top: 20px;
}
</style>