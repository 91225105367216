import { xor } from "lodash";
import md5 from "md5-node";
import moment from 'moment'
import Vue from 'vue'

/* math.js解决计算精度问题 */
const $math = require('mathjs')
export const math = {
  add() {
    return comp('add', arguments)
  },
  subtract() {
    return comp('subtract', arguments)
  },
  multiply() {
    return comp('multiply', arguments)
  },
  divide() {
    return comp('divide', arguments)
  }
}

function comp(_func, args) {
  let t = $math.chain($math.bignumber(args[0]))
  for (let i = 1; i < args.length; i++) {
    t = t[_func]($math.bignumber(args[i]))
  }
  // 防止超过6位使用科学计数法
  return parseFloat(t.done())
}
/* math.js解决计算精度问题 */

// 权限判断
export function computePermission(url) {
  const permissions = JSON.parse(localStorage.getItem('permissions'))
  if (!permissions || !permissions.length) {
    return false
  }
  return !!permissions.find(v => typeof url === 'string' ? url == v.url : url.test(v.url))
}

// 按钮权限判断
export function buttonPermission(code) {
  const permissions = JSON.parse(localStorage.getItem('permissions'))
  if (!permissions || !permissions.length) {
    return false
  }
  return !!permissions.find(v => typeof code === 'string' ? code == v.code : code.test(v.code))
}


// 签名算法 
export function signmd5(data) {
  let arr = [];
  for (var key in data) {
    arr.push(key)
  }
  arr.sort();
  let str = 'fl8O5PonxcWBXrd9' // 密钥，不要改动
  for (var i in arr) {
    str += data[arr[i]]
  }
  let string = str.replace(',', '')
  return md5(string)
}

// 服务状态判断(外包订单)
export function formateServiceStatus(val) {
  let obj = {
    "0": "无",
    "1": "待定校",
    "2": "待服务",
    "3": "正在对接",
    "4": "正在匹配",
    "5": "匹配成功",
    "6": "匹配失败",
    "7": "开始上课",
    "8": "正在上课",
    "9": "课程结束",
    "10": "课程终止",
  }
  return obj[val] || ''
}


// 服务状态判断(代签订单)
export function formateServiceStatusDaiqian(val) {
  let obj = {
    "0": "无",
    "1": "待推送",
    "2": "正在对接",
    "3": "正在匹配",
    "4": "匹配成功",
    "5": "订单完成",
    "6": "订单取消",
  }
  return obj[val] || ''
}

// 服务状态判断(代找订单)
export function formateServiceStatusDaiZhao(val) {
  let obj = {
    "0": "无",
    "1": "待推送",
    "2": "开始执行",
    "3": "正在匹配",
    "4": "匹配成功",
    "5": "订单完成",
    "6": "订单取消",
  }
  return obj[val] || ''
}

// 支付状态判断
export function formatePaytag(val) {
  let obj = {
    "0": "未收",
    "1": "已收",
    "2": "已退款",
  }
  return obj[val] || ''
}


// 连接老系统对象格式化（外包订单）
export function formateObj(item, type) {
  const data = {
    "id": item.old_id || null,
    "newId": item.id,
    "company": item.company.company_name || '',
    "status": formateServiceStatus(item.service_status),
    "year": Number(item.apply_year),
    "studentName": item.student_realname || '',
    "studentMobile": item.student_phone || '',
    "studentWechat": item.student_wechat || '',
    "studentQq": item.student_qq || '',
    "college": item.apply_college_name || '',
    "department": `(${item.apply_academy_code})${item.apply_academy_name}`,
    "specialty": `(${item.apply_major_code})${item.apply_major_name}`,
    "subjects": item.subjects.map((v) => { return `(${v.subject_code})${v.subject_name}` }),
    "hours": item.service_hours || '',
    "others": item.remark || '',
    "createdAt": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
  }
  if (type == 'create') {
    delete data.id
  }
  return data
}





// 连接老系统对象格式化（代签订单）
export function formateObjDaiqian(item, type) {
  const data = {
    "id": item.old_id || null,
    "newId": item.id,
    "company": item.company.company_name || '',
    "status": formateServiceStatusDaiqian(item.status),
    "year": Number(item.apply_year),
    "studentName": item.student_name || '',
    "college": item.apply_college_name || '',
    "department": `(${item.apply_academy_code})${item.apply_academy_name}`,
    "specialty": `(${item.apply_major_code})${item.apply_major_name}`,
    "subjects": item.subjects.map((v) => { return `(${v.subject_code})${v.subject_name}` }),
    "hours": item.hours || '',
    "others": item.remark || '',
    "createdAt": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
  }
  if (type == 'create') {
    delete data.id
  }
  return data
}


// 连接老系统对象格式化（代找订单）
export function formateObjDaiZhao(item, type) {
  const data = {
    "id": item.old_id || null,
    "newId": item.id,
    "company": item.company.company_name || '',
    "status": formateServiceStatusDaiZhao(item.status),
    "price": item.order_amount,
    "find_hours": 72,
    "paytag": formatePaytag(item.payment_status),
    "college": item.apply_college_name || '',
    "department": `(${item.apply_academy_code})${item.apply_academy_name}`,
    "specialty": `(${item.apply_major_code})${item.apply_major_name}`,
    "subjects": item.subjects.map((v) => { return `(${v.subject_code})${v.subject_name}` }),
    "others": item.remark || '',
    "createdAt": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    "remark": item.order_no,
  }
  if (type == 'create') {
    delete data.id
  }
  return data
}

// 支付状态判断
export function formateGradesStatus(val) {
  let obj = {
    "0": "正在对接",
    "1": "正在上课",
    "2": "课程终止",
    "3": "课程结束",
  }
  return obj[val] || ''
}


// 连接老系统对象格式化（班级）
export function formateGradeInfo(item, type) {
  const data = {
    "id": item.old_id || null,
    "newId": Number(item.id) + Number(item.new_id_offset),
    "company": '测试公司',
    "year": Number(item.apply_year) || "",
    "studentName": item.name,
    "studentWechat": '',
    "studentQq": '',
    "college": item.apply_college_name,
    "department": `(${item.apply_academy_code})${item.apply_academy_name}`,
    "specialty": `(${item.apply_major_code})${item.apply_major_name}`,
    "subjects": item.apply_subjects && item.apply_subjects.length ? item.apply_subjects.map((v) => { return `(${v.subject_code})${v.subject_name}` }) : [],
    "others": '班级同步数据',
    "remark": '班级同步数据',
    "hours": 1,
    "createdAt": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    "status": formateGradesStatus(item.status),
    "studentMobile": "1",
  }
  if (type == 'create') {
    delete data.id
  }
  return data
}


export function adminUrl() {
  // 环境判断
  if (location) {
    if (location.host.includes('manage.yanshizi.com')) {
      return `${window.location.protocol}//api.kaoyanbox.com`
    } else {
      return `${window.location.protocol}//api.kaoyanbox.com`
      // return `${window.location.protocol}//test-api.kaoyanbox.net`
    }
  }
}

export function crmUrl() {
  // 环境判断
  if (location) {
    if (location.host.includes('manage.yanshizi.com')) {
      return `${window.location.protocol}//crm-api.yanshizi.com`
    } else {
      return `${window.location.protocol}//crm-api.yanshizi.com`
      // return `${window.location.protocol}//crm-api.kaoyanbox.net`
    }
  }
}

export function digitUppercase(number) {
  let ret = ''
  if (number !== '' && number != null && number !== '0') {
    let unit = '仟佰拾亿仟佰拾万仟佰拾元角分'
    let str = ''
    number += '00'
    const point = number.indexOf('.')
    if (point >= 0) {
      number = number.substring(0, point) + number.substr(point + 1, 2)
    }
    unit = unit.substr(unit.length - number.length)
    for (let i = 0; i < number.length; i++) {
      str += '零壹贰叁肆伍陆柒捌玖'.charAt(number.charAt(i)) + unit.charAt(i)
    }
    return str.replace(/零(仟|佰|拾|角)/g, '零').replace(/(零)+/g, '零').replace(/零(万|亿|元)/g, '$1').replace(/(亿)万|(拾)/g, '$1$2').replace(/^元零?|零分/g, '').replace(/元$/g, '元') + '整'
  }
}

// 存储本地信息
export function setStorage(key, value) {
  if (typeof value == 'object') {
    value = JSON.stringify(value)
  }
  localStorage.setItem(key, value)
}
// 查询本地信息
export function getStorage(key) {
  let value = localStorage.getItem(key)
  let value2 = value
  try {
    value2 = JSON.parse(value)
  } catch (error) {
    value2 = value
  }
  return value2
}
// 删除本地信息
export function removeStorage(key) {
  localStorage.removeItem(key)
}


// 本地和线上地址判断
export function hostUrl() {
  const product = 'https://manage-api.yanshizi.com'
  const daily = 'https://manage-api.kaoyanbox.net'
  if (location) {
    if (location.host.includes('manage.yanshizi.com')) {
      return product
    } else if (location.host.includes('manage.kaoyanbox.net')) {
      if (location.host.includes('test')) {
        return daily
      }
      return daily
    } else {
      return daily
    }
  }
}

// export default {}.install = (Vue, options = {}) => {
//   Vue.directive('loadmore', {
//     inserted(el, binding) {
//             const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
//             SELECTWRAP_DOM.addEventListener('scroll', function() {
//                 const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
//                 if (condition) {
//                     binding.value();
//                 }
//             });
//         }
//   })
// }