<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>师资</el-breadcrumb-item>
        <el-breadcrumb-item>订单管理</el-breadcrumb-item>
        <el-breadcrumb-item>匹配详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
      </div>
    </div>
    <div class="main">
      <div class="modular"
           style="margin-right: 12px;width: 58%;height: 100%;">
        <div class="popover-content"
             v-if="materialShow">
          <div class="item"
               v-for="(item, index) in materialsList"
               :key="index"
               @click="chooseMaterial(item)"
               :class="{ 'choose': chooseId == item.id }">{{ item.name }}</div>
        </div>

        <el-card style="height: 100%;">
          <el-tabs v-model="active"
                   @tab-click="tabClick">
            <el-tab-pane label="拟录取名单"
                         name="first">
              <div class="tab-modular"
                   v-if="nameList.length">
                <el-table :data="nameList"
                          :header-cell-style="{
                  backgroundColor: '#F2F6FC',
                  color: '#909399',
                  fontSize: '14px',
                }"
                          :cell-style="{ fontSize: '13px' }"
                          border
                          v-loading="loading">
                  <el-table-column label="学院名称"
                                   align="center "
                                   width="200"
                                   show-overflow-tooltip>
                    <template slot-scope="scope">
                      {{ scope.row.recruit_academy_code ? `(${scope.row.recruit_academy_code})` : '' }}{{
                        scope.row.recruit_academy_name && scope.row.recruit_academy_name != 'null' ? scope.row.recruit_academy_name : ''
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column label="专业名称"
                                   align="center "
                                   width="200"
                                   show-overflow-tooltip>
                    <template slot-scope="scope">
                      ({{ scope.row.recruit_major_code }}){{ scope.row.recruit_major_name }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="exam_student_name"
                                   label="姓名"
                                   align="center "
                                   width="150"
                                   show-overflow-tooltip>
                  </el-table-column>
                  <el-table-column prop="first_exam_score"
                                   label="初试分数"
                                   align="center "
                                   width="100"
                                   show-overflow-tooltip>
                    <template slot-scope="scope">
                      {{
                        scope.row.first_exam_score && scope.row.first_exam_score != 'null' ? Number(scope.row.first_exam_score).toFixed(2) : 0
                      }}分
                    </template>
                  </el-table-column>
                  <el-table-column prop="second_exam_score"
                                   label="复试分数"
                                   align="center "
                                   width="100"
                                   show-overflow-tooltip>
                    <template slot-scope="scope">
                      {{
                        scope.row.second_exam_score && scope.row.second_exam_score != 'null' ? Number(scope.row.second_exam_score).toFixed(2) : 0
                      }}分
                    </template>
                  </el-table-column>
                  <el-table-column prop="total_exam_score"
                                   label="综合成绩"
                                   align="center "
                                   width="100"
                                   show-overflow-tooltip>
                    <template slot-scope="scope">
                      {{
                        scope.row.total_exam_score && scope.row.total_exam_score != 'null' ? Number(scope.row.total_exam_score).toFixed(2) : 0
                      }}分
                    </template>
                  </el-table-column>
                </el-table>
                <div style="margin-top: 16px">
                  <div class="block">
                    <el-pagination background
                                   layout="total, prev, pager, next, sizes, jumper"
                                   :current-page="Number(query.pageIndex)"
                                   :page-sizes="[10, 20, 50, 100, 500]"
                                   :page-size="Number(query.pageSize)"
                                   :total="Number(pageTotal)"
                                   @size-change="handleSizeChange"
                                   @current-change="handleCurrentChange"></el-pagination>
                  </div>
                </div>
              </div>
              <template v-else>
                <div class="material-content"
                     v-if="Object.keys(material).length && JSON.parse(material.content)[0]">
                  <template v-if="material.content_type == 1">
                    <iframe ref="btnId"
                            style="width:100%;"
                            :frameborder="0"
                            height="800"
                            :src="material.content"
                            class="iframe"
                            :srcdoc="`<a target='_blank' href='${material.content}'>此网址不支持内嵌打开，点击跳转原链接</a>`">
                    </iframe>

                  </template>
                  <template v-if="material.content_type == 2">
                    <iframe style="width:100%;"
                            :frameborder="0"
                            height="800"
                            :src="JSON.parse(material.content)[0].url"
                            class="iframe"
                            v-if="JSON.parse(material.content)[0].url.indexOf('.pdf') != -1"></iframe>
                    <iframe style="width:100%;"
                            :frameborder="0"
                            height="800"
                            :src="baseurl + JSON.parse(material.content)[0].url"
                            class="iframe"
                            v-else>
                    </iframe>
                  </template>
                  <template v-if="material.content_type == 3">
                    <div class="img-wrap">
                      <img v-for="(item, index) in JSON.parse(material.content)"
                           :key="index"
                           :src="item.url"
                           alt=""
                           srcset="">
                    </div>
                  </template>
                </div>
                <div class="empty"
                     v-else>
                  <img src="@/assets/images/confirm-empty.png"
                       alt=""
                       srcset="">
                  <div class="tips">
                    暂无拟录取名单，请先<el-link type="primary"
                             :underline="false"
                             @click="openDataCreateDialog(1)">添加</el-link>
                  </div>
                </div>
              </template>
            </el-tab-pane>
            <el-tab-pane label="专业目录"
                         name="second">
              <div class="tab-modular">

                <div class="item-wrap"
                     v-if="Object.keys(catalogue).length">
                  <div class="top">
                    <div class="wrap">
                      <div class="item">
                        <div class="label">招生单位：</div>
                        <div class="value">({{ catalogue.college_code }}){{ catalogue.college_name }}</div>
                      </div>
                      <div class="item">
                        <div class="label">院系所：</div>
                        <div class="value">({{ catalogue.academy_code }}){{ catalogue.academy_name }}</div>
                      </div>
                    </div>
                    <div class="wrap">

                      <div class="item">
                        <div class="label">专 业：</div>
                        <div class="value">({{ catalogue.major_code }}){{ catalogue.major_name }}</div>
                      </div>
                      <div class="item">
                        <div class="label">学习方式：</div>
                        <div class="value">{{ catalogue.type_plans.split('：')[0] }}</div>
                      </div>
                    </div>
                    <div class="wrap">
                      <div class="item">
                        <div class="label">研究方向：</div>
                        <div class="value">{{ catalogue.directions }}</div>
                      </div>
                      <div class="item">
                        <div class="label">拟招人数：</div>
                        <div class="value">{{ catalogue.type_plans }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="line"></div>
                  <div class="bottom">
                    <div class="title">考试范围</div>
                    <div class="wrap">
                      <div class="item"
                           v-for="(item, index) in JSON.parse(catalogue.subjects)[0]"
                           :key="index">
                        <div class="label">{{ subjectNameList[index] }}</div>
                        <div class="value">{{ item }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <template v-else>
                  <div class="material-content"
                       v-if="Object.keys(material).length && JSON.parse(material.content)[0]">
                    <template v-if="material.content_type == 1">
                      <iframe style="width:100%;"
                              :frameborder="0"
                              height="800"
                              :src="material.content"
                              class="iframe">
                      </iframe>
                    </template>
                    <template v-if="material.content_type == 2">
                      <iframe style="width:100%;"
                              :frameborder="0"
                              height="800"
                              :src="JSON.parse(material.content)[0].url"
                              class="iframe"
                              v-if="JSON.parse(material.content)[0].url.indexOf('.pdf') != -1"></iframe>
                      <iframe style="width:100%;"
                              :frameborder="0"
                              height="800"
                              :src="baseurl + JSON.parse(material.content)[0].url"
                              class="iframe"
                              v-else>
                      </iframe>
                    </template>
                    <template v-if="material.content_type == 3">
                      <div class="img-wrap">
                        <img v-for="(item, index) in JSON.parse(material.content)"
                             :key="index"
                             :src="item.url"
                             alt=""
                             srcset="">
                      </div>
                    </template>
                  </div>
                  <div class="empty"
                       v-else>
                    <img src="@/assets/images/confirm-empty.png"
                         alt=""
                         srcset="">
                    <div class="tips">
                      暂无专业目录，请先<el-link type="primary"
                               :underline="false"
                               @click="openDataCreateDialog(3)">添加</el-link>
                    </div>
                  </div>
                </template>
              </div>
            </el-tab-pane>
            <el-tab-pane label="复试名单"
                         name="ninth">
              <div class="material-content"
                   v-if="Object.keys(material).length && JSON.parse(material.content)[0]">
                <template v-if="material.content_type == 1">
                  <iframe style="width:100%;"
                          :frameborder="0"
                          height="800"
                          :src="material.content"
                          class="iframe">
                  </iframe>
                </template>
                <template v-if="material.content_type == 2">
                  <iframe style="width:100%;"
                          :frameborder="0"
                          height="800"
                          :src="JSON.parse(material.content)[0].url"
                          class="iframe"
                          v-if="JSON.parse(material.content)[0].url.indexOf('.pdf') != -1"></iframe>
                  <iframe style="width:100%;"
                          :frameborder="0"
                          height="800"
                          :src="baseurl + JSON.parse(material.content)[0].url"
                          class="iframe"
                          v-else>
                  </iframe>
                </template>
                <template v-if="material.content_type == 3">
                  <div class="img-wrap">
                    <img v-for="(item, index) in JSON.parse(material.content)"
                         :key="index"
                         :src="item.url"
                         alt=""
                         srcset="">
                  </div>
                </template>
              </div>
              <div class="empty"
                   v-else>
                <img src="@/assets/images/confirm-empty.png"
                     alt=""
                     srcset="">
                <div class="tips">
                  暂无复试名单，请先<el-link type="primary"
                           :underline="false"
                           @click="openDataCreateDialog(9)">添加</el-link>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="参考书目"
                         name="third">
              <div class="tab-modular">
                <div class="item-wrap"
                     v-if="Object.keys(catalogue).length"
                     style="white-space:pre-line;">
                  <div class="html"
                       v-html="books"></div>
                </div>
                <template v-else>
                  <div class="material-content"
                       v-if="Object.keys(material).length && JSON.parse(material.content)[0]">
                    <template v-if="material.content_type == 1">
                      <iframe style="width:100%;"
                              :frameborder="0"
                              height="800"
                              :src="material.content"
                              class="iframe">
                      </iframe>
                    </template>
                    <template v-if="material.content_type == 2">
                      <iframe style="width:100%;"
                              :frameborder="0"
                              height="800"
                              :src="JSON.parse(material.content)[0].url"
                              class="iframe"
                              v-if="JSON.parse(material.content)[0].url.indexOf('.pdf') != -1"></iframe>
                      <iframe style="width:100%;"
                              :frameborder="0"
                              height="800"
                              :src="baseurl + JSON.parse(material.content)[0].url"
                              class="iframe"
                              v-else>
                      </iframe>
                    </template>
                    <template v-if="material.content_type == 3">
                      <div class="img-wrap">
                        <img v-for="(item, index) in JSON.parse(material.content)"
                             :key="index"
                             :src="item.url"
                             alt=""
                             srcset="">
                      </div>
                    </template>
                  </div>
                  <div class="empty"
                       v-else>
                    <img src="@/assets/images/confirm-empty.png"
                         alt=""
                         srcset="">
                    <div class="tips">
                      暂无参考书目，请先<el-link type="primary"
                               :underline="false"
                               @click="openDataCreateDialog(2)">添加</el-link>
                    </div>
                  </div>
                </template>
              </div>
            </el-tab-pane>
            <el-tab-pane label="招生简章"
                         name="fourth">
              <div class="material-content"
                   v-if="Object.keys(material).length && JSON.parse(material.content)[0]">
                <template v-if="material.content_type == 1">
                  <iframe style="width:100%;"
                          :frameborder="0"
                          height="800"
                          :src="material.content"
                          class="iframe">
                  </iframe>
                </template>
                <template v-if="material.content_type == 2">
                  <iframe style="width:100%;"
                          :frameborder="0"
                          height="800"
                          :src="JSON.parse(material.content)[0].url"
                          class="iframe"
                          v-if="JSON.parse(material.content)[0].url.indexOf('.pdf') != -1"></iframe>
                  <iframe style="width:100%;"
                          :frameborder="0"
                          height="800"
                          :src="baseurl + JSON.parse(material.content)[0].url"
                          class="iframe"
                          v-else>
                  </iframe>
                </template>
                <template v-if="material.content_type == 3">
                  <div class="img-wrap">
                    <img v-for="(item, index) in JSON.parse(material.content)"
                         :key="index"
                         :src="item.url"
                         alt=""
                         srcset="">
                  </div>
                </template>
              </div>
              <div class="empty"
                   v-else>
                <img src="@/assets/images/confirm-empty.png"
                     alt=""
                     srcset="">
                <div class="tips">
                  暂无招生简章，请先<el-link type="primary"
                           :underline="false"
                           @click="openDataCreateDialog(4)">添加</el-link>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="考试大纲"
                         name="fifth">
              <div class="material-content"
                   v-if="Object.keys(material).length && JSON.parse(material.content)[0]">
                <template v-if="material.content_type == 1">
                  <iframe style="width:100%;"
                          :frameborder="0"
                          height="800"
                          :src="material.content"
                          class="iframe">
                  </iframe>
                </template>
                <template v-if="material.content_type == 2">
                  <iframe style="width:100%;"
                          :frameborder="0"
                          height="800"
                          :src="JSON.parse(material.content)[0].url"
                          class="iframe"
                          v-if="JSON.parse(material.content)[0].url.indexOf('.pdf') != -1"></iframe>
                  <iframe style="width:100%;"
                          :frameborder="0"
                          height="800"
                          :src="baseurl + JSON.parse(material.content)[0].url"
                          class="iframe"
                          v-else>
                  </iframe>
                </template>
                <template v-if="material.content_type == 3">
                  <div class="img-wrap">
                    <img v-for="(item, index) in JSON.parse(material.content)"
                         :key="index"
                         :src="item.url"
                         alt=""
                         srcset="">
                  </div>
                </template>
              </div>
              <div class="empty"
                   v-else>
                <img src="@/assets/images/confirm-empty.png"
                     alt=""
                     srcset="">
                <div class="tips">
                  暂无考试大纲，请先<el-link type="primary"
                           :underline="false"
                           @click="openDataCreateDialog(5)">添加</el-link>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="分数线"
                         name="sixth">
              <div class="material-content"
                   v-if="Object.keys(material).length && JSON.parse(material.content)[0]">
                <template v-if="material.content_type == 1">
                  <iframe style="width:100%;"
                          :frameborder="0"
                          height="800"
                          :src="material.content"
                          class="iframe">
                  </iframe>
                </template>
                <template v-if="material.content_type == 2">
                  <iframe style="width:100%;"
                          :frameborder="0"
                          height="800"
                          :src="JSON.parse(material.content)[0].url"
                          class="iframe"
                          v-if="JSON.parse(material.content)[0].url.indexOf('.pdf') != -1"></iframe>
                  <iframe style="width:100%;"
                          :frameborder="0"
                          height="800"
                          :src="baseurl + JSON.parse(material.content)[0].url"
                          class="iframe"
                          v-else>
                  </iframe>
                </template>
                <template v-if="material.content_type == 3">
                  <div class="img-wrap">
                    <img v-for="(item, index) in JSON.parse(material.content)"
                         :key="index"
                         :src="item.url"
                         alt=""
                         srcset="">
                  </div>
                </template>
              </div>
              <div class="empty"
                   v-else>
                <img src="@/assets/images/confirm-empty.png"
                     alt=""
                     srcset="">
                <div class="tips">
                  暂无分数线，请先<el-link type="primary"
                           :underline="false"
                           @click="openDataCreateDialog(6)">添加</el-link>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="复试方案"
                         name="seventh">
              <div class="material-content"
                   v-if="Object.keys(material).length && JSON.parse(material.content)[0]">
                <template v-if="material.content_type == 1">
                  <iframe style="width:100%;"
                          :frameborder="0"
                          height="800"
                          :src="material.content"
                          class="iframe">
                  </iframe>
                </template>
                <template v-if="material.content_type == 2">
                  <iframe style="width:100%;"
                          :frameborder="0"
                          height="800"
                          :src="JSON.parse(material.content)[0].url"
                          class="iframe"
                          v-if="JSON.parse(material.content)[0].url.indexOf('.pdf') != -1"></iframe>
                  <iframe style="width:100%;"
                          :frameborder="0"
                          height="800"
                          :src="baseurl + JSON.parse(material.content)[0].url"
                          class="iframe"
                          v-else>
                  </iframe>
                </template>
                <template v-if="material.content_type == 3">
                  <div class="img-wrap">
                    <img v-for="(item, index) in JSON.parse(material.content)"
                         :key="index"
                         :src="item.url"
                         alt=""
                         srcset="">
                  </div>
                </template>
              </div>
              <div class="empty"
                   v-else>
                <img src="@/assets/images/confirm-empty.png"
                     alt=""
                     srcset="">
                <div class="tips">
                  暂无复试方案，请先<el-link type="primary"
                           :underline="false"
                           @click="openDataCreateDialog(7)">添加</el-link>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="调剂信息"
                         name="eighth">
              <div class="material-content"
                   v-if="Object.keys(material).length && JSON.parse(material.content)[0]">
                <template v-if="material.content_type == 1">
                  <iframe style="width:100%;"
                          :frameborder="0"
                          height="800"
                          :src="material.content"
                          class="iframe">
                  </iframe>
                </template>
                <template v-if="material.content_type == 2">
                  <iframe style="width:100%;"
                          :frameborder="0"
                          height="800"
                          :src="JSON.parse(material.content)[0].url"
                          class="iframe"
                          v-if="JSON.parse(material.content)[0].url.indexOf('.pdf') != -1"></iframe>
                  <iframe style="width:100%;"
                          :frameborder="0"
                          height="800"
                          :src="baseurl + JSON.parse(material.content)[0].url"
                          class="iframe"
                          v-else>
                  </iframe>
                </template>
                <template v-if="material.content_type == 3">
                  <div class="img-wrap">
                    <img v-for="(item, index) in JSON.parse(material.content)"
                         :key="index"
                         :src="item.url"
                         alt=""
                         srcset="">
                  </div>
                </template>
              </div>
              <div class="empty"
                   v-else>
                <img src="@/assets/images/confirm-empty.png"
                     alt=""
                     srcset="">
                <div class="tips">
                  暂无调剂信息，请先<el-link type="primary"
                           :underline="false"
                           @click="openDataCreateDialog(8)">添加</el-link>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </div>
      <div class="modular"
           style="margin-left: 12px;width: 40%;height: 100%;">
        <el-card>
          <div class="title">
            {{ titleTxt }}
            <el-button type="primary"
                       @click="changeStatus"
                       v-if="dataForm && dataForm.confirm_status != 2"
                       size="mini">未录取</el-button>
          </div>
          <el-alert title="如有不确定的信息项请勾选右侧方框"
                    type="error"
                    show-icon
                    style="background-color:#ECF2FE;margin-bottom: 12px;">
          </el-alert>
          <el-form :model="dataForm"
                   label-position="right"
                   ref="formRules"
                   :rules="rules"
                   label-width="100px"
                   size="small">
            <div class="module"
                 v-show="step == 1">
              <el-form-item label="姓名："
                            prop="realname">
                <el-input v-model="dataForm.realname"
                          placeholder="请输入姓名"
                          style="width: 90%;"></el-input>
                <el-checkbox @change="(...event) => { handleToParams(event, 'realname') }"></el-checkbox>
              </el-form-item>
              <el-form-item label="考研年份："
                            prop="recruit_year">
                <el-select v-model="dataForm.recruit_year"
                           placeholder="请选择考研年份"
                           clearable
                           style="width: 90%;">
                  <el-option label="2020年"
                             :value="2020">
                  </el-option>
                  <el-option label="2021年"
                             :value="2021">
                  </el-option>
                  <el-option label="2022年"
                             :value="2022">
                  </el-option>
                  <el-option label="2023年"
                             :value="2023">
                  </el-option>
                  <el-option label="2024年"
                             :value="2024">
                  </el-option>
                  <el-option label="2025年"
                             :value="2025">
                  </el-option>
                </el-select>
                <el-checkbox @change="(...event) => { handleToParams(event, 'recruit_year') }"></el-checkbox>
              </el-form-item>
              <el-form-item label="录取院校："
                            prop="recruit_college">
                <el-select v-model="dataForm.recruit_college"
                           placeholder="请选择录取院校"
                           filterable
                           clearable
                           @change="collegeChange"
                           style="width: 90%;">
                  <el-option v-for="(item, index) in $store.state.collegeList"
                             :key="index"
                             :label="item.name"
                             :value="{ value: item.code, label: item.name }">
                  </el-option>
                </el-select>
                <el-checkbox @change="(...event) => { handleToParams(event, 'recruit_college_code', 'recruit_college_name') }"></el-checkbox>
              </el-form-item>
              <el-form-item label="录取专业："
                            prop="recruit_major">
                <el-select v-model="dataForm.recruit_major"
                           placeholder="请选择专业"
                           @change="majorChange"
                           clearable
                           filterable
                           style="width: 90%;">
                  <el-option v-for="(item, index) in majorOptions"
                             :key="index"
                             :label="item.allnames"
                             :value="{ value: item.code, label: item.name }">
                  </el-option>
                </el-select>
                <el-checkbox @change="(...event) => { handleToParams(event, 'recruit_major_code', 'recruit_major_name') }"></el-checkbox>

              </el-form-item>
              <el-form-item label="录取学院："
                            prop="recruit_major">
                <el-select v-model="dataForm.recruit_academy"
                           placeholder="请选择录取学院"
                           clearable
                           @change="academyChange($event,true)"
                           style="width: 90%;">
                  <el-option v-for="(item, index) in academyOptions"
                             :key="index"
                             :label="item.academy_name"
                             :value="{ value: item.academy_code, label: item.academy_name, directions: item.directions }">
                    <span>{{ `（${item.academy_code}）${item.academy_name}` }}</span>
                  </el-option>
                </el-select>
                <el-checkbox @change="(...event) => { handleToParams(event, 'recruit_academy_code', 'recruit_academy_name') }"></el-checkbox>
              </el-form-item>
              <el-form-item label="研究方向："
                            prop="recruit_major">
                <el-select v-model="dataForm.recruit_direction"
                           placeholder="请选择研究方向"
                           clearable
                           style="width: 90%;">
                  <el-option v-for="(item, index) in directionsOptions"
                             :key="index"
                             :label="item.direction"
                             :value="{ value: item.direction_code, label: item.direction }">
                    <span>{{ `（${item.direction_code}）${item.direction}` }}</span>
                  </el-option>
                </el-select>
                <el-checkbox @change="(...event) => { handleToParams(event, 'recruit_direction_code', 'recruit_direction_name') }"></el-checkbox>
              </el-form-item>

              <el-form-item label="初试科目："
                            prop="test_subjects">
                <el-select style="width:90%;"
                           v-model="dataForm.test_subjects"
                           placeholder="请选择初试科目"
                           clearable
                           multiple
                           @change="$forceUpdate()">
                  <el-option v-for="(item, index) in subjectOptions"
                             :key="index"
                             :label="item.subject_name"
                             :value="{ value: item.subject_code, label: item.subject_name, score: 0 }">
                    <span>{{ `（${item.subject_code}）${item.subject_name}` }}</span>
                  </el-option>
                </el-select>
                <el-checkbox @change="(...event) => { handleToParams(event, 'test_subject_codes', 'test_subject_names') }"></el-checkbox>
              </el-form-item>
              <!-- vvv{{dataForm.test_subjects}} -->
              <el-form-item label="科目分数："
                            prop="test_subjects">
                <div style="display: flex;">
                  <div v-if="dataForm.test_subjects && dataForm.test_subjects.length">
                    <el-input v-for="(item, index) in dataForm.test_subjects"
                              :key="index"
                              v-model="item.score"
                              placeholder="请输入分数"
                              style="width:90%;margin-bottom: 12px;"
                              oninput="value=value.replace(/[^0-9.]/g,'')"
                              @input="computedScore">
                      <template slot="prepend">
                        {{ item.label }}
                      </template>
                      <template slot="append">分</template>
                    </el-input>
                    <el-checkbox @change="(...event) => { handleToParams(event, 'test_subject_scores') }"></el-checkbox>

                  </div>
                  <div v-else>请选择初试科目</div>
                </div>

              </el-form-item>

              <el-form-item label="分数参考：">
                {{ dataForm.test_subject_scores }}
              </el-form-item>
              <el-form-item label="初试总分："
                            prop="initial_test_score">
                <el-input v-model="dataForm.initial_test_score"
                          placeholder="请输入初试总分"
                          style="width: 90%"
                          readonly>
                  <template slot="append">分</template>
                </el-input>
                <el-checkbox @change="(...event) => { handleToParams(event, 'initial_test_score') }"></el-checkbox>

              </el-form-item>
              <el-form-item label="录取类型："
                            prop="recruit_type">
                <el-radio-group v-model="dataForm.recruit_type">
                  <el-radio :label="1">一志愿</el-radio>
                  <el-radio :label="2">调剂</el-radio>
                </el-radio-group>
                <el-checkbox @change="(...event) => { handleToParams(event, 'recruit_type') }"></el-checkbox>

              </el-form-item>
              <el-form-item label="复试科目："
                            prop="second_test_subject_names">
                <el-input v-model="dataForm.second_test_subject_names"
                          placeholder="请输入复试科目"
                          style="width: 90%;"></el-input>
                <el-checkbox @change="(...event) => { handleToParams(event, 'second_test_subject_names') }"></el-checkbox>
              </el-form-item>
              <el-form-item label="参考书目："
                            prop="reference_book">
                <el-select style="width:90%;"
                           v-model="dataForm.reference_book"
                           placeholder="请选择参考书目"
                           clearable
                           multiple
                           ref="select"
                           @change="reference_bookChange"
                           @visible-change="(v) => visibleChange(v, 'select', selectClick, '去添加')">
                  <el-option v-for="(item, index) in booksOptions"
                             :key="index"
                             :label="item.label"
                             :value="{ value: item.value, label: item.label }">
                    <span>{{ `《${item.label}》${item.value}` }}</span>
                  </el-option>
                </el-select>
                <el-checkbox @change="(...event) => { handleToParams(event, 'reference_book_names', 'reference_book_extra_infos') }"></el-checkbox>
              </el-form-item>
              <el-form-item label="录取参考书：">
                {{dataForm.ref_recruit_reference_bookses||'无'}}
              </el-form-item>
            </div>
            <div class="module"
                 v-show="step == 2">
              <el-form-item label="性别："
                            prop="gender">
                <el-radio-group v-model="dataForm.gender">
                  <el-radio :label="1">男</el-radio>
                  <el-radio :label="2">女</el-radio>
                  <el-radio :label="0">未知</el-radio>
                </el-radio-group>
                <el-checkbox @change="(...event) => { handleToParams(event, 'gender') }"></el-checkbox>
              </el-form-item>
              <el-form-item label="匿名："
                            prop="hide_name_status">
                <el-radio-group v-model="dataForm.hide_name_status">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="昵称："
                            prop="nickname"
                            v-if="dataForm.hide_name_status">
                <el-input v-model="dataForm.nickname"
                          placeholder="请输入昵称"
                          style="width:90%;">
                </el-input>
                <el-checkbox @change="(...event) => { handleToParams(event, 'nickname') }"></el-checkbox>
              </el-form-item>
              <el-form-item label="手机号："
                            prop="mobile">
                <el-input v-model="dataForm.mobile"
                          placeholder="请输入手机号"
                          style="width:90%;"
                          oninput="value=value.replace(/[^0-9.]/g,'')"
                          disabled>
                </el-input>
                <el-checkbox @change="(...event) => { handleToParams(event, 'mobile') }"></el-checkbox>
              </el-form-item>
              <el-form-item label="微信号："
                            prop="name">
                <el-input v-model="dataForm.wechat"
                          placeholder="请输入微信号"
                          style="width:90%;">
                </el-input>
                <el-checkbox @change="(...event) => { handleToParams(event, 'wechat') }"></el-checkbox>
              </el-form-item>
              <el-form-item label="QQ号："
                            prop="name">
                <el-input v-model="dataForm.qq"
                          placeholder="请输入QQ号"
                          oninput="value=value.replace(/[^0-9.]/g,'')"
                          style="width:90%;">
                </el-input>
                <el-checkbox @change="(...event) => { handleToParams(event, 'qq') }"></el-checkbox>

              </el-form-item>
              <el-form-item label="个人优势："
                            prop="additional">
                <el-input type="textarea"
                          v-model="dataForm.additional"
                          placeholder="请输入个人优势"
                          style="width: 90%">
                </el-input>
                <el-checkbox @change="(...event) => { handleToParams(event, 'additional') }"></el-checkbox>

              </el-form-item>

              <el-form-item label="内部备注："
                            prop="additional">
                <el-input type="textarea"
                          v-model="dataForm.contract_remark"
                          placeholder="请输入内部备注、录取证明图片也可以直接在此处粘贴上传"
                          style="width: 90%"
                          @paste.native="handlePaste">
                </el-input>
                <!-- <el-checkbox @change="(...event) =>{handleToParams(event, 'additional')}"></el-checkbox> -->

              </el-form-item>

              <el-form-item label="录取证明："
                            prop="recruit_certify_img">
                <div style="display: flex;">
                  <el-upload class="avatar-uploader"
                             :action="imgUploadUrl"
                             :show-file-list="false"
                             :on-success="handleAvatarSuccess">
                    <img v-if="imageUrl"
                         :src="imageUrl"
                         class="avatar">
                    <i v-else
                       class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <el-checkbox @change="(...event) => { handleToParams(event, 'recruit_certify_img') }"></el-checkbox>
                </div>
              </el-form-item>
            </div>
          </el-form>
          <div class="footer">
            <el-button v-if="step == 2"
                       @click="stepChange(-1)">上一步</el-button>
            <el-button v-if="step == 1"
                       @click="handleToClose()">取消</el-button>
            <el-button type="primary"
                       v-if="step == 1"
                       @click="stepChange(1)">下一步</el-button>
            <el-button type="primary"
                       @click="submit('formRules')"
                       slot=""
                       v-if="step == 2">确定</el-button>
          </div>
          <AddBooks :isVisible="addBooksDialog"
                    @close="addBooksDialog = false"
                    @submit="AddBooksSubmit"></AddBooks>
        </el-card>
      </div>
    </div>
    <AddData :isVisible="createDialog"
             @submit="this.tabClick"
             @close="createDialog = false"
             :content="createContent">
    </AddData>
  </div>
</template>
<script>
let baseurl = `${window.location.protocol}//view.officeapps.live.com/op/view.aspx?src=`

import {
  academylist,
  majorlist,
  subjectlist,
  teacherOne,
  teacherEdit,
  materiallist,
  taRecordCreate,
} from '@/request/api'
import { adminUrl } from '@/utils/helper'
import { imgUploadUrl } from '@/request/http'
import AddBooks from '@/components/DialogComponents/TeacherResources/AddBooks.vue'
import AddData from '@/components/DialogComponents/TeacherResources/AddData.vue'
import { xorDependencies } from 'mathjs'
import { commonHandleLogList } from '../../../request/api'

export default {
  components: {
    AddBooks,
    AddData,
  },
  data() {
    return {
      active: 'first',
      dialogFormVisible: this.isVisible,
      dataForm: {
        hide_name_status: 0,
      },
      rules: {
        nickname: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
        realname: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        recruit_year: [
          { required: true, message: '请选择考研年份', trigger: 'blur' },
        ],
        recruit_college: [
          { required: true, message: '请选择录取院校', trigger: 'blur' },
        ],
        recruit_major: [
          { required: true, message: '请选择录取专业', trigger: 'blur' },
        ],
        recruit_academy: [
          { required: true, message: '请选择录取学院', trigger: 'blur' },
        ],
        recruit_direction: [
          { required: true, message: '请选择研究方向', trigger: 'blur' },
        ],
        test_subjects: [
          { required: true, message: '请选择初试科目', trigger: 'blur' },
        ],
        initial_test_score: [
          { required: true, message: '请输入初试科目分数', trigger: 'blur' },
        ],
        recruit_type: [
          { required: true, message: '请选择录取类型', trigger: 'blur' },
        ],
        /*   reference_book: [{ required: true, message: "请选择参考书目", trigger: "blur" }], */
        /*    second_test_subject_names: [{ required: true, message: "请输入复试科目", trigger: "blur" }], */
        gender: [{ required: true, message: '请选择性别', trigger: 'blur' }],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: '请输入正确的手机号',
          },
        ],
        /*      additional: [{ required: true, message: "请输入个人优势", trigger: "blur" }], */
        /*    recruit_certify_img: [{
             required: true,
             validator: (rule, value, callback) => {
               if (!this.imageUrl) {
                 callback(new Error('请上传录取证明'))
               } else {
                 callback()
               }
             },
           }], */
      },
      majorOptions: [], // 专业数组
      academyOptions: [], // 学院数组
      subjectOptions: [], //科目数组
      directionsOptions: [], //方向数组
      titleTxt: '报考信息',
      step: 1,
      imageUrl: '',
      imgUploadUrl: imgUploadUrl(),
      booksOptions: [],
      addBooksDialog: false,
      unconfirm_fields: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      pageTotal: 0,
      nameList: [],
      loading: false,
      catalogue: {},
      subjectNameList: ['政治', '外语', '业务课一', '业务课二'],
      books: '',
      material: {},
      createDialog: false,
      createContent: {},
      baseurl: baseurl,
      materialShow: false,
      materialsList: [],
      chooseId: null,
    }
  },
  created() {
    this.fetchDetail()
    this.$store.dispatch('getAllColleges')
  },
  mounted() {},
  computed: {},
  methods: {
    handlePaste(value) {
      let file = value.clipboardData.items[0]
      if (file.type.includes('image')) {
        let imgFile = file.getAsFile()
        const formData = new FormData()
        formData.append('file', imgFile)
        const axiosInstance = this.$axios.create({ withCredentials: false })
        axiosInstance({
          method: 'post',
          url: this.imgUploadUrl, // 上传地址，视情况更换
          data: formData,
        })
          .then((res) => {
            // 调用成功回调
            this.imageUrl = res.data.data.url
          })
          .catch(function (err) {
            console.log(err)
            // 上传失败
          })
      }
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.fetchNameList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.fetchNameList()
    },
    fetchDetail() {
      
      teacherOne({ id: this.$route.params.id }).then((response) => {
        let res = response.data
        this.dataForm = res.data

        if (
          this.dataForm.recruit_college_name &&
          this.dataForm.recruit_college_code
        ) {
          this.$set(this.dataForm, 'recruit_college', {
            label: this.dataForm.recruit_college_name,
            value: this.dataForm.recruit_college_code,
          })
          this.getMajor(this.dataForm.recruit_college_code)
        }
        //
        if (
          this.dataForm.recruit_major_name &&
          this.dataForm.recruit_major_code
        ) {
          this.$set(this.dataForm, 'recruit_major', {
            label: this.dataForm.recruit_major_name,
            value: this.dataForm.recruit_major_code,
          })
          setTimeout(() => {
            this.getMajorInfo(
              this.dataForm.recruit_college_code,
              this.dataForm.recruit_major_code
            )

            this.getBooks(
              this.dataForm.recruit_college_code,
              this.dataForm.recruit_major_code
            )
          }, 1000)
        }
        // console.log("Cccc",this.dataForm.test_subjects)
        // return
        if (
          this.dataForm.recruit_academy_name &&
          this.dataForm.recruit_academy_code
        ) {
          this.$set(this.dataForm, 'recruit_academy', {
            label: this.dataForm.recruit_academy_name,
            value: this.dataForm.recruit_academy_code,
          })
          this.getSubjects()
        }

        if (
          this.dataForm.test_subject_names &&
          this.dataForm.test_subject_codes
        ) {
          this.$set(
            this.dataForm,
            'test_subjects',
            this.dataForm.test_subject_names.split(',').map((v, i) => {
              return {
                value: this.dataForm.test_subject_codes.split(',')[i],
                label: v,
                score: this.dataForm.test_subject_scores.split(',')[i] || '',
              }
            })
          )
        }

        if (
          this.dataForm.recruit_direction_name &&
          this.dataForm.recruit_direction_code
        ) {
          this.$set(this.dataForm, 'recruit_direction', {
            label: this.dataForm.recruit_direction_name,
            value: this.dataForm.recruit_direction_code,
          })
        }

        if (
          this.dataForm.reference_book_names &&
          this.dataForm.reference_book_extra_infos
        ) {
          this.$set(
            this.dataForm,
            'reference_book',
            this.dataForm.reference_book_names.split(',').map((v, i) => {
              return {
                value: this.dataForm.reference_book_extra_infos.split(',')[i],
                label: v,
              }
            })
          )
        }
        if (this.dataForm.recruit_certify_img) {
          this.imageUrl = this.dataForm.recruit_certify_img
        }
        this.fetchNameList(true)
      })
    },
    fetchNameList(isReset = false) {
      this.loading = true
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/back/college/recruitPrepare/list`,
        method: 'get', //可以省略 不写默认是get
        params: {
          year: this.dataForm.recruit_year,
          recruit_college_code: this.dataForm.recruit_college.value,
          recruit_academy_code: this.dataForm.recruit_academy.value,
          recruit_major_code: this.dataForm.recruit_major.value,
          first_exam_score: this.dataForm.first_exam_score,
          ...this.query,
        },
      }).then((response) => {
        let res = response.data
        // console.log("oo",response.data)
        this.loading = false
        if (res.data.list.length > 0) {
          this.nameList = res.data.list || []
          xorDependencies
          this.pageTotal = res.data.count
        } else {
          // this.nameList = res.data.list || []; xorDependencies
          // this.pageTotal = res.data.count;
          this.fetchData(1)
        }
      })
    },

    fetchData(type) {
      const params = {
        years: this.dataForm.recruit_year,
        college_keyword: this.dataForm.recruit_college.value,
        isPaging: 0,
        types: type,
      }
      materiallist(params).then((response) => {
        let res = response.data
        if (res.data.list.length > 0) {
          if (res.data.list.length > 1) {
            this.materialsList = res.data.list
            this.materialShow = true
          }
          let index = res.data.list.findIndex((v) => {
            return v.academy_code == this.dataForm.recruit_academy.value
          })
          // console.log("Cc",res.data.list)
          if (index != -1) {
            this.material = res.data.list[index]
            this.chooseId = res.data.list[index].id
          } else {
            this.material = res.data.list[0]
            this.chooseId = res.data.list[0].id
          }
        }
      })
    },
    collegeChange(e) {
      this.dataForm.recruit_major = {}
      this.majorOptions = []
      this.dataForm.recruit_academy = {}
      this.academyOptions = []
      this.dataForm.test_subjects = []
      this.subjectOptions = []
      if (e) {
        this.getMajor(e.value)
      }
    },

    getMajor(college_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
      }
      majorlist(query).then((response) => {
        let res = response.data
        this.majorOptions = res.data.list.map((v) => {
          return {
            ...v,
            allnames: `（${v.code}）${v.name}`,
          }
        })
      })
    },
    majorChange(e) {
      this.dataForm.recruit_academy = {}
      this.academyOptions = []
      this.dataForm.test_subjects = []
      this.subjectOptions = []
      this.dataForm.directions = []
      if (e) {
        this.getMajorInfo(this.dataForm.recruit_college.value, e.value)
        this.getBooks(this.dataForm.recruit_college.value, e.value)
      }
    },
    getMajorInfo(college_code, major_code) {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/major/info`,
        method: 'get', //可以省略 不写默认是get
        params: {
          college_code: college_code,
          major_code: major_code,
        },
      }).then((response) => {
        let res = response.data
        this.academyOptions = res.data.academys

        if (this.academyOptions.length == 1) {
          this.dataForm.recruit_academy = {
            value: this.academyOptions[0].academy_code,
            label: this.academyOptions[0].academy_name,
            directions: this.academyOptions[0].directions,
          }
          this.academyChange(this.dataForm.recruit_academy)
        }

        this.directionsOptions = this.academyOptions.find((v) => {
          return this.dataForm.recruit_academy_code == v.academy_code
        }).directions
      })
    },
    getBooks(college_code, major_code) {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/college/core/academyMajor/list`,
        method: 'get', //可以省略 不写默认是get
        params: {
          college_code: college_code,
          major_code: major_code,
        },
      }).then((response) => {
        let res = response.data
        if (
          res.data.list[0].reference_books &&
          res.data.list[0].reference_books != 'null'
        ) {
          let books = res.data.list[0].reference_books
            .split('\n')
            .filter((v) => {
              return v && v.split('')[0] != '('
            })
          this.booksOptions = books.map((v) => {
            return {
              label: v.split('》，')[0].substring(3, v.split('》，')[0].length),
              value: v.split('》，')[1],
            }
          })
        }
      })
    },
    academyChange(e, flag) {
      if (flag) {
        this.dataForm.test_subjects = []
      }

      this.subjectOptions = []
      this.$forceUpdate()
      if (e) {
        this.directionsOptions = e.directions
        this.getSubjects()
      }
    },
    getSubjects() {
      let query = {
        isPaging: 0,
        college_code: this.dataForm.recruit_college.value,
        academy_code: this.dataForm.recruit_academy.value,
        major_code: this.dataForm.recruit_major.value,
      }
      subjectlist(query).then((res) => {
        let obj = {
          id: 999,
          subject_code: '999',
          subject_name: '复试课程',
        }
        this.subjectOptions = res.data.data.list || []
        this.subjectOptions.push(obj)
      })
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let data = {
            contract_remark: this.dataForm.contract_remark,
            id: this.dataForm.id,
            realname: this.dataForm.realname,
            recruit_year: this.dataForm.recruit_year,
            confirm_status: 1,
            nickname: this.dataForm.nickname,
            hide_name_status: this.dataForm.hide_name_status,
            recruit_college_code: this.dataForm.recruit_college.value,
            recruit_college_name: this.dataForm.recruit_college.label,
            recruit_major_code: this.dataForm.recruit_major.value,
            recruit_major_name: this.dataForm.recruit_major.label,
            recruit_academy_code: this.dataForm.recruit_academy.value,
            recruit_academy_name: this.dataForm.recruit_academy.label,
            recruit_direction_code: this.dataForm.recruit_direction.value,
            recruit_direction_name: this.dataForm.recruit_direction.label,
            test_subject_names:
              this.dataForm.test_subjects && this.dataForm.test_subjects.length
                ? this.dataForm.test_subjects
                    .map((v) => {
                      return v.label
                    })
                    .join(',')
                : '',
            test_subject_codes:
              this.dataForm.test_subjects && this.dataForm.test_subjects.length
                ? this.dataForm.test_subjects
                    .map((v) => {
                      return v.value
                    })
                    .join(',')
                : '',
            test_subject_scores:
              this.dataForm.test_subjects && this.dataForm.test_subjects.length
                ? this.dataForm.test_subjects
                    .map((v) => {
                      return v.score
                    })
                    .join(',')
                : '',
            recruit_type: this.dataForm.recruit_type,
            second_test_subject_names: this.dataForm.second_test_subject_names,
            mobile: this.dataForm.mobile,
            wechat: this.dataForm.wechat,
            qq: this.dataForm.qq,
            additional: this.dataForm.additional,
            recruit_certify_img: this.imageUrl,
            reference_book_extra_infos:
              this.dataForm.reference_book &&
              this.dataForm.reference_book.length
                ? this.dataForm.reference_book
                    .map((v) => {
                      return v.value
                    })
                    .join(',')
                : '',
            reference_book_names:
              this.dataForm.reference_book &&
              this.dataForm.reference_book.length
                ? this.dataForm.reference_book
                    .map((v) => {
                      return v.label
                    })
                    .join(',')
                : '',
            unconfirm_fields: this.unconfirm_fields
              .filter((v) => {
                return v
              })
              .join(','),
          }
          teacherEdit(data).then((response) => {
            let res = response.data
            if (res.code == 0) {
              this.$message.success('操作成功')
              taRecordCreate({
                record: `已确认${this.dataForm.realname}老师的报考信息`,
                teacher_id: this.$route.params.id,
                type: 18,
              }).then((res) => {
                // console.log("ces",res)
              })
              this.fetchDetail()
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    stepChange(step) {
      this.step += step
      this.titleTxt = this.step == 1 ? '报考信息' : '个人信息'
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    computedScore() {
      if (this.dataForm.test_subjects && this.dataForm.test_subjects.length) {
        let score = this.dataForm.test_subjects.reduce((acc, cur) => {
          return acc + Number(cur.score)
        }, 0)
        this.$set(this.dataForm, 'initial_test_score', score)
      }
    },
    reference_bookChange(e) {
      console.log(e)
    },

    /**
     * 为element-ui的Select和Cascader添加弹层底部操作按钮
     * @param visible
     * @param refName  设定的ref名称
     * @param onClick  底部操作按钮点击监听
     * @param addname  底部按钮名称
     */
    visibleChange(visible, refName, onClick, addname) {
      if (visible) {
        const ref = this.$refs[refName]
        let popper = ref.$refs.popper
        if (popper.$el) popper = popper.$el
        if (
          !Array.from(popper.children).some(
            (v) => v.className === 'el-cascader-menu__list'
          )
        ) {
          const el = document.createElement('ul')
          el.className = 'el-cascader-menu__list'
          el.style = 'border-top: solid 1px #E4E7ED; padding:0; color: #606266;'
          el.innerHTML =
            `<li class="el-cascader-node text-center" style="height:50px;line-height: 50px;">
                <span class="el-cascader-node__label" style="color:#409EFF;"><i class="font-blue el-icon-plus"></i>` +
            addname +
            `</span>
                </li>`
          popper.appendChild(el)
          el.onclick = () => {
            // 底部按钮的点击事件 点击后想触发的逻辑也可以直接写在这
            onClick && onClick()
            // 以下代码实现点击后弹层隐藏 不需要可以删掉
            if (ref.toggleDropDownVisible) {
              ref.toggleDropDownVisible(false)
            } else {
              ref.visible = false
            }
          }
        }
      }
    },
    /**
     *
     */
    selectClick() {
      this.addBooksDialog = true
    },
    AddBooksSubmit({ data }) {
      this.booksOptions.push(data)
    },
    handleToParams(e, name, othername = '') {
      if (e[0] == true) {
        if (!this.unconfirm_fields.includes(name)) {
          this.unconfirm_fields.push(name)
          this.unconfirm_fields.push(othername)
        } else {
          this.unconfirm_fields = this.unconfirm_fields.filter((v) => {
            return v && v != name
          })
          this.unconfirm_fields = this.unconfirm_fields.filter((v) => {
            return v && v != othername
          })
        }
      } else {
        this.unconfirm_fields = this.unconfirm_fields.filter((v) => {
          return v && v != name
        })
        this.unconfirm_fields = this.unconfirm_fields.filter((v) => {
          return v && v != othername
        })
      }
    },
    fetchCollegeCore() {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/college/core/academyMajor/list`,
        method: 'get', //可以省略 不写默认是get
        params: {
          year: this.dataForm.recruit_year,
          college_code: this.dataForm.recruit_college.value,
          academy_code: this.dataForm.recruit_academy.value,
          major_code: this.dataForm.recruit_major.value,
        },
      }).then((response) => {
        let res = response.data
        if (res.data.list.length == 0) {
          this.fetchData(3)
        } else {
          this.catalogue = res.data.list[0]
        }
      })
    },
    fetchBooks() {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/college/core/academyMajor/list`,
        method: 'get', //可以省略 不写默认是get
        params: {
          year: this.dataForm.recruit_year,
          college_code: this.dataForm.recruit_college.value,
          academy_code: this.dataForm.recruit_academy.value,
          major_code: this.dataForm.recruit_major.value,
        },
      }).then((response) => {
        let res = response.data
        if (res.data.list.length == 0) {
          this.fetchData(2)
        } else {
          this.books = res.data.list[0].reference_books
        }
      })
    },
    tabClick() {
      this.materialShow = false
      this.material = {}
      if (this.active == 'first') {
        this.fetchNameList(true)
      } else if (this.active == 'second') {
        this.fetchCollegeCore()
      } else if (this.active == 'third') {
        this.fetchBooks()
      } else if (this.active == 'fourth') {
        this.fetchData(4)
      } else if (this.active == 'fifth') {
        this.fetchData(5)
      } else if (this.active == 'sixth') {
        this.fetchData(6)
      } else if (this.active == 'seventh') {
        this.fetchData(7)
      } else if (this.active == 'eighth') {
        this.fetchData(8)
      } else if (this.active == 'ninth') {
        this.fetchData(9)
      }
    },
    openDataCreateDialog(type) {
      this.createContent = {
        type,
        year: this.dataForm.recruit_year,
        college_code: this.dataForm.recruit_college.value,
        college_name: this.dataForm.recruit_college.label,
      }
      this.createDialog = true
    },
    handleToClose() {
      this.$router.go(-1)
    },
    changeStatus() {
      this.$confirm('确定要改为未录取吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        teacherEdit({
          confirm_status: -1,
          id: this.dataForm.id,
          archive: 1,
        }).then((response) => {
          let res = response.data
          if (res.code == 0) {
            this.$message.success('操作成功')
            this.$emit('submit')
          }
        })
      })
    },
    chooseMaterial(item) {
      this.material = item
      this.materialShow = false
      this.chooseId = item.id
    },
  },
}
</script>
    
<style lang="less" scoped>
.content {
  height: 100%;

  .popover-content {
    position: absolute;
    top: 90px;
    left: 24px;
    width: 489px;
    min-height: 325px;
    background: #fff;
    box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px 3px 3px 3px;
    opacity: 1;
    z-index: 99999999;
    padding: 0px 16px;

    .item {
      padding: 12px 0px;
      border-bottom: 1px solid #dcdfe6;
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: normal;
      color: #333333;
      line-height: 22px;
      cursor: pointer;
    }

    .choose {
      color: #266fe8;
    }
  }

  .breadcrumb {
  }

  .main {
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
    position: relative;

    .modular {
    }
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #303133;
    margin-bottom: 12px;
  }

  /deep/.el-alert__icon {
    color: #266fe8;
  }

  /deep/.el-alert__title {
    color: #333;
  }
}

/deep/.el-input-group__prepend {
  min-width: 110px;
}

.avatar-uploader .el-upload {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 140px;
  height: 140px;
  line-height: 140px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  text-align: center;
}

.avatar {
  width: 140px;
  height: 140px;
}

.el-checkbox {
  margin-left: 16px;
}

.item-wrap {
  .top {
    display: flex;
    flex-direction: column;

    .wrap {
      display: flex;
      width: 100%;
      margin-bottom: 16px;

      .item {
        width: 50%;
        display: flex;

        .label {
          width: 90px;
          text-align: justify;
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          color: #303133;
          white-space: nowrap;
        }

        .value {
          font-weight: 600;
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          color: #303133;
        }
      }
    }
  }

  .line {
    opacity: 1;
    border: 1px solid #dcdfe6;
    margin-bottom: 16px;
  }

  .bottom {
    .title {
      width: 100%;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 600;
      color: #303133;
    }

    .wrap {
      display: flex;

      .item {
        flex: 1;

        .label {
          width: 80px;
          text-align: justify;
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          color: #303133;
          margin-bottom: 12px;
        }

        .value {
          font-weight: 600;
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          color: #303133;
        }
      }
    }
  }

  .html {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 26px;
  }
}

.material-content {
}

.empty {
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 195px;
    height: 161px;
    margin-bottom: 12px;
  }

  .tips {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
}

.img-wrap {
  overflow: auto;
  overflow-y: scroll;
  height: 800px;

  img {
    width: 100%;
  }
}

/deep/.el-tabs__content {
}
</style>
    