<template>
  <div class="content">
    <el-dialog top="5vh"
               title="账单列表"
               :visible.sync="dialogFormVisible"
               :close-on-click-modal="false"
               width="1200px"
               @close="emitClose">
      <el-table :data="tableData"
                v-if="billType==1"
                :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                :cell-style="{ textAlign: 'center', fontSize: '13px' }"
                height="460"
                border>

        <el-table-column prop="order_no"
                         label="账单ID"
                         width="180"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="source_order_type"
                         label="账单类型"
                         width="116"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="billType== 1">{{scope.row.type}}</span>
            <span v-if="billType == 3">充值账单</span>
          </template>
        </el-table-column>

        <el-table-column prop="create_time"
                         label="账单周期"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.start_time | dateFormat("YYYY.MM.DD") }}-{{
              scope.row.end_time | dateFormat("YYYY.MM.DD")
            }}
          </template>
        </el-table-column>
        <el-table-column prop="total_amount"
                         label="总费用"
                         width="116"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="billType== 1">{{ scope.row.total_amount + "元" }}</span>
            <span v-if="billType== 3">{{ scope.row.amount + "元" }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="tableData"
                v-if="billType==2"
                :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                :cell-style="{ textAlign: 'center', fontSize: '13px' }"
                height="460"
                border>
        <el-table-column prop="out_trade_no"
                         label="订单ID"
                         width="180"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.order_no}}</span>
          </template>
        </el-table-column>

        <el-table-column prop="source_order_type"
                         label="订单类型"
                         width="116"
                         show-overflow-tooltip>

          <span>共享订单</span>
        </el-table-column>

        <el-table-column prop="status"
                         label="订单状态"
                         width="116"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="small"
                    v-if="scope.row.status == 1">待接单</el-tag>
            <el-tag size="small"
                    v-if="scope.row.status == 2">
              <span v-if=" scope.row.receive_order_type ==1 "> 预留-接单中</span>

              <span v-else>接单中</span>
            </el-tag>
            <el-tag size="small"
                    type="warning"
                    v-if="scope.row.status == 3">
              <span v-if=" scope.row.receive_order_type ==1 "> 预留-待支付</span>

              <span v-else>待支付</span>
            </el-tag>

            <el-tag size="small"
                    type="warning"
                    v-if="scope.row.status == 4">
              <span v-if=" scope.row.receive_order_type ==1 ">
                预留-待确认
              </span>
              <span v-else-if=" scope.row.order_reuse_status  ==1  ">
                重启-待确认
              </span>

              <span v-else>待确认</span></el-tag>
            <el-tag size="small"
                    type="success"
                    v-if="scope.row.status == 5">
              <span v-if=" scope.row.receive_order_type ==1 "> 预留-已完成</span>
              <span v-else-if=" scope.row.order_reuse_status  ==1  ">
                重启-已完成
              </span>
              <span v-else>已完成</span>
            </el-tag>
            <el-tag size="small"
                    type="info"
                    v-if="scope.row.status == 6"> <span v-if=" scope.row.receive_order_type ==1 "> 预留-已取消</span>
              <span v-else-if=" scope.row.order_reuse_status  ==1  ">
                重启-已取消
              </span>
              <span v-else>已取消</span></el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="invoice_status"
                         label="开票状态"
                         width="116"
                         show-overflow-tooltip>
          <template slot-scope="scope">

            <el-tag size="small"
                    type="warning"
                    v-show="scope.row.ref_invoice_order_invoice_status== 1">开票中</el-tag>
            <el-tag size="small"
                    type="success"
                    v-show="scope.row.ref_invoice_order_invoice_status== 2">已开票</el-tag>

          </template>
        </el-table-column>

        <el-table-column prop="create_time"
                         label="发单人"
                         show-overflow-tooltip>
          <template slot-scope="scope">

            <span v-if="scope.row.sender_company_user_obj !=null &&scope.row.sender_company_user_obj.cid==1000"
                  class="pointer"
                  @click="handleReceiveSingleIssue(scope.row.sender_send_count_statistic_obj,scope.row.sender_company_user_obj )">
              <span v-if="scope.row.ref_sender_manage_user_obj !=null"> 平台-{{scope.row.ref_sender_manage_user_obj.nickname}}</span>
              <span v-else>平台-</span>
            </span>
            <span class="pointer"
                  v-else
                  @click="handleReceiveSingleIssue(scope.row.sender_send_count_statistic_obj,scope.row.sender_company_user_obj  )">{{
              scope.row.sender_company_user_obj
                ? `${scope.row.sender_company_user_obj.company_ref_name}/${scope.row.sender_company_user_obj.alias}/${scope.row.sender_company_user_obj.phone}`
                : ""
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="订单金额"
                         align="center"
                         width="100"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-popover placement="top"
                        width="200"
                        trigger="hover">
              <div>
                <div class="count-item other">
                  <div>订单费用</div>
                  <div>￥{{ scope.row.total_in_amount }}</div>
                </div>
                <div class="line"></div>
                <div class="count-item">
                  <div>基础费用</div>
                  <div>
                    ￥{{
                      scope.row.in_base_amount ? scope.row.in_base_amount : 0
                    }}
                  </div>
                </div>
                <div class="count-item">
                  <div>平台费用</div>
                  <div>
                    ￥{{
                      scope.row.in_service_amount
                        ? scope.row.in_service_amount
                        : 0
                    }}
                  </div>
                </div>

                <div class="count-item">
                  <div>超时附加</div>
                  <div>
                    ￥{{
                      scope.row.in_timeout_amount
                        ? scope.row.in_timeout_amount
                        : 0
                    }}
                  </div>
                </div>

                <div class="count-item">
                  <div>院校附加</div>
                  <div>
                    ￥{{
                      scope.row.in_append_college_category_amount
                        ? scope.row.in_append_college_category_amount
                        : 0
                    }}
                  </div>
                </div>
                <div class="count-item">
                  <div>地区附加</div>
                  <div>
                    ￥{{
                      scope.row.in_append_college_province_amount
                        ? scope.row.in_append_college_province_amount
                        : 0
                    }}
                  </div>
                </div>
                <div class="count-item">
                  <div>招生附加</div>
                  <div>
                    ￥{{
                      scope.row.in_append_college_enrol_amount
                        ? scope.row.in_append_college_enrol_amount
                        : 0
                    }}
                  </div>
                </div>
                <div class="count-item">
                  <div>要求附加</div>
                  <div>
                    ￥{{
                      Number(scope.row.in_append_demand_academy_major_amount) +
                      Number(scope.row.in_append_demand_direction_amount) +
                      Number(scope.row.in_append_demand_hour_amount_amount)+
                       Number(scope.row.in_append_trial_listen_amount)+
                          Number(scope.row.in_append_professional_experience_amount)+
                            Number(scope.row.in_append_first_rank_amount)+
                             Number(scope.row. in_append_first_total_score_amount)+
                              Number(scope.row.in_append_first_professional_score_amount)
                        ? (
                            Number(
                              scope.row.in_append_demand_academy_major_amount
                            ) +
                            Number(
                              scope.row.in_append_demand_direction_amount
                            ) +
                            Number(
                              scope.row.in_append_demand_hour_amount_amount
                            )+
                              Number(scope.row.in_append_trial_listen_amount)+
                                Number(scope.row.in_append_professional_experience_amount)+
                                 Number(scope.row.in_append_first_rank_amount)+
                                     Number(scope.row. in_append_first_total_score_amount)+
                                       Number(scope.row.in_append_first_professional_score_amount)
                          ).toFixed(2)
                        : 0
                    }}
                  </div>
                </div>
                <div class="count-item">
                  <div>加急费用</div>
                  <div>
                    ￥{{
                      scope.row.in_urgency_amount
                        ? scope.row.in_urgency_amount
                        : 0
                    }}
                  </div>
                </div>

                <div class="count-item">
                  <div>复试附加</div>
                  <div>
                    ￥{{
                      scope.row.in_append_second_exam_subject_amount
                        ? scope.row.in_append_second_exam_subject_amount
                        : 0
                    }}
                  </div>
                </div>
                <div class="count-item">
                  <div>专业附加</div>
                  <div>
                    ￥{{
                      scope.row.in_append_major_discipline_amount
                        ? scope.row.in_append_major_discipline_amount
                        : 0
                    }}
                  </div>
                </div>
                <div class="count-item">
                  <div>考核附加</div>
                  <div>
                    ￥{{
                      scope.row.in_append_examine_cancel_amount
                        ? scope.row.in_append_examine_cancel_amount
                        : 0
                    }}
                  </div>
                </div>

              </div>
              <span slot="reference"
                    style="color: #ff0034">￥{{ scope.row.total_in_amount }}</span>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column label="创建时间"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{
              scope.row.create_time
                ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
                : "无"
            }}
          </template>
        </el-table-column>
        <el-table-column label="更新时间"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{
              scope.row.update_time
                ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>

      </el-table>
      <el-table :data="tableData"
                v-if="billType==3"
                :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                :cell-style="{ textAlign: 'center', fontSize: '13px' }"
                height="460"
                border>
        <el-table-column prop="out_trade_no"
                         label="订单ID"
                         width="180"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.out_trade_no }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="source_order_type"
                         label="账单类型"
                         width="116"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>充值账单</span>
          </template>
        </el-table-column>

        <el-table-column prop="create_time"
                         label="账单周期"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.start_time | dateFormat("YYYY.MM.DD") }}-{{
              scope.row.end_time | dateFormat("YYYY.MM.DD")
            }}
          </template>
        </el-table-column>
        <el-table-column prop="total_amount"
                         label="总费用"
                         width="116"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="billType== 1">{{ scope.row.total_amount + "元" }}</span>
            <span v-if="billType== 3">{{ scope.row.amount + "元" }}</span>
          </template>
        </el-table-column>

      </el-table>

      <div slot="footer"
           class="dialog-footer">
        <el-button @click="emitClose">取 消</el-button>
        <el-button type="primary"
                   @click="emitClose">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { serviceorderlist, paymentLog } from '../../../request/api'
import { crmUrl } from '@/utils/helper.js'
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      tableData: [],
      billType: null,
      jjj: null,
    }
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val
    },
    content(val) {
      console.log('详细内容', val)
      // 机构账单
      if (val.source_order_type == 1) {
        let query = {
          isPaging: 0,
          ids: val.bills,
        }
        serviceorderlist(query).then((res) => {
          this.tableData = res.data.data.list || []
        })
        // 共享订单
      } else if (val.source_order_type == 2) {
        let str = crmUrl()
        let data = {
          isPaging: 0,
          ref_company_invoice_order_id: val.id,
        }
        this.$axios({
          url: `${str}/api/v1/sharePlatform/findOrder/list`,
          method: 'get', //可以省略 不写默认是get
          params: data,
        }).then((response) => {
          this.tableData = []
          this.tableData = response.data.data.list || []
        })
      } else {
        let str = crmUrl()
        let data = {
          isPaging: 0,
          cid: val.cid,
          ref_company_invoice_order_id: val.id,
        }
        this.$axios({
          url: `${str}/api/v1/company/account/orderSender/paymentLog`,
          method: 'get', //可以省略 不写默认是get
          params: data,
        }).then((response) => {
          this.tableData = []
          this.tableData = response.data.data.list || []
        })
      }
      this.billType = val.source_order_type
      console.log('  this.billType ', this.billType)
    },
  },
  methods: {
    emitClose() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="less" scoped>
</style>