<template>
  <div>
    <el-dialog top="5vh"
               title="订单详情"
               :visible.sync="dialogFormVisible"
               @close="handleToClose"
               :close-on-click-modal="false"
               width="70%">
      <el-table v-if="order_flag"
                :data="result"
                :header-cell-style="{
          backgroundColor: '#F2F6FC',
          color: '#909399',
          fontSize: '14px',
        }"
                :cell-style="{ fontSize: '13px' }"
                border>
        <el-table-column prop="order_no"
                         label="订单编号"
                         align="center"
                         width="150">
        </el-table-column>
        <el-table-column prop="spend_duration"
                         label="时效"
                         align="center"
                         width="120">
          <template slot-scope="scope">
            <div class="spend-wrap">
              <img src="@/assets/images/ji_tag.png"
                   alt=""
                   srcset=""
                   v-if="scope.row.urgency_status == 1" />
              <div v-html="formateDurationTime(scope.row)"></div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="订单状态"
                         align="center"
                         width="80"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="small"
                    v-if="scope.row.status == 1">待接单</el-tag>
            <el-tag size="small"
                    v-if="scope.row.status == 2">接单中</el-tag>
            <el-tag size="small"
                    type="warning"
                    v-if="scope.row.status == 3">待支付</el-tag>
            <el-tag size="small"
                    type="warning"
                    v-if="scope.row.status == 4">待确认</el-tag>
            <el-tag size="small"
                    type="success"
                    v-if="scope.row.status == 5">已完成</el-tag>
            <el-tag size="small"
                    type="info"
                    v-if="scope.row.status == 6">已取消</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="发单人"
                         align="center"
                         width="150"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{
              scope.row.sender_company_user_obj
                ? `${scope.row.sender_company_user_obj.company_ref_name}-${scope.row.sender_company_user_obj.alias}`
                : ""
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="订单信息"
                         align="left"
                         min-width="500"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.apply_year }}考研 |
              {{ scope.row.apply_college_name }} |
              {{ scope.row.apply_academy_name }} | ({{
                scope.row.apply_major_code
              }}){{ scope.row.apply_major_name }} |
              {{ scope.row.lesson_hours }}小时</span>
          </template>
        </el-table-column>
        <el-table-column label="辅导要求"
                         align="center"
                         width="150"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-popover placement="top"
                        width="260"
                        trigger="hover"
                        v-if="formateAsks(scope.row) > 0">
              <div class="popver-content">
                <div style="
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 8px;
                  "
                     v-if="
                    scope.row.demand_direction_codes &&
                    scope.row.demand_direction_names
                  ">
                  <div class="point"
                       style="margin-right: 8px">·</div>
                  考试方向仅限是{{ formateDirections(scope.row) }}
                </div>
                <div style="
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 8px;
                  "
                     v-if="scope.row.demand_max_hour_amount">
                  <div class="point"
                       style="margin-right: 8px">·</div>
                  研究生时薪≤{{ scope.row.demand_max_hour_amount }}元/小时；
                </div>
                <div style="
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 8px;
                  "
                     v-if="scope.row.demand_academy_major_status">
                  <div class="point"
                       style="margin-right: 8px">·</div>
                  研究生与学员同一个学院专业
                </div>
                <div style="
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 8px;
                  "
                     v-if="scope.row.demand_extra_info">
                  <div class="point"
                       style="margin-right: 8px">·</div>
                  {{ scope.row.demand_extra_info }}
                </div>
              </div>
              <span slot="reference">有{{ formateAsks(scope.row) }}项辅导要求</span>
            </el-popover>
            <span v-else> 暂无任何辅导要求 </span>
          </template>
        </el-table-column>
        <el-table-column label="订单金额"
                         align="center"
                         width="100"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-popover placement="top"
                        width="200"
                        trigger="hover">
              <div>
                <div class="count-item other">
                  <div>订单费用</div>
                  <div>￥{{ scope.row.total_in_amount }}</div>
                </div>
                <div class="line"></div>
                <div class="count-item">
                  <div>基础费用</div>
                  <div>
                    ￥{{
                      scope.row.in_base_amount ? scope.row.in_base_amount : 0
                    }}
                  </div>
                </div>
                <div class="count-item">
                  <div>平台费用</div>
                  <div>
                    ￥{{
                      scope.row.in_service_amount
                        ? scope.row.in_service_amount
                        : 0
                    }}
                  </div>
                </div>

                <div class="count-item">
                  <div>超时附加</div>
                  <div>
                    ￥{{
                      scope.row.in_timeout_amount
                        ? scope.row.in_timeout_amount
                        : 0
                    }}
                  </div>
                </div>

                <div class="count-item">
                  <div>院校附加</div>
                  <div>
                    ￥{{
                      scope.row.in_append_college_category_amount
                        ? scope.row.in_append_college_category_amount
                        : 0
                    }}
                  </div>
                </div>
                <div class="count-item">
                  <div>地区附加</div>
                  <div>
                    ￥{{
                      scope.row.in_append_college_province_amount
                        ? scope.row.in_append_college_province_amount
                        : 0
                    }}
                  </div>
                </div>
                <div class="count-item">
                  <div>招生附加</div>
                  <div>
                    ￥{{
                      scope.row.in_append_college_enrol_amount
                        ? scope.row.in_append_college_enrol_amount
                        : 0
                    }}
                  </div>
                </div>

                <div class="count-item">
                  <div>要求附加</div>
                  <div>
                    ￥{{
                      Number(scope.row.in_append_demand_academy_major_amount) +
                      Number(scope.row.in_append_demand_direction_amount) +
                      Number(scope.row.in_append_demand_hour_amount_amount)
                        ? (
                            Number(
                              scope.row.in_append_demand_academy_major_amount
                            ) +
                            Number(
                              scope.row.in_append_demand_direction_amount
                            ) +
                            Number(
                              scope.row.in_append_demand_hour_amount_amount
                            )
                          ).toFixed(2)
                        : 0
                    }}
                  </div>
                </div>
                <div class="count-item">
                  <div>加急费用</div>
                  <div>
                    ￥{{
                      scope.row.in_urgency_amount
                        ? scope.row.in_urgency_amount
                        : 0
                    }}
                  </div>
                </div>
                <div class="count-item">
                  <div>复试附加</div>
                  <div>
                    ￥{{
                      scope.row.in_append_second_exam_subject_amount 
                        ? scope.row.in_append_second_exam_subject_amount 
                        : 0
                    }}
                  </div>
                </div>
                <!--   <div class="count-item">
                    <div>推荐分成</div>
                    <div>￥{{ scope.row.extra_out_referrer_bonus_amount?scope.row.extra_out_referrer_bonus_amount:0 }}</div>
                  </div> -->
              </div>
              <span slot="reference"
                    style="color: #ff0034">￥{{ scope.row.total_in_amount }}</span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="已付费用"
                         align="center"
                         width="100"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{
              scope.row.total_deposit_amount
                ? `￥${scope.row.total_deposit_amount}`
                : "-"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="接单人"
                         align="center"
                         width="150"
                         prop="ref_receiver_manage_user_obj.realname">
        </el-table-column>
        <el-table-column label="创建时间"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{
              scope.row.create_time
                ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
                : "无"
            }}
          </template>
        </el-table-column>
        <el-table-column label="更新时间"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{
              scope.row.update_time
                ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
      </el-table>

      <el-table v-else
                :data="result"
                row-key="id"
                :header-cell-style="{
          backgroundColor: '#F2F6FC',
        }"
                border>
        <el-table-column prop="spend_duration"
                         label="时效"
                         align="center"
                         width="120">
          <template slot-scope="scope">
            <div class="spend-wrap">
              <img src="@/assets/images/ji_tag.png"
                   alt=""
                   srcset=""
                   v-if="scope.row.urgency_status == 1" />
              <div v-html="formateDuration(scope.row)"></div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="类别"
                         align="center"
                         width="80">
          <template slot-scope="scope">
            {{ formateType(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="订单状态"
                         align="center"
                         width="80"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="small"
                    v-if="scope.row.status == 1">待分配</el-tag>
            <el-tag size="small"
                    v-if="scope.row.status == 2">匹配中</el-tag>
            <el-tag size="small"
                    v-if="scope.row.status == 3"
                    type="warning">待确认</el-tag>
            <el-tag size="small"
                    v-if="scope.row.status == 4"
                    type="success">已完成</el-tag>
            <el-tag type="danger"
                    size="small"
                    v-if="scope.row.status == 5">返单中</el-tag>
            <el-tag type="info"
                    size="small"
                    v-if="scope.row.status == 6">已取消</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="机构简称"
                         align="center"
                         width="120">
          <template slot-scope="scope">
            {{ scope.row.company ? scope.row.company.name : "无" }}
          </template>
        </el-table-column>
        <el-table-column prop="order_no"
                         label="订单编号"
                         align="center"
                         width="120">
        </el-table-column>
        <el-table-column label="辅导对象"
                         align="left"
                         width="180"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ formateName(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="报考信息"
                         align="left"
                         width="350"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.apply_college_name }} |
              {{ scope.row.apply_academy_name }} | ({{
                scope.row.apply_major_code
              }}){{ scope.row.apply_major_name }}
              {{
                scope.row.apply_direction_code && scope.row.apply_direction_name
                  ? ` |
              (${scope.row.apply_direction_code})${scope.row.apply_direction_name}`
                  : ""
              }}</span>
          </template>
        </el-table-column>
        <el-table-column label="辅导科目"
                         align="left"
                         width="250"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ formateSubjects(scope.row) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="辅导课时"
                         align="center"
                         width="100"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.lesson_hours }}小时
          </template>
        </el-table-column>
        <el-table-column prop="allocation_demand"
                         label="要求"
                         align="left"
                         width="350"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="匹配师"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{ formateIds(scope.row.allocation_manage_ids) }}
          </template>
        </el-table-column>
        <el-table-column label="学管师"
                         align="center"
                         width="120">
          <template slot-scope="scope">
            {{
              scope.row.learn_manage_user
                ? scope.row.learn_manage_user.nickname
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{
              scope.row.create_time
                ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
                : "无"
            }}
          </template>
        </el-table-column>
        <el-table-column label="更新时间"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{
              scope.row.update_time
                ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
      </el-table>

      <div class="search-wrap"
           style="margin-top: 30px">
        <el-form class="search-wrap"
                 ref="searchForm"
                 :inline="true"
                 :model="query"
                 size="small"
                 label-position="left">
          <el-form-item label="类型：">
            <el-select v-model="query.types"
                       placeholder="请选择类型"
                       clearable
                       @change="getDataList(true)"
                       multiple>
              <el-option v-for="(item, index) in filters.types"
                         :key="index"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="记录人">
            <el-select v-model="query.manage_creators"
                       placeholder="请选择"
                       @change="getDataList(true)"
                       multiple
                       clearable>
              <el-option v-for="item in $store.state.staffOptions"
                         :key="item.id"
                         :label="item.nickname"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker class="datetime"
                            v-model="create_time"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            @change="getDataList(true)"
                            clearable
                            :default-time="['00:00:00', '23:59:59']">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="resultT"
                :header-cell-style="{
          backgroundColor: '#F2F6FC',
          color: '#909399',
          fontSize: '14px',
        }"
                :cell-style="{ fontSize: '13px' }"
                border>
        <el-table-column label="记录人"
                         align="center"
                         width="120">
          <template slot-scope="scope">
            {{
              scope.row.manage_creator ? scope.row.manage_creator.nickname : ""
            }}
          </template>
        </el-table-column>

        <el-table-column label="类别"
                         align="center"
                         width="120"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="mini"
                    v-if="scope.row.type == 1">分配</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 2">备注</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 3">修改</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 4">跟进</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 5">签单</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 6">申请</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 7">沟通</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 8">记录</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 9">提交</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 10"
                    type="warning">修改</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 11">加入</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 12"
                    type="success">确认</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 13"
                    type="danger">驳回</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 14"
                    type="danger">取消</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 15"
                    type="warning">稍签</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 16"
                    type="warning">完结</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 17"
                    type="warning">推荐</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 18"
                    type="success">确认</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 19"
                    type="success">签约</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 20"
                    type="success">同步</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 21"
                    type="success">入驻</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="record"
                         label="沟通/操作记录"
                         align="left"
                         min-width="500">
          <template slot-scope="scope">
            <div v-html="
                scope.row.record
                  .replace(/\n|\r\n/g, '<br>')
                  .replace(/ /g, ' &nbsp')
              "></div>
            <span style="color: #409eff">{{
              formateAltManager(scope.row.alt_manage_ids)
            }}</span>
            <div class="img-list"
                 v-if="scope.row.record_img_urls">
              <img v-for="(item, index) in JSON.parse(scope.row.record_img_urls)"
                   :key="index"
                   :src="item.url"
                   alt=""
                   srcset=""
                   @click="openViewer(JSON.parse(scope.row.record_img_urls))" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="teacher_allocation_order_no"
                         label="沟通订单编号"
                         align="center"
                         show-overflow-tooltip
                         width="130"
                         v-if="type == 'teacher_read' || type == 'grade_read'">
          <template slot-scope="scope">
            <el-link :underline="false"
                     type="primary"
                     @click="
                handleToOrderDetail(scope.row.teacher_allocation_order_no)
              "
                     v-if="
                scope.row.inner_order_type == 1 &&
                scope.row.teacher_allocation_order_no
              ">{{ scope.row.teacher_allocation_order_no }}</el-link>

            <el-link :underline="false"
                     type="primary"
                     @click="handleToOrderDetail(scope.row.share_find_order_no, true)"
                     v-else-if="
                scope.row.inner_order_type == 2 &&
                scope.row.teacher_allocation_order_id
              ">{{ scope.row.share_find_order_no }}</el-link>
            <!-- <el-link
              :underline="false"
              type="primary"
              @click="handleToOrderDetail(scope.row.teacher_allocation_order_no)"
              v-if="scope.row.teacher_allocation_order_no"
            >{{ scope.row.teacher_allocation_order_no }}</el-link> -->
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column label="沟通老师"
                         align="center"
                         show-overflow-tooltip
                         width="130"
                         v-if="type == 'order_read' || type == 'grade_read'">
          <template slot-scope="scope">
            <el-link :underline="false"
                     type="primary"
                     @click="handleToDetail(scope.row.teacher_id)"
                     v-if="scope.row.teacher_user_realname && scope.row.teacher_id">{{ scope.row.teacher_user_realname }}</el-link>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column label="日期"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{
              scope.row.create_time
                ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>

        <el-table-column label="操作"
                         width="120"
                         align="center">
          <template slot-scope="scope">
            <el-link type="danger"
                     :underline="false"
                     @click="handleToRemove(scope.row)"
                     :disabled="![6, 7, 8].includes(scope.row.type)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer-wrap">
        <!-- <el-button size="small" @click="handleToLog()">新增记录</el-button> -->
        <div></div>
        <el-pagination background
                       layout="total, prev, pager, next, sizes, jumper"
                       :current-page="Number(query.pageIndex)"
                       :page-sizes="[10, 20, 50, 100, 500]"
                       :page-size="Number(query.pageSize)"
                       :total="Number(pageTotal)"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"></el-pagination>
      </div>
    </el-dialog>
    <SeniorDetail :isVisible="SeniorDetailDialog"
                  @close="SeniorDetailDialog = false"
                  :id="detailId" />
  </div>
</template>
<script>
import { crmUrl } from '@/utils/helper.js'
import { taList, taRecordList, taRecordDelete } from '@/request/api'
import SeniorDetail from '@/components/DialogComponents/TeacherResources/SeniorDetail.vue'
import {} from '@/request/api'
export default {
  components: { SeniorDetail },
  props: {
    order_flag: {
      type: Boolean,
      default: false,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },

    order_no: {
      type: Number,
      default: null,
    },
    order_id: {
      default: null,
    },
    force_inner_order_types: {
      default: null,
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      result: [],
      resultT: [],
      query: {
        isPaging: 1,
        pageIndex: 1,
        pageSize: 10,
        force_inner_order_types: '',
      },
      pageTotal: 0,
      type: 'order_read',
      filters: {
        types: [
          { label: '分配', value: 1 },
          { label: '备注', value: 2 },
          { label: '修改', value: 3 },
          { label: '跟进', value: 4 },
          { label: '签单', value: 5 },
          { label: '申请', value: 6 },
          { label: '沟通', value: 7 },
          { label: '记录', value: 8 },
          { label: '提交', value: 9 },
          { label: '修改', value: 10 },
          { label: '加入', value: 11 },
          { label: '确认', value: 12 },
          { label: '驳回', value: 13 },
          { label: '取消', value: 14 },
          { label: '稍签', value: 15 },
          { label: '完结', value: 16 },
          { label: '推荐', value: 17 },
          { label: '确认', value: 18 },
          { label: '签约', value: 19 },
          { label: '同步', value: 20 },
          { label: '入驻', value: 21 },
        ],
      },
      create_time: '',
      teacher_allocation_order_id: '',
      detailId: '',
      SeniorDetailDialog: false,
    }
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val
      if (val) {
        this.result = []
        if (this.order_id) {
          this.query.force_inner_order_types = this.force_inner_order_types
          this.teacher_allocation_order_id = this.order_id
        }

        if (this.order_flag) {
          this.getDataList()
          this.getFindOrderList()
          // console.log("111")
        } else {
          this.fetchDetail()

          // console.log("222")
        }
      }
    },
  },
  methods: {
    handleToDetail(id) {
      this.detailId = id
      this.SeniorDetailDialog = true
    },
    handleToRemove(row) {
      this.$confirm('确定要删除此条记录吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        taRecordDelete({ id: row.id }).then((res) => {
          this.getDataList(true)
        })
      })
    },
    getDataList(isReset) {
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      const params = Object.assign({}, this.query, {
        teacher_allocation_order_id: this.teacher_allocation_order_id || '',
        teacher_id: this.teacher_id || '',
      })
      if (params.types) {
        params.types = params.types.join(',')
      }
      if (params.manage_creators) {
        params.manage_creators = params.manage_creators.join(',')
      }

      if (this.teacherNo) {
        params.teacher_allocation_order_id = ''
      }
      taRecordList(params).then((response) => {
        // console.log("ll", response);
        let res = response.data
        this.resultT = res.data.list || []
        this.pageTotal = res.data.count
        // this.pageTotal = res.data.count;
      })
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.getDataList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.getDataList()
    },
    formateAltManager(ids) {
      if (ids) {
        let str = ids.split(',').map((v) => {
          return `@${
            this.$store.state.staffOptions.find((t) => {
              return t.id == v
            }).nickname
          }`
        })

        return str.join(',')
      } else {
        return ''
      }
    },
    formateDirections(row) {
      let names = row.demand_direction_names.split(',')
      let codes = row.demand_direction_codes.split(',')

      return names
        .map((v, i) => {
          return `(${codes[i]})${v}`
        })
        .join(',')
    },
    formateAsks(row) {
      let num = 0
      for (let k in row) {
        if (k == 'demand_direction_codes' && row[k]) {
          num += 1
        }
        if (k == 'demand_max_hour_amount' && row[k]) {
          num += 1
        }
        if (k == 'demand_academy_major_status' && row[k]) {
          num += 1
        }
        if (k == 'demand_extra_info' && row[k]) {
          num += 1
        }
      }
      return num
    },
    getFindOrderList() {
      let str = crmUrl()
      this.$axios({
        url: `${str}/api/v1/sharePlatform/findOrder/list`,
        method: 'get', //可以省略 不写默认是get
        params: {
          isPaging: 0,
          order_no: this.order_no,
        },
      }).then((response) => {
        let res = response.data
        this.result = res.data.list
        // console.log('pppp',result[0].id)
      })
    },
    fetchDetail() {
      taList({
        order_no: this.order_no,
      }).then((response) => {
        let res = response.data
        this.result = res.data.list
        // console.log("pp-o",this.result[0].id)
        this.teacher_allocation_order_id = this.result[0].id
        this.getDataList(true)
      })
    },
    formateSubjects(row) {
      let arr = row.apply_subject_names
        ? row.apply_subject_names.split(',')
        : []
      let code_arr = row.apply_subject_codes
        ? row.apply_subject_codes.split(',')
        : []
      return arr.length
        ? arr
            .map((v, i) => {
              return `(${code_arr[i]})${v}`
            })
            .join('+')
        : '无'
    },
    formateIds(ids) {
      if (ids && this.$store.state.staffOptions.length) {
        let arr = ids.split(',').map((v) => {
          return this.$store.state.staffOptions.find((t) => {
            return t.id == v
          }).nickname
        })
        return arr.join(',')
      } else {
        return '无'
      }
    },
    formateName(row) {
      if (row.type == 1) {
        return row.grade ? row.grade.name : '无'
      } else if (row.type == 3) {
        return row.dispatch_order ? row.dispatch_order.student_name : '无'
      } else {
        return '无'
      }
    },
    formateDurationTime(row) {
      let time = new Date().getTime() - row.create_time
      return this.formatDuring(time)
    },
    formateDuration(row) {
      if (row.spend_duration) {
        return this.formatDuring(row.spend_duration)
      }
    },

    formateType(row) {
      let types = {
        1: '外包',
        2: '代找',
        3: '代签',
      }
      return types[row.type]
    },
    formatDuring(millisecond) {
      var days = parseInt(millisecond / (1000 * 60 * 60 * 24))
      var hours = parseInt(
        (millisecond % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      var minutes = parseInt((millisecond % (1000 * 60 * 60)) / (1000 * 60))
      var seconds = (millisecond % (1000 * 60)) / 1000
      if (days < 7) {
        return `<span style="color:#67C23A;">${
          days + '天' + hours + '小时'
        }</span>`
      } else if (days < 14) {
        return `<span style="color:#E6A23C;">${
          days + '天' + hours + '小时'
        }</span>`
      } else {
        return `<span style="color:#F56C6C;">${
          days + '天' + hours + '小时'
        }</span>`
      }
      /*  return days + "天" + hours + "小时"; */
    },
    handleToClose() {
      this.$emit('close')
    },
  },
}
</script>
<style lang="less" >
.footer-wrap {
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
  align-items: center;
}
</style>