<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>师资</el-breadcrumb-item>
        <el-breadcrumb-item>记录查询</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">

      </div>
    </div>
    <el-card style="margin-top: 20px">
      <el-form class="search-wrap"
               ref="searchForm"
               :inline="true"
               :model="query"
               size="small"
               label-position="left">
        <el-form-item label="类型：">
          <el-select v-model="query.types"
                     placeholder="请选择类型"
                     clearable
                     @change="fetchList(true)"
                     multiple>
            <el-option v-for="(item, index) in filters.types"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="记录人">
          <el-select v-model="query.manage_creators"
                     placeholder="请选择"
                     @change="fetchList(true)"
                     multiple
                     clearable>
            <el-option v-for="item in $store.state.staffOptions.filter(v=>{
                return v.depts.findIndex(t=>{
                  return v.status == 1
                })!=-1
              })"
                       :key="item.id"
                       :label="item.nickname"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker class="datetime"
                          v-model="create_time"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :default-time="['00:00:00', '23:59:59']"
                          @change="fetchList(true)"
                          clearable>
          </el-date-picker>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
      </div>
      <el-table :data="result"
                :header-cell-style="{
        backgroundColor: '#F2F6FC',
        color: '#909399',
        fontSize: '14px',
      }"
                :cell-style="{ fontSize: '13px' }"
                border>
        <el-table-column prop="id"
                         label="ID"
                         align="center"
                         width="80">
        </el-table-column>

        <el-table-column label="类别"
                         align="center"
                         width="120"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="mini"
                    v-if="scope.row.type == 1">分配</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 2">备注</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 3">修改</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 4">跟进</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 5">签单</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 6">申请</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 7">沟通</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 8">记录</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 9">提交</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 10"
                    type="warning">修改</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 11">加入</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 12"
                    type="success">确认</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 13"
                    type="danger">驳回</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 14"
                    type="danger">取消</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 15"
                    type="warning">稍签</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 16"
                    type="warning">完结</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 17"
                    type="warning">推荐</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 18"
                    type="success">确认</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 19"
                    type="success">签约</el-tag>

            <el-tag size="mini"
                    v-if="scope.row.type == 20"
                    type="success">同步</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 21"
                    type="success">入驻</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="record"
                         label="沟通/操作记录"
                         align="left"
                         min-width="600"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-html="scope.row.record.replace(/\n|\r\n/g, '<br>').replace(/ /g, ' &nbsp')">

            </div>
            <span style="color:#409EFF;">{{ formateAltManager(scope.row.alt_manage_ids) }}</span>
            <div class="img-list"
                 v-if="scope.row.record_img_urls">
              <img v-for="(item, index) in JSON.parse(scope.row.record_img_urls)"
                   :key="index"
                   :src="item.url"
                   alt=""
                   srcset=""
                   @click="openViewer(JSON.parse(scope.row.record_img_urls),index)">
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="teacher_allocation_order_no"
                         label="沟通订单编号"
                         align="center"
                         show-overflow-tooltip
                         width="130">
          <template slot-scope="scope">
            <el-link :underline="false"
                     type="primary"
                     @click="handleToOrderDetail(scope.row.teacher_allocation_order_no, false,scope.row)"
                     v-if="scope.row.teacher_allocation_order_no">{{ scope.row.teacher_allocation_order_no }}</el-link>
            <el-link :underline="false"
                     type="primary"
                     @click="handleToOrderDetail(scope.row.share_find_order_no, true,scope.row)"
                     v-else-if="scope.row.share_find_order_no">{{ scope.row.share_find_order_no }}</el-link>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column label="沟通老师"
                         align="center"
                         show-overflow-tooltip
                         width="100">
          <template slot-scope="scope">
            <el-link :underline="false"
                     type="primary"
                     @click="handleToDetail(scope.row.teacher_id)"
                     v-if="scope.row.teacher_user_realname && scope.row.teacher_id">{{ scope.row.teacher_user_realname
              }}</el-link>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column label="记录人"
                         align="center"
                         width="120">
          <template slot-scope="scope">
            {{ scope.row.manage_creator ? scope.row.manage_creator.nickname : "" }}
          </template>
        </el-table-column>
        <el-table-column label="日期"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{ scope.row.create_time ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm") : "" }}
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         width="120"
                         align="center"
                         fixed="right">
          <template slot-scope="scope">
            <el-link type="danger"
                     :underline="false"
                     @click="handleToRemove(scope.row)"
                     :disabled="![6, 7, 8].includes(scope.row.type)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="operation">
        <div class="block"
             style="margin-bottom:0px;">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>
    <SeniorDetail :isVisible="SeniorDetailDialog"
                  @close="SeniorDetailDialog = false"
                  :id="detailId" />
    <RecordOrderDetail :order_flag="order_flag"
                       :isVisible="OrderDetailDialog"
                       @close="OrderDetailDialog = false"
                       :order_no="order_no"
                       :order_id='order_id'
                       :force_inner_order_types="force_inner_order_types" />
    <el-image-viewer v-if="showViewer"
                     :url-list="imgs"
                     :zIndex="999999999"
                     :on-close="closeViewer"
                     :initial-index="initialIndex" />
  </div>
</template>
        
<script>
import TableTittle from '@/components/TableTittle/TableTittle.vue'
import SeniorDetail from '@/components/DialogComponents/TeacherResources/SeniorDetail.vue'
import RecordOrderDetail from '@/components/DialogComponents/TeacherResources/RecordOrderDetail.vue'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

import { taRecordList, taRecordDelete } from '@/request/api'
import { f } from 'vue-canvas-poster'
export default {
  components: {
    TableTittle,
    SeniorDetail,
    RecordOrderDetail,
    ElImageViewer,
  },
  data() {
    return {
      initialIndex: 0,
      TableName: '记录列表',
      result: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        is_oauth: 1,
        force_inner_order_types: '1,2',
      },
      pageTotal: 0,
      filters: {
        types: [
          { label: '分配', value: 1 },
          { label: '备注', value: 2 },
          { label: '修改', value: 3 },
          { label: '跟进', value: 4 },
          { label: '签单', value: 5 },
          { label: '申请', value: 6 },
          { label: '沟通', value: 7 },
          { label: '记录', value: 8 },
          { label: '提交匹配', value: 9 },
          { label: '修改匹配', value: 10 },
          { label: '已加入', value: 11 },
          { label: '已确认', value: 12 },
          { label: '已驳回', value: 13 },
          { label: '取消订单', value: 14 },
          { label: '稍签', value: 15 },
          { label: '完结', value: 16 },
          { label: '推荐', value: 17 },
          { label: '确认', value: 18 },
          { label: '签约', value: 19 },
          { label: '同步', value: 20 },
          { label: '入驻', value: 21 },
        ],
      },
      create_time: '',
      detailId: null,
      SeniorDetailDialog: false,
      OrderDetailDialog: false,
      order_no: null,
      order_flag: false,
      showViewer: false,
      imgs: [],
      order_id: '',
      force_inner_order_types: '',
    }
  },
  created() {
    this.parseQuery()
    this.fetchList()
    this.$store.dispatch('getStaffOptions')
  },
  watch: {
    // 时间数据监听
    create_time(value) {
      if (value) {
        this.query.start_create_time = this.$moment(value[0]).valueOf()
        this.query.end_create_time = this.$moment(value[1]).valueOf()
      } else {
        this.query.start_create_time = ''
        this.query.end_create_time = ''
        this.fetchList(true)
      }
    },
  },
  computed: {},
  methods: {
    // 关闭查看器
    closeViewer() {
      this.showViewer = false
    },
    openViewer(url, index) {
      this.imgs = url.map((v) => {
        return v.url
      })
      this.initialIndex = index
      this.showViewer = true
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.fetchList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.fetchList()
    },
    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      const params = Object.assign({}, this.query)
      if (params.types) {
        params.types = params.types.join(',')
      }
      if (params.manage_creators) {
        params.manage_creators = params.manage_creators.join(',')
      }
      taRecordList(params).then((response) => {
        let res = response.data
        this.result = res.data.list || []
        let regex = /将.*?由/g
        this.result.map((item) => {
          item.record = item.record.replace(
            regex,
            (match) => `<div /style="color:#409EFF;">${match}</div>`
          )
          item.record = item.record.replace(
            /(更改为|(?<=，)(?:(?!学生)[^，])*?由)/g,
            '<div /style="color:#409EFF">$1</div>'
          )
        })
        this.pageTotal = res.data.count
      })
      this.syncQuery()
    },
    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key]
      })
      if (this.query.types && this.query.types.length) {
        this.query.types = this.query.types.split(',').map((v) => {
          return Number(v)
        })
      }
      if (this.query.manage_creators && this.query.manage_creators.length) {
        this.query.manage_creators = this.query.manage_creators
          .split(',')
          .map((v) => {
            return Number(v)
          })
      }
    },

    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query)
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      )
      if (params.types) {
        params.types = params.types.filter((v) => v).join(',')
      }
      if (params.manage_creators) {
        params.manage_creators = params.manage_creators
          .filter((v) => v)
          .join(',')
      }
      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        })
    },
    formateAltManager(ids) {
      if (ids && this.$store.state.staffOptions.length) {
        let str = ids.split(',').map((v) => {
          return `@${
            this.$store.state.staffOptions.find((t) => {
              return t.id == v
            }).nickname
          }`
        })

        return str.join(',')
      } else {
        return ''
      }
    },
    handleToDetail(id) {
      this.detailId = id
      this.SeniorDetailDialog = true
    },

    handleToOrderDetail(no, flag, row) {
      this.order_id = row.teacher_allocation_order_id
      if (row.inner_order_type == 1) {
        this.force_inner_order_types = null
      } else {
        this.force_inner_order_types = 2
      }
      this.order_no = Number(no)
      this.order_flag = flag
      this.OrderDetailDialog = true
    },
    handleToRemove(row) {
      this.$confirm('确定要删除此条记录吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        taRecordDelete({ id: row.id }).then((res) => {
          this.fetchList(true)
        })
      })
    },
  },
}
</script>
        
<style lang="less" scoped>
.content {
  .operation {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
  }

  .el-link {
    font-size: 14px;
    margin: 0px 5px;
  }

  .img-list {
    margin-top: 12px;
    display: flex;
    align-items: center;

    img {
      width: 140px;
      height: 140px;
      margin-right: 12px;
      border-radius: 4px;
    }
  }
}
</style>
        