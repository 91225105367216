<template>
  <div class="content">
    <el-dialog top="5vh"
               title=""
               :visible.sync="dialogFormVisible"
               @close="handleToClose"
               :close-on-click-modal="false"
               width="70%"
               append-to-body>
      <div class="search-wrap">
        <el-form class="search-wrap"
                 ref="searchForm"
                 :inline="true"
                 :model="query"
                 size="small"
                 label-position="left">
          <el-form-item label="类型：">
            <el-select v-model="query.types"
                       placeholder="请选择类型"
                       clearable
                       @change="fetchList(true)"
                       multiple>
              <el-option v-for="(item, index) in filters.types"
                         :key="index"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="记录人">
            <el-select v-model="query.manage_creators"
                       placeholder="请选择"
                       @change="fetchList(true)"
                       multiple
                       clearable>
              <el-option v-for="item in $store.state.staffOptions"
                         :key="item.id"
                         :label="item.nickname"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker class="datetime"
                            v-model="create_time"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            @change="fetchList(true)"
                            clearable
                            :default-time="['00:00:00', '23:59:59']">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="result"
                :header-cell-style="{
          backgroundColor: '#F2F6FC',
          color: '#909399',
          fontSize: '14px',
        }"
                :cell-style="{ fontSize: '13px' }"
                border>
        <el-table-column label="记录人"
                         align="center"
                         width="120">
          <template slot-scope="scope">
            {{
              scope.row.manage_creator ? scope.row.manage_creator.nickname : ""
            }}
          </template>
        </el-table-column>

        <el-table-column label="类别"
                         align="center"
                         width="120"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="mini"
                    v-if="scope.row.type == 1">分配</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 2">备注</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 3">修改</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 4">跟进</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 5">签单</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 6">申请</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 7">沟通</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 8">记录</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 9">提交</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 10"
                    type="warning">修改</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 11">加入</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 12"
                    type="success">确认</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 13"
                    type="danger">驳回</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 14"
                    type="danger">取消</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 15"
                    type="warning">稍签</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 16"
                    type="warning">完结</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 17"
                    type="warning">推荐</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 18"
                    type="success">确认</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 19"
                    type="success">签约</el-tag>
            <el-tag size="mini"
                    v-if="scope.row.type == 20"
                    type="success">同步</el-tag>

            <el-tag size="mini"
                    v-if="scope.row.type == 21"
                    type="success">入驻</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="record"
                         label="沟通/操作记录"
                         align="left"
                         min-width="500">
          <template slot-scope="scope">
            <!-- <div v-html="scope.row.record.replace(/\n|\r\n/g, '<br>').replace(/ /g, ' &nbsp')"></div> -->
            <div class="partAfter"
                 v-if="scope.row.recordContent.includes('$')">
              <div v-html="scope.row.recordContent.split('$')[1]">
              </div>
              <div v-if="scope.row.recordContent.includes('url')"
                   class="copyImg">
                <img v-for="(item, index) in JSON.parse(scope.row.recordContent.split('$')[ scope.row.recordContent.split('$').length -1])"
                     :key="index"
                     :src="item.url"
                     alt=""
                     srcset=""
                     @click="
                  openViewer(JSON.parse(scope.row.recordContent.split('$')[ scope.row.recordContent.split('$').length -1]), index)
                " />
              </div>
            </div>
            <div :class="{'isActive':scope.row.partBefore}">
              <div v-html="getRecord(scope.row.partBefore?scope.row.partBefore:scope.row.record,scope.row)"></div>
              <span style="color: #409eff">{{
              formateAltManager(scope.row.alt_manage_ids)
            }}</span>
              <div class="img-list"
                   v-if="scope.row.record_img_urls">
                <img v-for="(item, index) in JSON.parse(scope.row.record_img_urls)"
                     :key="index"
                     :src="item.url"
                     alt=""
                     srcset=""
                     @click="
                  openViewer(JSON.parse(scope.row.record_img_urls), index)
                " />
              </div>
            </div>

          </template>
        </el-table-column>

        <el-table-column prop="teacher_allocation_order_no"
                         label="沟通订单编号"
                         align="center"
                         show-overflow-tooltip
                         width="130"
                         v-if="type == 'teacher_read' || type == 'grade_read'">
          <template slot-scope="scope">
            <el-link :underline="false"
                     type="primary"
                     @click="
                handleToOrderDetail(
                  scope.row.teacher_allocation_order_no,
                  false,
                  scope.row
                )
              "
                     v-if="
                scope.row.inner_order_type == 1 &&
                scope.row.teacher_allocation_order_no
              ">{{ scope.row.teacher_allocation_order_no }}</el-link>

            <el-link :underline="false"
                     type="primary"
                     @click="
                handleToOrderDetail(
                  scope.row.share_find_order_no,
                  true,
                  scope.row
                )
              "
                     v-else-if="
                scope.row.inner_order_type == 2 &&
                scope.row.teacher_allocation_order_id
              ">{{ scope.row.share_find_order_no }}</el-link>
            <!-- <el-link
              :underline="false"
              type="primary"
              @click="handleToOrderDetail(scope.row.teacher_allocation_order_no)"
              v-if="scope.row.teacher_allocation_order_no"
            >{{ scope.row.teacher_allocation_order_no }}</el-link> -->
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column label="沟通老师"
                         align="center"
                         show-overflow-tooltip
                         width="100"
                         v-if="type == 'order_read' || type == 'grade_read'">
          <template slot-scope="scope">
            <el-link :underline="false"
                     type="primary"
                     @click="handleToDetail(scope.row.teacher_id)"
                     v-if="scope.row.teacher_user_realname && scope.row.teacher_id">{{ scope.row.teacher_user_realname }}</el-link>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column label="日期"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{
              scope.row.create_time
                ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>

        <el-table-column label="操作"
                         width="120"
                         align="center">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="handleToReplay(scope.row)">回复</el-link>
            <el-link type="danger"
                     :underline="false"
                     @click="handleToRemove(scope.row)"
                     :disabled="![6, 7, 8].includes(scope.row.type)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer-wrap">
        <el-button size="small"
                   @click="handleToLog()">新增记录</el-button>
        <el-pagination background
                       layout="total, prev, pager, next, sizes, jumper"
                       :current-page="Number(query.pageIndex)"
                       :page-sizes="[10, 20, 50, 100, 500]"
                       :page-size="Number(query.pageSize)"
                       :total="Number(pageTotal)"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"></el-pagination>
      </div>
    </el-dialog>
    <Log :isVisible="logDialog"
         :isReplay="isReplay"
         :rePlayContent="rePlayContent"
         :written="written"
         :id="logId"
         :teacher_id="teacher_id"
         :order_id="teacher_allocation_order_id"
         @close="logDialog = false"
         @submit="fetchList"></Log>
    <SeniorDetail :isVisible="SeniorDetailDialog"
                  @close="SeniorDetailDialog = false"
                  :id="detailId" />
    <RecordOrderDetail :order_flag="order_flag"
                       :isVisible="OrderDetailDialog"
                       @close="OrderDetailDialog = false"
                       :order_no="order_no"
                       :order_id="orderId"
                       :force_inner_order_types="force_inner_order_types" />
    <el-image-viewer v-if="showViewer"
                     :url-list="imgs"
                     :zIndex="999999999"
                     :on-close="closeViewer"
                     :initial-index="initialIndex" />
  </div>
</template>

<script>
import { taRecordList, taRead, taRecordDelete } from '@/request/api'
import {} from '@/utils/helper'
import SeniorDetail from '@/components/DialogComponents/TeacherResources/SeniorDetail.vue'
import RecordOrderDetail from '@/components/DialogComponents/TeacherResources/RecordOrderDetail.vue'
import Log from '@/components/DialogComponents/TeacherResources/Log.vue'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

export default {
  components: {
    Log,
    SeniorDetail,
    RecordOrderDetail,
    ElImageViewer,
  },
  props: {
    teacherNo: {
      default: false,
    },
    flag: {
      default: false,
    },
    written: {
      default: false,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    teacher_allocation_order_id: {
      type: Number,
      default: null,
    },
    teacher_id: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: '',
    },
    grade_id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      rePlayContent: {},
      isReplay: false,
      initialIndex: 0,
      order_flag: false,
      dialogFormVisible: this.isVisible,
      dataForm: {},
      rules: {
        /*   name: [{ required: true, message: "请输入班级名称", trigger: "blur" }], */
      },
      result: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        force_inner_order_types: '',
      },
      pageTotal: 0,
      logDialog: false,
      logId: null, // 老师id
      order_id: null,
      filters: {
        types: [
          { label: '分配', value: 1 },
          { label: '备注', value: 2 },
          { label: '修改', value: 3 },
          { label: '跟进', value: 4 },
          { label: '签单', value: 5 },
          { label: '申请', value: 6 },
          { label: '沟通', value: 7 },
          { label: '记录', value: 8 },
          { label: '提交', value: 9 },
          { label: '修改', value: 10 },
          { label: '加入', value: 11 },
          { label: '确认', value: 12 },
          { label: '驳回', value: 13 },
          { label: '取消', value: 14 },
          { label: '稍签', value: 15 },
          { label: '完结', value: 16 },
          { label: '推荐', value: 17 },
          { label: '确认', value: 18 },
          { label: '签约', value: 19 },
          { label: '同步', value: 20 },

          { label: '入驻', value: 21 },
        ],
      },
      create_time: '',
      SeniorDetailDialog: false,
      detailId: null,
      OrderDetailDialog: false,
      order_no: null,
      showViewer: false,
      imgs: [],
      orderId: '',
      force_inner_order_types: '',
    }
  },
  mounted() {
    window.goorderNumber = this.goorderNumber
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val
      if (val) {
        // console.log("tyoe",this.teacher_id)
        if (this.flag) {
          this.query.force_inner_order_types = '1,2'
        }
        this.fetchList(true)
        // console.log(this.teacher_allocation_order_id);
      }
    },
    // 时间数据监听
    create_time(value) {
      if (value) {
        this.query.start_create_time = this.$moment(value[0]).valueOf()
        this.query.end_create_time = this.$moment(value[1]).valueOf()
      } else {
        this.query.start_create_time = ''
        this.query.end_create_time = ''
        this.fetchList(true)
      }
    },
  },

  methods: {
    handleToReplay(row) {
      this.isReplay = true
      this.rePlayContent = row
      this.logDialog = true
    },
    goorderNumber(orderNumber, row) {
      this.orderId = row.teacher_allocation_order_id
      if (row.inner_order_type == 1) {
        this.force_inner_order_types = null
      } else {
        this.force_inner_order_types = 2
      }

      if (row.inner_order_type == 1 && row.teacher_allocation_order_no) {
        this.order_flag = false
      } else if (row.inner_order_type == 2 && row.teacher_allocation_order_id) {
        this.order_flag = true
      }
      this.order_no = orderNumber
      this.OrderDetailDialog = true
    },
    getRecord(text, row) {
      if (text.includes('订单')) {
        text = text.replace(/\n|\r\n/g, '<br>').replace(/ /g, ' &nbsp')
        let list = text.split('订单').map((item, index) => {
          if (index == 0) return item
          if (item.length < 10) return item
          let orderNumber = item.substr(0, 10)
          if (orderNumber.trim().length < 10) return item
          if (Number(orderNumber)) {
            const style = `<span onclick='goorderNumber(${orderNumber},${JSON.stringify(
              row
            )})' style="color: #409EFF;cursor: pointer;">$&</span>`
            return item.replace(new RegExp(orderNumber, 'g'), style)
          }
          return item
        })
        return list.join('订单')
      } else {
        return text.replace(/\n|\r\n/g, '<br>').replace(/ /g, ' &nbsp')
      }
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false
    },
    openViewer(url, index) {
      console.log('urls', url)
      this.imgs = url.map((v) => {
        return v.url
      })
      this.initialIndex = index
      this.showViewer = true
    },
    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      const params = Object.assign({}, this.query, {
        teacher_allocation_order_id: this.teacher_allocation_order_id || '',
        teacher_id: this.teacher_id || '',
      })
      if (params.types) {
        params.types = params.types.join(',')
      }
      if (params.manage_creators) {
        params.manage_creators = params.manage_creators.join(',')
      }

      if (this.teacherNo) {
        params.teacher_allocation_order_id = ''
      }
      taRecordList(params).then((response) => {
        let res = response.data
        this.result = res.data.list || []
        let regex = /将.*?由/g
        this.result.map((item) => {
          item.recordContent = item.record
          // 新增加一个字段用来区别回复内容
          if (item.record.includes('$')) {
            item.partBefore = item.record.split('$')[0]
            // item.partAfter = item.record.split('$')[1]
          }

          if (item.record.split('$')[1]) {
            console.log('111')
          } else {
            item.record = item.record.replace(
              regex,
              (match) => `<div /style="color:#409EFF;">${match}</div>`
            )
            item.record = item.record.replace(
              /(更改为|(?<=，)(?:(?!学生)[^，])*?由)/g,
              '<div /style="color:#409EFF">$1</div>'
            )
          }
        })
        console.log('this.result', this.result)
        this.pageTotal = res.data.count
      })
      if (this.type == 'order_read') {
        this.read({
          teacher_allocation_order_id: this.teacher_allocation_order_id,
        })
      } else if (this.type == 'grade_read') {
        this.read({
          grade_id: this.grade_id,
        })
      } else if (this.type == 'teacher_read') {
        this.read({
          teacher_id: this.teacher_id,
        })
      }
    },
    read(data) {
      taRead(data).then((response) => {})
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.fetchList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.fetchList()
    },
    handleToClose() {
      this.$emit('close')
      this.$emit('submit')
    },
    handleToLog() {
      this.order_id = this.teacher_allocation_order_id
      this.logId = this.teacher_id
      this.isReplay = false
      this.logDialog = true
    },
    formateAltManager(ids) {
      if (ids) {
        let str = ids.split(',').map((v) => {
          return `@${
            this.$store.state.staffOptions.find((t) => {
              return t.id == v
            }).nickname
          }`
        })

        return str.join(',')
      } else {
        return ''
      }
    },
    handleToDetail(id) {
      this.detailId = id
      this.SeniorDetailDialog = true
    },
    handleToOrderDetail(no, flag, row) {
      this.orderId = row.teacher_allocation_order_id
      if (row.inner_order_type == 1) {
        this.force_inner_order_types = null
      } else {
        this.force_inner_order_types = 2
      }
      if (flag) {
        this.order_flag = true
      } else {
        this.order_flag = false
      }
      this.order_no = Number(no)
      this.OrderDetailDialog = true
    },
    handleToRemove(row) {
      this.$confirm('确定要删除此条记录吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        taRecordDelete({ id: row.id }).then((res) => {
          this.fetchList(true)
        })
      })
    },
  },
}
</script>
  
<style lang="less" scoped>
.isActive {
  font-size: 16px;
  width: fit-content;
  padding: 10px;
  background: #fff;
  border-radius: 8px;
}
.copyImg {
  width: 40px;
  height: 40px;
  img {
    width: 100%;
    height: 100%;
  }
}
.partAfter {
  font-size: 11px;
  width: fit-content;
  padding: 5px;
  border-radius: 8px;
  background: #f3f4f5;
}
.search-wrap {
  margin-bottom: 24px;
}

.footer-wrap {
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
  align-items: center;
}

.img-list {
  margin-top: 12px;
  display: flex;
  align-items: center;

  img {
    width: 140px;
    height: 140px;
    margin-right: 12px;
    border-radius: 4px;
  }
}
</style>