<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>师资</el-breadcrumb-item>
        <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
        <el-button type="primary"
                   plain
                   @click="handleWeight"
                   size="small">查看权重</el-button>
        <el-button icon="el-icon-download"
                   @click="handleExport"
                   size="small">导出</el-button>
        <el-button type="primary"
                   size="small"
                   @click="openAutoDialog"
                   v-if="$buttonPermission('taOrder:auto')">自动分单</el-button>
      </div>
    </div>
    <el-card style="margin-top: 20px">
      <el-form class="search-wrap"
               ref="searchForm"
               :inline="true"
               :model="query"
               size="small"
               label-position="left">
        <el-form-item label="订单编号">
          <el-input v-model.trim="query.order_no"
                    placeholder="请输入订单编号"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="辅导对象">
          <el-input v-model.trim="query.order_keyword"
                    placeholder="请输入辅导对象"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="机构"
                      prop="cid">
          <el-select v-model="query.cid"
                     placeholder="请选择机构"
                     filterable
                     @change="fetchList"
                     clearable>
            <el-option v-for="item in $store.state.CompanyList"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="类型"
                      prop="types">
          <el-select v-model="query.types"
                     placeholder="请选择"
                     @change="fetchList"
                     multiple
                     clearable>
            <el-option v-for="item in filters.types"
                       :key="item.id"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="状态"
                      prop="types">
          <el-select v-model="query.statuses"
                     placeholder="请选择"
                     @change="fetchList"
                     multiple
                     clearable>
            <el-option v-for="item in filters.statuses"
                       :key="item.id"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="同步状态">
          <el-select v-model="query.ref_find_order_exist_statuses"
                     placeholder="请选择"
                     @change="fetchList(true)"
                     clearable>
            <el-option label="已同步"
                       value="1"></el-option>
            <el-option label="未同步"
                       value="0"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="共享订单状态"
                      v-if="query.ref_find_order_exist_statuses == 1">
          <el-select v-model="query.ref_find_order_statuses"
                     placeholder="请选择"
                     @change="fetchList"
                     multiple
                     clearable>
            <el-option v-for="item in filters.find_order_statusesList"
                       :key="item.id"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="院校">
          <el-input v-model.trim="query.apply_college"
                    placeholder="请输入院名称/代码"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="专业">
          <el-input v-model.trim="query.apply_major"
                    placeholder="请输入专业名称/代码"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="科目">
          <el-input v-model.trim="query.apply_subject"
                    placeholder="请输入科目名称/代码"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="搜索">
          <el-input v-model.trim="query.keyword"
                    placeholder="请输入学院/方向"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="匹配师">
          <el-select v-model="query.allocation_manage_idses"
                     placeholder="请选择"
                     @change="fetchList(true)"
                     clearable
                     multiple
                     collapse-tags>
            <el-option v-for="item in $store.state.staffOptions.filter((v) => {
                return (
                  v.depts.findIndex((t) => {
                    return t.id == 5 && v.status == 1;
                  }) != -1
                );
              })"
                       :key="item.id"
                       :label="item.nickname"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学管师">
          <el-select v-model="query.learn_manage_ids"
                     placeholder="请选择"
                     @change="fetchList(true)"
                     clearable
                     multiple
                     collapse-tags>
            <el-option v-for="item in $store.state.staffOptions.filter((v) => {
                return (
                  v.depts.findIndex((t) => {
                    return t.id == 4 && v.status == 1;
                  }) != -1
                );
              })"
                       :key="item.id"
                       :label="item.nickname"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="匹配时效">
          <el-select v-model="query.min_spend_duration"
                     placeholder="请选择"
                     @change="fetchList(true)"
                     clearable>
            <el-option label="超3天"
                       :value="259200000"> </el-option>
            <el-option label="超7天"
                       :value="604800000"> </el-option>
            <el-option label="超15天"
                       :value="1296000000"> </el-option>
            <el-option label="超30天"
                       :value="2592000000"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报名时间">
          <el-date-picker class="datetime"
                          v-model="create_time"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          @change="fetchList(true)"
                          clearable
                          :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="老师">
          <el-input v-model.trim="query.sign_teacher_keyword"
                    placeholder="请输入姓名/手机号"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px"
             v-if="query.is_statistic_company_earn == 0">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
        <el-form size="small"
                 :inline="true">
          <el-form-item label="只看关注"
                        label-width="120">
            <el-switch v-model="query.follow"
                       :active-value="1"
                       :inactive-value="0"
                       @change="fetchList(true)">
            </el-switch>
          </el-form-item>
          <el-form-item label-width="32px">
            <el-switch v-model="query.is_statistic_company_earn"
                       :active-value="1"
                       :inactive-value="0"
                       active-text="数据报表"
                       inactive-text="订单详情"
                       @change="switchChange">
            </el-switch>
          </el-form-item>
          <el-form-item label-width="32px">
            <el-radio-group v-model="order_status"
                            @change="fetchList(true)">
              <el-radio-button label="">全部</el-radio-button>
              <el-radio-button :label="'2,5'">匹配中</el-radio-button>

              <el-badge :value="finishedCheckWait"
                        class="item"
                        :hidden="finishedCheckWait == 0">
                <el-radio-button :label="'3'">已完结(等)</el-radio-button>
              </el-badge>
              <!-- finishedCheckWait -->
              <el-badge :value="finishedCheck"
                        class="item"
                        :hidden="finishedCheck == 0">
                <el-radio-button :label="'2'">已完结</el-radio-button>
              </el-badge>
              <el-badge :value="uncheck"
                        class="item"
                        :hidden="uncheck == 0">
                <el-radio-button :label="1">待分配</el-radio-button>
              </el-badge>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="result"
                :header-cell-style="{
          backgroundColor: '#F2F6FC',
          color: '#909399',
          fontSize: '14px',
        }"
                :cell-style="{ fontSize: '13px' }"
                @selection-change="handleSelectionChange"
                border>
        <el-table-column prop="date"
                         label="关注"
                         width="50"
                         fixed="left"
                         align="center">
          <template slot-scope="scope">
            <img alt=""
                 srcset=""
                 class="star"
                 src="@/assets/images/star-fill.png"
                 @click="followDelete(scope.row)"
                 v-show="scope.row.follow" />
            <img src="@/assets/images/star.png"
                 alt=""
                 srcset=""
                 class="star"
                 v-show="!scope.row.follow"
                 @click="followCreate(scope.row)" />
          </template>
        </el-table-column>
        <el-table-column label="沟通记录"
                         fixed="left"
                         align="center"
                         width="100">
          <template slot-scope="scope">
            <div class="badge">
              <el-badge :value="
                  scope.row.record_alt_unreaded_count &&
                  scope.row.record_alt_unreaded_count > 0
                    ? '@'
                    : scope.row.record_msg_unreaded_count
                "
                        class="item"
                        :hidden="
                  !scope.row.record_alt_unreaded_count &&
                  !scope.row.record_msg_unreaded_count
                ">
                <el-link type="primary"
                         :underline="false"
                         @click="checkRecord(scope.row.id)">查看</el-link>
              </el-badge>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="spend_duration"
                         label="时效"
                         align="center"
                         width="120">
          <template slot-scope="scope">
            <div class="spend-wrap">
              <img src="@/assets/images/ji_tag.png"
                   alt=""
                   srcset=""
                   v-if="scope.row.urgency_status == 1" />
              <div v-html="formateDuration(scope.row)"></div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="类别"
                         align="center"
                         width="80">
          <template slot-scope="scope">
            {{ formateType(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="订单状态"
                         align="center"
                         width="80"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="small"
                    v-if="scope.row.status == 1">待分配</el-tag>
            <el-tag size="small"
                    v-if="scope.row.status == 2 && scope.row.hard_dot_status == 0">匹配中</el-tag>
            <el-tag size="small"
                    type="danger"
                    v-if="scope.row.status == 2 && scope.row.hard_dot_status == 1">已完结</el-tag>
            <el-tag size="small"
                    v-if="scope.row.status == 3"
                    type="warning">待确认</el-tag>
            <el-tag size="small"
                    v-if="scope.row.status == 4"
                    type="success">已完成</el-tag>
            <el-tag type="danger"
                    size="small"
                    v-if="scope.row.status == 5 && scope.row.hard_dot_status == 0">返单中</el-tag>
            <el-tag type="danger"
                    size="small"
                    v-if="scope.row.status == 5 && scope.row.hard_dot_status == 1">已完结</el-tag>
            <el-tag type="info"
                    size="small"
                    v-if="scope.row.status == 6">已取消</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="同步状态"
                         align="center"
                         width="100"
                         show-overflow-tooltip
                         v-if="order_status == 2">
          <template slot-scope="scope">
            <el-tag size="small"
                    v-if="scope.row.ref_exist_find_order_status == 1">已同步</el-tag>
            <el-tag size="small"
                    v-if="scope.row.ref_exist_find_order_status == 0"
                    type="warning">未同步</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="共享订单状态"
                         align="center"
                         width="120"
                         v-if="order_status == 2"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="small"
                    v-if="scope.row.ref_find_order_status == 1">待接单</el-tag>
            <el-tag size="small"
                    v-if="scope.row.ref_find_order_status == 2">接单中</el-tag>
            <el-tag size="small"
                    type="warning"
                    v-if="scope.row.ref_find_order_status == 3">待支付</el-tag>
            <el-tag size="small"
                    type="warning"
                    v-if="scope.row.ref_find_order_status == 4">待确认</el-tag>
            <el-tag size="small"
                    type="success"
                    v-if="scope.row.ref_find_order_status == 5">已完成</el-tag>
            <el-tag size="small"
                    type="info"
                    v-if="scope.row.ref_find_order_status == 6">已取消</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="机构简称"
                         align="center"
                         width="120"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.company ? scope.row.company.name : "无" }}
          </template>
        </el-table-column>
        <el-table-column prop="order_no"
                         label="订单编号"
                         align="center"
                         width="120">
          <template slot-scope="scope">
            {{ scope.row.order_no }}
            <img src="https://upload.kaoyanbox.net/74c77c5c9254681e2c978fcc27e3ebd9.png"
                 class="copy"
                 :data-clipboard-text="clipboardText"
                 @click="handleCopy(scope.row)" />

          </template>

        </el-table-column>
        <el-table-column label="辅导对象"
                         align="left"
                         width="180"
                         show-overflow-tooltip
                         v-if="query.is_statistic_company_earn == 0">
          <template slot-scope="scope">
            {{ formateName(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="报考信息"
                         align="left"
                         width="350"
                         show-overflow-tooltip
                         v-if="query.is_statistic_company_earn == 0">
          <template slot-scope="scope">
            <span>{{ scope.row.apply_year }}年 |
              <span class="pointer"
                    @click="institutionOpen(scope.row)">{{ scope.row.apply_college_name }} </span>| {{ scope.row.apply_academy_name }} | ({{
                scope.row.apply_major_code
              }}){{
                scope.row.apply_major_name.includes(scope.row.apply_major_code)
                  ? scope.row.apply_major_name.split(
                      `（${scope.row.apply_major_code}）`
                    )[1]
                  : scope.row.apply_major_name
              }}
              {{
                scope.row.apply_direction_code && scope.row.apply_direction_name
                  ? ` |
              (${scope.row.apply_direction_code})${scope.row.apply_direction_name}`
                  : ""
              }}</span>
          </template>
        </el-table-column>
        <el-table-column label="辅导科目"
                         align="left"
                         width="250"
                         show-overflow-tooltip
                         v-if="query.is_statistic_company_earn == 0">
          <template slot-scope="scope">
            <span>{{ formateSubjects(scope.row) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="辅导课时"
                         align="center"
                         width="100"
                         show-overflow-tooltip
                         v-if="query.is_statistic_company_earn == 0">
          <template slot-scope="scope">
            {{ scope.row.lesson_hours }}小时
          </template>
        </el-table-column>
        <el-table-column prop="allocation_demand"
                         label="要求"
                         align="left"
                         width="350"
                         show-overflow-tooltip
                         v-if="query.is_statistic_company_earn == 0">
        </el-table-column>
        <el-table-column label="复习基础"
                         width="240"
                         align="left"
                         prop="coach_need"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="参考书目"
                         width="240"
                         align="left"
                         prop="refer_book"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="老师时薪"
                         align="center"
                         width="150"
                         show-overflow-tooltip
                         v-if="query.is_statistic_company_earn == 1">
          <template slot-scope="scope"
                    v-if="query.is_statistic_company_earn == 1">
            {{
              scope.row.earn_amount_statistic_obj
                .arr_hour_expend_teahcer_amount &&
              scope.row.earn_amount_statistic_obj.arr_hour_expend_teahcer_amount
                .length
                ? scope.row.earn_amount_statistic_obj.arr_hour_expend_teahcer_amount
                    .map((v) => {
                      return `${v}元`;
                    })
                    .join("/")
                : "-"
            }}
          </template>
        </el-table-column>

        <el-table-column label="合同课时费"
                         align="center"
                         width="150"
                         show-overflow-tooltip
                         v-if="query.is_statistic_company_earn == 1">
          <template slot-scope="scope"
                    v-if="query.is_statistic_company_earn == 1">
            {{
              scope.row.earn_amount_statistic_obj &&
              scope.row.earn_amount_statistic_obj.hour_income_company_amount
                ? `${scope.row.earn_amount_statistic_obj.hour_income_company_amount}元`
                : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column label="总课时数"
                         align="center"
                         width="150"
                         show-overflow-tooltip
                         v-if="query.is_statistic_company_earn == 1">
          <template slot-scope="scope"
                    v-if="query.is_statistic_company_earn == 1">
            {{
              scope.row.earn_amount_statistic_obj &&
              scope.row.earn_amount_statistic_obj.total_company_hour
                ? `${scope.row.earn_amount_statistic_obj.total_company_hour}小时`
                : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column label="老师总课时数"
                         align="center"
                         width="150"
                         show-overflow-tooltip
                         v-if="query.is_statistic_company_earn == 1">
          <template slot-scope="scope"
                    v-if="query.is_statistic_company_earn == 1">
            {{
              scope.row.earn_amount_statistic_obj.arr_total_teacher_hour &&
              scope.row.earn_amount_statistic_obj.arr_total_teacher_hour.length
                ? scope.row.earn_amount_statistic_obj.arr_total_teacher_hour
                    .map((v) => {
                      return `${v}小时`;
                    })
                    .join("/")
                : "-"
            }}
          </template>
        </el-table-column>

        <el-table-column label="单位差价"
                         align="center"
                         width="150"
                         v-if="query.is_statistic_company_earn == 1"
                         show-overflow-tooltip>
          <template slot-scope="scope"
                    v-if="query.is_statistic_company_earn == 1">
            {{
              scope.row.earn_amount_statistic_obj.arr_hour_earn_amount &&
              scope.row.earn_amount_statistic_obj.arr_hour_earn_amount.length
                ? scope.row.earn_amount_statistic_obj.arr_hour_earn_amount
                    .map((v) => {
                      return `${v}元`;
                    })
                    .join("/")
                : "-"
            }}
          </template>
        </el-table-column>

        <el-table-column label="总费用"
                         align="center"
                         width="150"
                         v-if="query.is_statistic_company_earn == 1"
                         show-overflow-tooltip>
          <template slot-scope="scope"
                    v-if="query.is_statistic_company_earn == 1">
            {{
              scope.row.earn_amount_statistic_obj &&
              scope.row.earn_amount_statistic_obj.total_income_company_amount
                ? `${scope.row.earn_amount_statistic_obj.total_income_company_amount}元`
                : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column label="总差价"
                         align="center"
                         width="150"
                         show-overflow-tooltip
                         v-if="query.is_statistic_company_earn == 1">
          <template slot-scope="scope"
                    v-if="query.is_statistic_company_earn == 1">
            {{
              scope.row.earn_amount_statistic_obj &&
              scope.row.earn_amount_statistic_obj.total_earn_amount
                ? `${scope.row.earn_amount_statistic_obj.total_earn_amount}元`
                : scope.row.earn_amount_statistic_obj.total_earn_amount == 0
                ? "0元"
                : "-"
            }}
          </template>
        </el-table-column>

        <el-table-column label="总利润收入比"
                         align="center"
                         width="150"
                         v-if="query.is_statistic_company_earn == 1"
                         show-overflow-tooltip>
          <template slot-scope="scope"
                    v-if="query.is_statistic_company_earn == 1">
            {{
              scope.row.earn_amount_statistic_obj &&
              scope.row.earn_amount_statistic_obj.total_earn_income_rate
                ? `${scope.row.earn_amount_statistic_obj.total_earn_income_rate}%`
                : "-"
            }}
          </template>
        </el-table-column>

        <el-table-column label="匹配师"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{ formateIds(scope.row.allocation_manage_ids) }}
          </template>
        </el-table-column>
        <el-table-column label="学管师"
                         align="center"
                         width="120">
          <template slot-scope="scope">
            {{
              scope.row.learn_manage_user
                ? scope.row.learn_manage_user.nickname
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{
              scope.row.create_time
                ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
                : "无"
            }}
          </template>
        </el-table-column>
        <el-table-column label="更新时间"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{
              scope.row.update_time
                ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         fixed="right"
                         align="center"
                         width="180">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="handleToDistribution(scope.row)"
                     v-if="$buttonPermission('taOrder:allocation')">分配</el-link>
            <el-link type="primary"
                     :underline="false"
                     @click="handleOrder(scope.row)">处理</el-link>
            <!--    -->
            <!--   -->
            <el-link v-if="order_status == 2"
                     :disabled="!!scope.row.ref_exist_find_order_status == 1"
                     type="primary"
                     :underline="false"
                     @click="handleSynchronization(scope.row)">同步</el-link>
            <!-- <el-link v-if="scope.row.hard_dot_status == 1" type="primary" :underline="false"
              @click="handleFinished(scope.row)">继续</el-link> -->
            <el-link type="danger"
                     v-if="
                $buttonPermission('taOrder:allocation') &&
                (scope.row.status == 2 || scope.row.status == 5)
              "
                     :underline="false"
                     @click="handleCancel(scope.row)">取消</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 16px">
        <div class="block">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>
    <el-card style="margin-top: 20px"
             v-if="query.is_statistic_company_earn == 1">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
        <el-form size="small"
                 :inline="true">
          <el-form-item label="只看关注"
                        label-width="120">
            <el-switch v-model="query.follow"
                       :active-value="1"
                       :inactive-value="0"
                       @change="fetchList(true)">
            </el-switch>
          </el-form-item>
          <el-form-item label-width="32px">
            <el-switch v-model="query.is_statistic_company_earn"
                       :active-value="1"
                       :inactive-value="0"
                       active-text="数据报表"
                       inactive-text="订单详情"
                       @change="switchChange">
            </el-switch>
          </el-form-item>
          <el-form-item label-width="32px">
            <el-radio-group v-model="order_status"
                            @change="fetchList(true)">
              <el-radio-button label="">全部</el-radio-button>
              <el-radio-button :label="2">匹配中</el-radio-button>
              <el-badge :value="uncheck"
                        class="item"
                        :hidden="uncheck == 0">
                <el-radio-button :label="1">待分配</el-radio-button>
              </el-badge>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="result"
                :header-cell-style="{
          backgroundColor: '#F2F6FC',
          color: '#909399',
          fontSize: '14px',
        }"
                :cell-style="{ fontSize: '13px' }"
                @selection-change="handleSelectionChange"
                border>
        <el-table-column prop="date"
                         label="关注"
                         width="50"
                         fixed="left"
                         align="center">
          <template slot-scope="scope">
            <img alt=""
                 srcset=""
                 class="star"
                 src="@/assets/images/star-fill.png"
                 @click="followDelete(scope.row)"
                 v-show="scope.row.follow" />
            <img src="@/assets/images/star.png"
                 alt=""
                 srcset=""
                 class="star"
                 v-show="!scope.row.follow"
                 @click="followCreate(scope.row)" />
          </template>
        </el-table-column>
        <el-table-column label="沟通记录"
                         fixed="left"
                         align="center"
                         width="100">
          <template slot-scope="scope">
            <div class="badge">
              <el-badge :value="
                  scope.row.record_alt_unreaded_count &&
                  scope.row.record_alt_unreaded_count > 0
                    ? '@'
                    : scope.row.record_msg_unreaded_count
                "
                        class="item"
                        :hidden="
                  !scope.row.record_alt_unreaded_count &&
                  !scope.row.record_msg_unreaded_count
                ">
                <el-link type="primary"
                         :underline="false"
                         @click="checkRecord(scope.row.id)">查看</el-link>
              </el-badge>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="spend_duration"
                         label="时效"
                         align="center"
                         width="120">
          <template slot-scope="scope">
            <div class="spend-wrap">
              <img src="@/assets/images/ji_tag.png"
                   alt=""
                   srcset=""
                   v-if="scope.row.urgency_status == 1" />
              <div v-html="formateDuration(scope.row)"></div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="类别"
                         align="center"
                         width="80">
          <template slot-scope="scope">
            {{ formateType(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="订单状态"
                         align="center"
                         width="80"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="small"
                    v-if="scope.row.status == 1">待分配</el-tag>
            <el-tag size="small"
                    v-if="scope.row.status == 2">匹配中</el-tag>
            <el-tag size="small"
                    v-if="scope.row.status == 3"
                    type="warning">待确认</el-tag>
            <el-tag size="small"
                    v-if="scope.row.status == 4"
                    type="success">已完成</el-tag>
            <el-tag type="danger"
                    size="small"
                    v-if="scope.row.status == 5">返单中</el-tag>
            <el-tag type="info"
                    size="small"
                    v-if="scope.row.status == 6">已取消</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="机构简称"
                         align="center"
                         width="120"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.company ? scope.row.company.name : "无" }}
          </template>
        </el-table-column>
        <el-table-column prop="order_no"
                         label="订单编号"
                         align="center"
                         width="120">
          <template slot-scope="scope">
            {{ scope.row.order_no }}

          </template>
        </el-table-column>

        <el-table-column label="签约单价"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            <!-- -->
            <!-- <div v-if="scope.row.upFlag">
            <el-input style="width: 100px;" min="0" v-model="scope.row.upPrice" placeholder="请输入" type="number" ></el-input>
            <i style="margin-left: 5px;" class="el-icon-finished" @click="upAmount(scope.row)"></i>
            
           </div> -->
            <div>
              {{
                scope.row.earn_amount_statistic_obj.hour_income_company_amount
                  ? scope.row.earn_amount_statistic_obj
                      .hour_income_company_amount + "元"
                  : "-"
              }}
              <!-- {{ scope.row.fix_hour_income_company_amount?scope.row.fix_hour_income_company_amount+'元':scope.row.earn_amount_statistic_obj.hour_income_company_amount ?scope.row.earn_amount_statistic_obj.hour_income_company_amount+'元':'-'}} -->
              <!-- <i class="el-icon-edit-outline " @click="upPrice(scope.row)"></i> -->
            </div>
          </template>
        </el-table-column>
        <el-table-column label="总课时"
                         align="center"
                         width="150"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.earn_amount_statistic_obj &&
              scope.row.earn_amount_statistic_obj.total_company_hour
                ? `${scope.row.earn_amount_statistic_obj.total_company_hour}小时`
                : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column label="总费用"
                         align="center"
                         width="150"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.earn_amount_statistic_obj &&
              scope.row.earn_amount_statistic_obj.total_income_company_amount
                ? `${scope.row.earn_amount_statistic_obj.total_income_company_amount}元`
                : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column label="老师课时"
                         align="center"
                         width="150"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.earn_amount_statistic_obj.arr_total_teacher_hour &&
              scope.row.earn_amount_statistic_obj.arr_total_teacher_hour.length
                ? scope.row.earn_amount_statistic_obj.arr_total_teacher_hour
                    .map((v) => {
                      return `${v}小时`;
                    })
                    .join("/")
                : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column label="老师时薪"
                         align="center"
                         width="150"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.earn_amount_statistic_obj
                .arr_hour_expend_teahcer_amount &&
              scope.row.earn_amount_statistic_obj.arr_hour_expend_teahcer_amount
                .length
                ? scope.row.earn_amount_statistic_obj.arr_hour_expend_teahcer_amount
                    .map((v) => {
                      return `${v}元`;
                    })
                    .join("/")
                : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column label="单位差价"
                         align="center"
                         width="150"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.earn_amount_statistic_obj.arr_hour_earn_amount &&
              scope.row.earn_amount_statistic_obj.arr_hour_earn_amount.length
                ? scope.row.earn_amount_statistic_obj.arr_hour_earn_amount
                    .map((v) => {
                      return `${v}元`;
                    })
                    .join("/")
                : "-"
            }}
          </template>
        </el-table-column>

        <el-table-column label="总差价"
                         align="center"
                         width="150"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.earn_amount_statistic_obj &&
              scope.row.earn_amount_statistic_obj.total_earn_amount
                ? `${scope.row.earn_amount_statistic_obj.total_earn_amount}元`
                : scope.row.earn_amount_statistic_obj.total_earn_amount == 0
                ? "0元"
                : "-"
            }}
          </template>
        </el-table-column>

        <el-table-column label="收入比"
                         align="center"
                         width="150"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.earn_amount_statistic_obj &&
              scope.row.earn_amount_statistic_obj.total_earn_income_rate
                ? `${scope.row.earn_amount_statistic_obj.total_earn_income_rate}%`
                : scope.row.earn_amount_statistic_obj.total_earn_income_rate ==
                  0
                ? `${scope.row.earn_amount_statistic_obj.total_earn_income_rate}%`
                : "-"
            }}
          </template>
        </el-table-column>

        <el-table-column label="匹配师"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{ formateIds(scope.row.allocation_manage_ids) }}
          </template>
        </el-table-column>
        <el-table-column label="学管师"
                         align="center"
                         width="120">
          <template slot-scope="scope">
            {{
              scope.row.learn_manage_user
                ? scope.row.learn_manage_user.nickname
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{
              scope.row.create_time
                ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
                : "无"
            }}
          </template>
        </el-table-column>
        <el-table-column label="更新时间"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{
              scope.row.update_time
                ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         fixed="right"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="handleToDistribution(scope.row)"
                     v-if="$buttonPermission('taOrder:allocation')">分配</el-link>
            <el-link type="primary"
                     :underline="false"
                     @click="handleOrder(scope.row)">处理</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 16px">
        <div class="block">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>

    <Record :teacher_allocation_order_id="orderId"
            :isVisible="recordDialog"
            @close="recordDialog = false"
            @submit="fetchList"
            :type="'order_read'"></Record>
    <Distribution :type="type"
                  :row="row"
                  :id="distributionId"
                  :manage_ids="manage_ids"
                  :second_subject_status="second_subject_status"
                  @close="distributionDialog = false"
                  :isVisible="distributionDialog"
                  @submit="fetchList"></Distribution>
    <AutoOrder @close="autoDialog = false"
               :isVisible="autoDialog"
               @submit="fetchList"></AutoOrder>
    <InstitutionDetails ref="institutionDetails"></InstitutionDetails>
    <ShareUp ref="shareUp"
             @submit="fetchList()" />
    <!-- 查看权重 -->
    <el-dialog top="5vh"
               title="查看"
               :visible.sync="dialogWeightVisible"
               @close="weightToClose"
               @open="openWeight"
               :close-on-click-modal="false"
               width="498px">
      <el-form :model="dataForm"
               label-position="left"
               ref="formRules"
               label-width="100px"
               size="small">

        <el-form-item label="权重类型："
                      prop="weightType">
          <el-select v-model="dataForm.weightType"
                     placeholder="请选择"
                     clearable
                     style="width: 100%"
                     filterable>
            <el-option v-for="(item, index) in weightType "
                       :key="index"
                       :label="item.name"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div v-if="dataForm.weightType==0||dataForm.weightType==5">
        <div style="marginTop:5px;marginBottom:5px">综合权重：</div>
        <el-table :data="weight_obj_all_replace"
                  :header-cell-style="{
          backgroundColor: '#F2F6FC',
          color: '#909399',
          fontSize: '14px',
        }"
                  :cell-style="{ fontSize: '13px' }"
                  border>
          <el-table-column prop="sort"
                           label="优先级"
                           align="center"
                           width="150">
          </el-table-column>
          <el-table-column prop="allocation_manage_nickname"
                           label="匹配师名"
                           align="center"
                           width="155">
          </el-table-column>
          <el-table-column prop="count_in_use"
                           label="当前权重值"
                           align="center"
                           width="150">
          </el-table-column>
        </el-table>
      </div>

      <div v-if="dataForm.weightType==1||dataForm.weightType==5"
           style="marginTop:15px">
        <div style="marginTop:5px;marginBottom:5px">外包权重：</div>
        <el-table :data="weight_obj_out_source"
                  :header-cell-style="{
          backgroundColor: '#F2F6FC',
          color: '#909399',
          fontSize: '14px',
        }"
                  :cell-style="{ fontSize: '13px' }"
                  border>
          <el-table-column prop="sort"
                           label="优先级"
                           align="center"
                           width="150">
          </el-table-column>
          <el-table-column prop="allocation_manage_nickname"
                           label="匹配师名"
                           align="center"
                           width="155">
          </el-table-column>
          <el-table-column prop="count_in_use"
                           label="当前权重值"
                           align="center"
                           width="150">
          </el-table-column>
        </el-table>
      </div>
      <div v-if="dataForm.weightType==2||dataForm.weightType==5">
        <div style="marginTop:20px;marginBottom:5px">代找权重：</div>
        <el-table :data="weight_obj_find_replace"
                  :header-cell-style="{
          backgroundColor: '#F2F6FC',
          color: '#909399',
          fontSize: '14px',
        }"
                  :cell-style="{ fontSize: '13px' }"
                  border>
          <el-table-column prop="sort"
                           label="优先级"
                           align="center"
                           width="150">
          </el-table-column>
          <el-table-column prop="allocation_manage_nickname"
                           label="匹配师名"
                           align="center"
                           width="155">
          </el-table-column>
          <el-table-column prop="count_in_use"
                           label="当前权重值"
                           align="center"
                           width="150">
          </el-table-column>
        </el-table>
      </div>
      <div v-if="dataForm.weightType==3||dataForm.weightType==5">
        <div style="marginTop:20px;marginBottom:5px">代签权重：</div>
        <el-table :data="weight_obj_sign_replace"
                  :header-cell-style="{
          backgroundColor: '#F2F6FC',
          color: '#909399',
          fontSize: '14px',
        }"
                  :cell-style="{ fontSize: '13px' }"
                  border>
          <el-table-column prop="sort"
                           label="优先级"
                           align="center"
                           width="150">
          </el-table-column>
          <el-table-column prop="allocation_manage_nickname"
                           label="匹配师名"
                           align="center"
                           width="155">
          </el-table-column>
          <el-table-column prop="count_in_use"
                           label="当前权重值"
                           align="center"
                           width="150">
          </el-table-column>
        </el-table>
      </div>
      <div v-if="dataForm.weightType==4||dataForm.weightType==5">
        <div style="marginTop:20px;marginBottom:5px">共享派单权重：</div>
        <el-table :data="weight_obj_share_appointer"
                  :header-cell-style="{
          backgroundColor: '#F2F6FC',
          color: '#909399',
          fontSize: '14px',
        }"
                  :cell-style="{ fontSize: '13px' }"
                  border>
          <el-table-column prop="sort"
                           label="优先级"
                           align="center"
                           width="150">
          </el-table-column>
          <el-table-column prop="allocation_manage_nickname"
                           label="匹配师名"
                           align="center"
                           width="155">
          </el-table-column>
          <el-table-column prop="count_in_use"
                           label="当前权重值"
                           align="center"
                           width="150">
          </el-table-column>
        </el-table>
      </div>

    </el-dialog>

  </div>
</template>
  
<script>
import TableTittle from '@/components/TableTittle/TableTittle.vue'
import Record from '@/components/DialogComponents/TeacherResources/Record.vue'
import Distribution from '@/components/DialogComponents/TeacherResources/Distribution.vue'
import AutoOrder from '@/components/DialogComponents/TeacherResources/AutoOrder.vue'
import {
  taList,
  taOrderExport,
  createuserfollow,
  deleteuserfollow,
  teacherAllocationOrder,
  upOrderList,
  cancelOrdereTa,
  sysAllocationWeightValue,
} from '@/request/api'
import InstitutionDetails from '@/components/DialogComponents/InstitutionDetails/index.vue'
import { isArray } from 'mathjs'
import { crmUrl } from '@/utils/helper'
import ShareUp from '@/components/ShareUp/ShareUp.vue'
import Clipboard from 'clipboard'
export default {
  components: {
    TableTittle,
    Record,
    Distribution,
    AutoOrder,
    InstitutionDetails,
    ShareUp,
  },
  data() {
    return {
      clipboardText: '',
      row: {},
      type: '',
      TableName: '订单列表',
      result: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        is_statistic_company_earn: 0,
      },
      pageTotal: 0,
      chooseList: [],
      editDialog: false,
      editId: null,
      recordDialog: false,
      orderId: null,
      distributionId: null,
      distributionDialog: false,
      dialogWeightVisible: false,
      manage_ids: '',
      filters: {
        find_order_statusesList: [
          {
            label: '待接单',
            value: 1,
          },
          {
            label: '进行中',
            value: 2,
          },
          {
            label: '待支付',
            value: 3,
          },
          {
            label: '待确认',
            value: 4,
          },
          {
            label: '已完成',
            value: 5,
          },
          {
            label: '已取消',
            value: 6,
          },
        ],
        statuses: [
          {
            label: '待分配',
            value: 1,
          },
          {
            label: '匹配中',
            value: 2,
          },
          {
            label: '待确认',
            value: 3,
          },
          {
            label: '已完成',
            value: 4,
          },
          {
            label: '返单中',
            value: 5,
          },
          {
            label: '已取消',
            value: 6,
          },
        ],
        types: [
          {
            label: '外包',
            value: 1,
          },
          {
            label: '代找',
            value: 2,
          },
          {
            label: '代签',
            value: 3,
          },
        ],
      },
      create_time: '',
      order_status: '2,5',
      uncheck: 0,
      finishedCheck: 0,
      finishedCheckWait: 0,
      autoDialog: false,
      lock: true,
      second_subject_status: null,
      weight_obj_all_replace: [],
      weight_obj_find_replace: [],
      weight_obj_out_source: [],
      weight_obj_sign_replace: [],
      weight_obj_share_appointer: [],
      dataForm: {
        allocation_manage_ids: null,
        weightType: 5,
      },
      weightType: [
        {
          value: 5,
          name: '全部',
        },
        {
          value: 0,
          name: '综合权重',
        },
        {
          value: 1,
          name: '外包权重',
        },
        {
          value: 2,
          name: '代找权重',
        },
        {
          value: 3,
          name: '代签权重',
        },
        {
          value: 4,
          name: '共享派单权重',
        },
      ],
    }
  },
  created() {
    this.parseQuery()
    this.fetchList()

    this.$store.dispatch('getStaffOptions')
    this.$store.dispatch('getpipeiOptions')
    this.$store.dispatch('getAllColleges')
    this.$store.dispatch('getCompanyList', { data_range_tags: '2,3,4' })
  },
  watch: {
    // 时间数据监听
    create_time(value) {
      if (value) {
        this.query.start_create_time = this.$moment(value[0]).valueOf()
        this.query.end_create_time = this.$moment(value[1]).valueOf()
      } else {
        this.query.start_create_time = ''
        this.query.end_create_time = ''
        this.fetchList(true)
      }
    },
  },
  computed: {},
  methods: {
    handleCopy(row) {
      console.log('row', row)
      this.clipboardText = `${row.company ? row.company.name : ''}
${row.order_no}
${this.formateName(row)}
${row.apply_year}年 | ${row.apply_college_name} | ${
        row.apply_academy_name
      } | (${row.apply_major_code})${
        row.apply_major_name.includes(row.apply_major_code)
          ? row.apply_major_name.split(`(${row.apply_major_code})`)[1]
          : row.apply_major_name
      }${
        row.apply_direction_code && row.apply_direction_name
          ? ` | (${row.apply_direction_code})${row.apply_direction_name}`
          : ''
      }
${this.formateSubjects(row)}
${row.lesson_hours}小时`
      var clipboard = new Clipboard('.copy')
      clipboard.on('success', (e) => {
        this.$message({
          message: '复制成功！',
          type: 'success',
        })
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message({
          message: '该浏览器不支持自动复制',
          type: 'error',
        })
        // 释放内存
        clipboard.destroy()
      })
    },
    handleWeight() {
      this.dialogWeightVisible = true
    },
    weightToClose() {
      this.dialogWeightVisible = false
    },
    openWeight() {
      sysAllocationWeightValue().then((res) => {
        this.weight_obj_find_replace =
          res.data.data.weight_obj_find_replace.sort(function (a, b) {
            return a.count_in_use - b.count_in_use
          })
        this.weight_obj_out_source = res.data.data.weight_obj_out_source.sort(
          function (a, b) {
            return a.count_in_use - b.count_in_use
          }
        )
        this.weight_obj_sign_replace =
          res.data.data.weight_obj_sign_replace.sort(function (a, b) {
            return a.count_in_use - b.count_in_use
          })
        this.weight_obj_share_appointer =
          res.data.data.weight_obj_share_appointer.sort(function (a, b) {
            return a.count_in_use - b.count_in_use
          })
        const allItems = [
          ...this.weight_obj_find_replace,
          ...this.weight_obj_out_source,
          ...this.weight_obj_sign_replace,
          ...this.weight_obj_share_appointer,
        ]

        // 按 name 分组求和
        const result = {}
        allItems.forEach((item) => {
          const allocation_manage_id = item.allocation_manage_id
          const allocation_manage_nickname = item.allocation_manage_nickname
          if (!result[allocation_manage_id]) {
            result[allocation_manage_id] = {
              allocation_manage_id,
              allocation_manage_nickname,
              count_in_use: 0,
            }
          }
          result[allocation_manage_id].count_in_use =
            Math.round(
              (result[allocation_manage_id].count_in_use + item.count_in_use) *
                100
            ) / 100
        })
        this.weight_obj_all_replace = Object.values(result)
        this.weight_obj_all_replace.sort(function (a, b) {
          return a.count_in_use - b.count_in_use
        })
        // 增加排序值
        let allId = 1
        this.weight_obj_all_replace.map((item) => {
          item.sort = allId++
        })
        let findId = 1
        this.weight_obj_find_replace.map((item) => {
          item.sort = findId++
        })
        let outId = 1
        this.weight_obj_out_source.map((item) => {
          item.sort = outId++
        })
        let signId = 1
        this.weight_obj_sign_replace.map((item) => {
          item.sort = signId++
        })
        let shareId = 1
        this.weight_obj_share_appointer.map((item) => {
          item.sort = shareId++
        })
      })
    },
    handleSynchronization(row) {
      this.synchronization(row)
      // this.$confirm("此操作将进行同步操作, 是否继续?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      //   .then(() => {
      //     this.synchronization(row);
      //   })
      //   .catch(() => {
      //     this.$message({
      //       type: "info",
      //       message: "已取消",
      //     });
      //   });
    },
    synchronization(row) {
      let data = {
        apply_year: row.apply_year,
        apply_college_code: row.apply_college_code,
        apply_college_name: row.apply_college_name,
        apply_major_code: row.apply_major_code,
        apply_major_name: row.apply_major_name,
        apply_academy_code: row.apply_academy_code,
        apply_academy_name: row.apply_academy_name,
        apply_subject_codes: row.apply_subject_codes,
        apply_subject_names: row.apply_subject_names,
        lesson_hours: row.lesson_hours,
        demand_direction_codes: row.apply_direction_code,
        demand_direction_names: row.apply_direction_name,
        demand_extra_info: row.allocation_demand,
        extend_reamrk: row.reamrk,
        ref_allocation_order_id: row.id,
      }
      this.$refs.shareUp.open(data)
      // let str = crmUrl();
      // this.$axios({
      //   url: `${str}/api/v1/sharePlatform/findOrder`,
      //   method: "post", //可以省略 不写默认是get
      //   data: data,
      // }).then((res) => {
      //   this.fetchList();
      //   console.log("res:", res);
      // });
    },
    institutionOpen(row) {
      this.$refs.institutionDetails.open(row.apply_college_code)
    },
    handleCancel(row) {
      this.$confirm('是否确定取消订单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          cancelOrdereTa({
            order_id: row.id,
          }).then((res) => {
            if (res.data.code == 0) {
              this.fetchList(true)
              this.$message({
                type: 'success',
                message: '取消成功!',
              })
            }
            console.log('rr', res)
          })
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        })
    },
    upAmount(row) {
      // console.log("111",row)
      upOrderList({
        id: row.id,
        fix_hour_income_company_amount: row.upPrice,
      }).then((res) => {
        this.result.forEach((item) => {
          if (row.id == item.id) {
            this.fetchList(true)
            // item.upFlag = false
          }
        })

        // console.log("111", this.result)
      })
    },
    upPrice(row) {
      this.result.forEach((item) => {
        if (row.id == item.id) {
          if (row.fix_hour_income_company_amount) {
            this.$set(item, 'upPrice', row.fix_hour_income_company_amount)
          } else if (row.earn_amount_statistic_obj.hour_income_company_amount) {
            this.$set(
              item,
              'upPrice',
              row.earn_amount_statistic_obj.hour_income_company_amount
            )
          } else {
            this.$set(item, 'upPrice', 0)
          }
          item.upFlag = true
        }
      })
      // console.log("111",row,this.result)
    },
    handleFinished(row) {
      this.$confirm('订单是否继续匹配？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          teacherAllocationOrder({
            id: row.id,
            hard_dot_status: 0,
          }).then((res) => {
            // console.log(res,'res')
            if (res.data.code == 0) {
              this.fetchList()
              this.$message({
                type: 'success',
                message: '修改成功!',
              })
            }
            //
            //
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.fetchList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.fetchList()
    },
    fetchCheckCount() {
      taList({
        statuses: 1,
        isPaging: 0,
      }).then((response) => {
        let res = response.data
        this.uncheck = res.data.count
      })
    },
    fetchFinishedCount() {
      taList({
        statuses: '2,5',
        hard_dot_statuses: 1,
        hard_dot_types: 1,
        isPaging: 0,
      }).then((response) => {
        let res = response.data
        this.finishedCheck = res.data.count
      })
    },

    fetchFinishedWaitCount() {
      taList({
        statuses: '2,5',
        hard_dot_types: 2,
        hard_dot_statuses: 1,
        isPaging: 0,
      }).then((response) => {
        let res = response.data
        this.finishedCheckWait = res.data.count
      })
    },

    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      const params = Object.assign({}, this.query)

      if (params.types) {
        params.types = params.types.join(',')
      }

      if (params.learn_manage_ids) {
        params.learn_manage_ids = params.learn_manage_ids.join(',')
      }
      if (params.allocation_manage_idses) {
        params.allocation_manage_idses =
          params.allocation_manage_idses.join(',')
      }

      if (this.order_status == 2) {
        params.statuses = '2,5'
        params.hard_dot_types = '1'
        // delete params.statuses
        params.hard_dot_statuses = 1
      } else if (this.order_status == 3) {
        params.statuses = '2,5'
        params.hard_dot_types = '2'
        params.hard_dot_statuses = 1
      } else if (this.order_status == '2,5') {
        params.hard_dot_statuses = 0
        params.statuses = this.order_status
      } else if (this.query.statuses && this.query.statuses.length > 0) {
        params.statuses = params.statuses.join(',')
      } else {
        params.statuses = this.order_status
      }

      if (
        params.ref_find_order_statuses &&
        params.ref_find_order_statuses.length
      ) {
        params.ref_find_order_statuses =
          params.ref_find_order_statuses.join(',')
      }
      taList(params).then((response) => {
        let res = response.data
        this.result = res.data.list || []
        this.pageTotal = res.data.count
        this.fetchCheckCount()
        this.fetchFinishedCount()
        this.fetchFinishedWaitCount()
      })

      this.syncQuery()
    },
    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key]
      })
      if (this.query.types && this.query.types.length) {
        this.query.types = this.query.types.split(',').map((v) => {
          return Number(v)
        })
      }
      if (this.query.statuses && this.query.statuses.length) {
        this.query.statuses = this.query.statuses.split(',').map((v) => {
          return Number(v)
        })
      }
      if (this.query.learn_manage_ids && this.query.learn_manage_ids.length) {
        this.query.learn_manage_ids = this.query.learn_manage_ids
          .split(',')
          .map((v) => {
            return Number(v)
          })
      }
      if (
        this.query.allocation_manage_idses &&
        this.query.allocation_manage_idses.length
      ) {
        this.query.allocation_manage_idses = this.query.allocation_manage_idses
          .split(',')
          .map((v) => {
            return Number(v)
          })
      }
      this.query.follow = Number(this.query.follow)
    },

    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query)
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      )
      if (params.types) {
        params.types = params.types.filter((v) => v).join(',')
      }
      if (params.statuses) {
        params.statuses = params.statuses.filter((v) => v).join(',')
      }
      if (params.learn_manage_ids) {
        params.learn_manage_ids = params.learn_manage_ids
          .filter((v) => v)
          .join(',')
      }
      if (params.allocation_manage_idses) {
        params.allocation_manage_idses = params.allocation_manage_idses
          .filter((v) => v)
          .join(',')
      }
      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        })
    },
    handleSelectionChange(val) {
      this.chooseList = val
      this.show = true
    },
    handleToDetail(row) {
      this.$router.push('/tr/order/handle/99')
    },
    formateType(row) {
      let types = {
        1: '外包',
        2: '代找',
        3: '代签',
      }
      return types[row.type]
    },
    formateSubjects(row) {
      let arr = row.apply_subject_names
        ? row.apply_subject_names.split(',')
        : []
      let code_arr = row.apply_subject_codes
        ? row.apply_subject_codes.split(',')
        : []
      return arr.length
        ? arr
            .map((v, i) => {
              return `(${code_arr[i]})${v}`
            })
            .join('+')
        : '无'
    },
    formateIds(ids) {
      if (ids && this.$store.state.staffOptions.length) {
        let arr = ids.split(',').map((v) => {
          return this.$store.state.staffOptions.find((t) => {
            return t.id == v
          }).nickname
        })
        return arr.join(',')
      } else {
        return '无'
      }
    },
    checkRecord(id) {
      this.orderId = id
      this.recordDialog = true
    },
    handleToDistribution(row) {
      this.row = row
      this.type = row.type
      this.distributionId = row.id
      if (row.apply_subject_codes.includes('999')) {
        this.second_subject_status = 1
      } else {
        this.second_subject_status = 0
      }
      this.manage_ids = row.allocation_manage_ids
      this.distributionDialog = true
    },
    handleOrder(row) {
      if (row.status == 1) {
        this.$message.error('请分配匹配师')
        return
      }
      // number of people

      if (row.type == 1) {
        if (row.grade_students && row.grade_students.length > 0) {
          window.open(
            `/tr/order/handle/${row.id}?name=${
              row.grade ? row.grade.name : ''
            }&&peopleNum=${row.grade_students.length}`
          )
        } else {
          window.open(
            `/tr/order/handle/${row.id}?name=${row.grade ? row.grade.name : ''}`
          )
        }
      } else if (row.type == 3) {
        this.$router.push()
        if (row.grade_students && row.grade_students.length > 0) {
          window.open(
            `/tr/order/handle/${row.id}?name=${
              row.dispatch_order ? row.dispatch_order.student_name : ''
            }&&peopleNum=${row.grade_students.length}`
          )
        } else {
          window.open(
            `/tr/order/handle/${row.id}?name=${
              row.dispatch_order ? row.dispatch_order.student_name : ''
            }`
          )
        }
      } else {
        if (row.grade_students && row.grade_students.length > 0) {
          window.open(
            `/tr/order/handle/${row.id}&&peopleNum=${row.grade_students.length}`
          )
        } else {
          window.open(`/tr/order/handle/${row.id}`)
        }
      }
    },
    formateDuration(row) {
      if (row.spend_duration) {
        return this.formatDuring(row.spend_duration)
      }
    },
    formatDuring(millisecond) {
      var days = parseInt(millisecond / (1000 * 60 * 60 * 24))
      var hours = parseInt(
        (millisecond % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      var minutes = parseInt((millisecond % (1000 * 60 * 60)) / (1000 * 60))
      var seconds = (millisecond % (1000 * 60)) / 1000
      if (days < 7) {
        return `<span style="color:#67C23A;">${
          days + '天' + hours + '小时'
        }</span>`
      } else if (days < 14) {
        return `<span style="color:#E6A23C;">${
          days + '天' + hours + '小时'
        }</span>`
      } else {
        return `<span style="color:#F56C6C;">${
          days + '天' + hours + '小时'
        }</span>`
      }
      /*  return days + "天" + hours + "小时"; */
    },
    formateName(row) {
      let length = 0
      if (row.grade_students && row.grade_students.length > 0) {
        length = row.grade_students.length
      }

      if (row.type == 1) {
        if (length > 0) {
          return row.grade ? row.grade.name + '*' + length + '人' : '无'
        } else {
          return row.grade ? row.grade.name : '无'
        }
      }
      // else if (row.type == 3) {
      //   if(length>0){
      //     return row.dispatch_order ? row.dispatch_order.student_name+'*' +length +'人' : '无'
      //   }else{
      //     return row.dispatch_order ? row.dispatch_order.student_name : '无'
      //   }

      // } row.dispatch_order.order_no
      else if (row.type == 3) {
        if (length > 0) {
          if (row.dispatch_order && row.dispatch_order.student_name) {
            return row.dispatch_order.student_name
          } else {
            return row.dispatch_order
              ? `代签ID ${row.dispatch_order.order_no}*${length}人`
              : '无'
          }
        } else {
          if (row.dispatch_order && row.dispatch_order.student_name) {
            return row.dispatch_order.student_name
          } else {
            return row.dispatch_order
              ? `代签ID ${row.dispatch_order.order_no}`
              : '无'
          }
        }
      } else if (row.type == 2) {
        if (length > 0) {
          return row.match_order
            ? `代找ID ${row.match_order.order_no}*${length}人`
            : '无'
        } else {
          return row.match_order ? `代找ID ${row.match_order.order_no}` : '无'
        }
      }
    },
    openAutoDialog() {
      this.autoDialog = true
    },
    switchChange(e) {
      this.result = []
      this.fetchList()
    },
    handleExport() {
      this.$confirm('是否导出数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const params = Object.assign({}, this.query)
        if (params.types) {
          params.types = params.types.join(',')
        }
        // if (params.statuses) {
        //   params.statuses = params.statuses.join(',')
        // }
        if (params.learn_manage_ids) {
          params.learn_manage_ids = params.learn_manage_ids.join(',')
        }
        if (params.allocation_manage_idses) {
          params.allocation_manage_idses =
            params.allocation_manage_idses.join(',')
        }
        // if (this.query.statuses && this.query.statuses.length) {
        //   params.statuses = params.statuses.join(',')
        // } else {
        //   params.statuses = this.order_status
        // }

        if (this.order_status == 2) {
          params.statuses = '2,5'
          // delete params.statuses
          params.hard_dot_statuses = 1
        } else if (this.order_status == '2,5') {
          params.hard_dot_statuses = 0
          params.statuses = this.order_status
        } else if (this.query.statuses && this.query.statuses.length) {
          params.statuses = params.statuses.join(',')
          // console.log( params.statuses,' params.statuses')
        } else {
          params.statuses = this.order_status
        }

        taOrderExport(params).then((res) => {
          if (res.data.code == 0) {
            this.$message.success('导出成功')
            //创建a标签用于跳转至下载链接
            var str = res.data.data.url.replace(
              /http:/,
              window.location.protocol
            )
            this.$store.commit('downLoad', str)
          }
        })
      })
    },
    // 列表关注创建
    followCreate(item) {
      let flag = false
      if (this.lock) {
        this.lock = false
        let query = {
          type: 9,
          follow_id: item.id,
        }
        createuserfollow(query).then((res) => {
          this.fetchList()
        })
        clearTimeout(flag)
        flag = setTimeout(() => {
          this.lock = true
        }, 500)
      }
    },
    // 列表关注删除
    followDelete(item) {
      let flag = false
      if (this.lock) {
        this.lock = false
        let query = {
          type: 9,
          follow_id: item.id,
        }
        deleteuserfollow(query).then((res) => {
          this.fetchList()
        })
        clearTimeout(flag)
        flag = setTimeout(() => {
          this.lock = true
        }, 300)
      }
    },
  },
}
</script>
  
<style lang="less" scoped>
.copy {
  cursor: pointer;
  margin-top: -5px;
  width: 15px;
  height: 15px;
  vertical-align: middle;
}
/deep/.el-badge__content.is-fixed {
  z-index: 99 !important;
}

.content {
  .badge {
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tag {
  }

  .spend-wrap {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 14px;
      height: 14px;
      margin-right: 8px;
    }
  }

  .star {
    width: 18px;
    height: 18px;
  }
}
</style>
  