<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>财务</el-breadcrumb-item>
        <el-breadcrumb-item>授课课时收集</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card style="margin-top: 20px">
      <el-form class="search-wrap"
               ref="searchForm"
               :inline="true"
               :model="query"
               size="small"
               label-position="left">
        <el-form-item label="用户">
          <el-input v-model.trim="query.user_keyword"
                    placeholder="输入用户姓名/手机号"
                    @input="getDataList(true)"
                    clearable></el-input>
        </el-form-item>

        <el-form-item label="用户open id">
          <el-input v-model.trim="query.wechat_open_id_keyword"
                    placeholder="输入用户open id"
                    @input="getDataList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="最近一次到账课酬金额">
          <el-input v-model.trim="query.remark"
                    placeholder="请输入金额"
                    @input="getDataList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="到账课酬状态">
          <el-select v-model="query.in_pay_amount_check_statuses"
                     placeholder="请选择"
                     @change="getDataList(true)"
                     filterable
                     clearable>
            <el-option v-for="item in filters.instatuses"
                       :key="item.id"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="代发课酬状态">
          <el-select v-model="query.un_pay_amount_check_statuses"
                     placeholder="请选择"
                     @change="getDataList(true)"
                     filterable
                     clearable>
            <el-option v-for="item in filters.outstatuses"
                       :key="item.id"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model.trim="query.remark_1_keyword"
                    placeholder="输入带课机构与学员名称"
                    @input="getDataList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="带课机构与学员">
          <el-input v-model.trim="query.company_student_names_keyword"
                    placeholder="输入带课机构与学员名称"
                    @input="getDataList(true)"
                    clearable></el-input>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card style="margin-top: 20px">
      <div class="title">
        <TableTittle :title="TableName"></TableTittle>
        <el-form label-position="right"
                 :inline="true"
                 size="small">
          <el-form-item style="float: right">
            <el-radio-group v-model="query.remark_2_keyword"
                            @change="getDataList(true)">
              <el-radio-button :label="''">全部</el-radio-button>
              <el-radio-button :label="2">已归档</el-radio-button>
              <el-radio-button :label="1">未归档</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>

      <el-table :data="tableData"
                :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                :cell-style="{ textAlign: 'center', fontSize: '13px' }"
                border>

        <el-table-column type="index"
                         label="序号"
                         width="50">
        </el-table-column>
        <el-table-column prop=""
                         label="用户"
                         width="200"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <div>
              {{ scope.row.uid }}/{{ scope.row.ref_user_nickname? scope.row.ref_user_nickname :'' }}/{{ scope.row.ref_user_mobile  ?scope.row.ref_user_mobile : '' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="wechat_open_id"
                         label="用户open id"
                         width="200"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="teaching_realname"
                         label="姓名"
                         width="120"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="teaching_mobile"
                         label="手机号"
                         width="130"
                         show-overflow-tooltip>
        </el-table-column>

        <el-table-column prop="in_pay_amount_check_status"
                         label="到账课酬状态"
                         width="100">
          <template slot-scope="scope">
            <el-tag type="warning"
                    v-show="scope.row.in_pay_amount_check_status == 1">
              <span>待核实</span>
            </el-tag>
            <el-tag v-show="scope.row.in_pay_amount_check_status == 2"
                    type="success">
              <span>已核实</span>
            </el-tag>
            <el-tag type="danger"
                    v-show="scope.row.in_pay_amount_check_status == 3">
              <span>有错误</span>
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="in_pay_amount_check_status"
                         label="代发课酬状态"
                         width="100">
          <template slot-scope="scope">
            <el-tag type="warning"
                    v-show="scope.row.un_pay_amount_check_status == 1">
              <span>待核实</span>
            </el-tag>
            <el-tag v-show="scope.row.un_pay_amount_check_status == 2"
                    type="success">
              <span>已核实</span>
            </el-tag>
            <el-tag type="danger"
                    v-show="scope.row.un_pay_amount_check_status == 3">
              <span>有错误</span>
            </el-tag>
            <el-tag v-show="scope.row.un_pay_amount_check_status == 4">
              <span>已发放</span>
            </el-tag>
            <el-tag v-show="scope.row.un_pay_amount_check_status == 5">
              <span>部分发放</span>
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="company_student_names"
                         label="带课机构与学员"
                         width="140"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="total_lesson_hour_amounts"
                         label="授课总课时+时薪"
                         width="140"
                         show-overflow-tooltip>
        </el-table-column>

        <el-table-column prop="current_total_in_pay_amount"
                         label="已到账课酬"
                         width="110"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <div>
              ￥{{ scope.row.current_total_in_pay_amount }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="current_in_pay_imgs"
                         label="到账截图"
                         width="100"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <div>
              <el-image style="width: 40px; height: 40px"
                        :src="scope.row.current_in_pay_imgs"
                        :preview-src-list="[scope.row.current_in_pay_imgs]">
              </el-image>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="last_one_time_in_pay_time"
                         label="最近已到账时间"
                         width="140">
          <template slot-scope="scope">
            {{ scope.row.last_one_time_in_pay_time | dateFormat("YYYY-MM-DD HH:mm") }}
          </template>
        </el-table-column>

        <el-table-column prop="remark"
                         label="最近已到账金额"
                         width="130"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <div>
              ￥{{ scope.row.remark }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="history_success_withdraw_pay_count"
                         label="已到账次数"
                         width="100"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <div>
              {{ scope.row.history_success_withdraw_pay_count }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="last_total_un_pay_amount"
                         label="未到账课酬"
                         width="100"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <div>
              ￥{{ scope.row.last_total_un_pay_amount }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="alipay_account_no"
                         label="支付宝账号"
                         width="150"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <div>
              {{ scope.row.alipay_account_no}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="remark_1"
                         label="备注"
                         width="180"
                         show-overflow-tooltip>

        </el-table-column>
        <el-table-column label="创建时间"
                         min-width="160"
                         align="center">
          <template slot-scope="scope">
            <span>{{
                $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
              }}</span>
          </template>
        </el-table-column>
        <el-table-column label="更新时间"
                         min-width="160"
                         align="center">
          <template slot-scope="scope">
            <span>{{
                $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm")
              }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name"
                         label="操作"
                         width="150"
                         fixed="right">
          <template slot-scope="scope">
            <div class="templateLink">
              <el-link type="primary"
                       :underline="false"
                       @click="handleEdit(scope.row)">编辑</el-link> |

              <el-link :underline="false"
                       type="danger"
                       @click="handleDetele(scope.row)">删除</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <div style="margin-top: 20px">
        <div class="block">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>

    <!-- 编辑弹窗 -->
    <el-dialog top="5vh"
               title="编辑"
               :visible.sync="dialogFormVisible"
               :close-on-click-modal="false"
               width="501px"
               @close="emitClose">
      <el-form :model="form"
               label-width="100px"
               label-position="right"
               size="small"
               ref="formRules">

        <el-form-item label="归档状态"
                      prop="remark_2">
          <el-select v-model="form.remark_2"
                     placeholder="请选择"
                     style="width: 350px"
                     filterable>
            <el-option v-for="item in filters.archiveStatus"
                       :key="item.id"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="到账课酬状态"
                      prop="in_pay_amount_check_status">
          <el-select v-model="form.in_pay_amount_check_status"
                     placeholder="请选择"
                     style="width: 350px"
                     filterable>
            <el-option v-for="item in filters.instatuses"
                       :key="item.id"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="代发课酬状态"
                      prop="un_pay_amount_check_status">
          <el-select v-model="form.un_pay_amount_check_status"
                     placeholder="请选择"
                     style="width: 350px">
            <el-option v-for="item in filters.outstatuses"
                       :key="item.id"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="备注">
          <el-input type="textarea"
                    v-model="form.remark1"
                    placeholder="请输入备注"
                    clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="emitClose">取 消</el-button>
        <el-button type="primary"
                   @click="submit('formRules')">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {
  teachingRegistration,
  registrationEdit,
  registrationDetele,
} from '../../../request/api'
import TableTittle from '../../../components/TableTittle/TableTittle.vue'
export default {
  components: {
    TableTittle,
  },
  data() {
    return {
      TableName: '课时收集列表',
      query: {
        pageIndex: 1,
        pageSize: 10,
        remark_2_keyword: 1,
      },
      pageTotal: 0,
      dialogFormVisible: false,
      form: {
        remark1: '',
        remark_2: '',
        un_pay_amount_check_status: null,
        in_pay_amount_check_status: null,
      },
      tableData: [],
      id: null,
      detailRow: {},
      filters: {
        archiveStatus: [
          {
            label: '已归档',
            value: 2,
          },
          {
            label: '未归档',
            value: 1,
          },
        ],
        status: [
          {
            label: '全部',
            value: '',
          },
          {
            label: '已归档',
            value: 2,
          },
          {
            label: '未归档',
            value: 1,
          },
        ],
        instatuses: [
          {
            label: '待核实',
            value: 1,
          },
          {
            label: '已核实',
            value: 2,
          },
          {
            label: '有错误',
            value: 3,
          },
        ],
        outstatuses: [
          {
            label: '待核实',
            value: 1,
          },
          {
            label: '已核实',
            value: 2,
          },
          {
            label: '有错误',
            value: 3,
          },
          {
            label: '已发放',
            value: 4,
          },
          {
            label: '部分发放',
            value: 5,
          },
        ],
      },
    }
  },
  created() {
    this.getDataList(true)
  },
  methods: {
    getDataList(type) {
      if (type) {
        this.query.pageIndex = 1
      }
      this.tableData = []
      const payparams = Object.assign({}, this.query)
      teachingRegistration(payparams).then((res) => {
        console.log('当前✌', res)
        this.pageTotal = res.data.data.count
        res.data.data.list.map((item) => {
          item.current_in_pay_imgs = item.current_in_pay_imgs.split(',')[0]
          console.log(item.current_in_pay_imgs)
        })
        this.tableData = res.data.data.list
      })
    },
    handleEdit(row) {
      this.dialogFormVisible = true
      this.form.un_pay_amount_check_status = row.un_pay_amount_check_status
      this.form.in_pay_amount_check_status = row.in_pay_amount_check_status
      this.form.remark1 = row.remark_1
      this.form.remark_2 = row.remark_2 * 1
      this.id = row.id
    },
    submit() {
      let data = {
        un_pay_amount_check_status: this.form.un_pay_amount_check_status,
        in_pay_amount_check_status: this.form.in_pay_amount_check_status,
        id: this.id,
        remark_1: this.form.remark1,
        remark_2: this.form.remark_2,
      }
      registrationEdit(data).then((res) => {
        if (res.data.code == 0) {
          this.$message.success('操作成功')
          this.dialogFormVisible = false
          this.getDataList()
        }
      })
    },
    //更新当前页
    handleDetele(row) {
      let id = []
      id.push(row.id)
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          console.log('删除')
          registrationDetele({
            ids: id,
          }).then((res) => {
            if (res.data.code == 0) {
              this.getDataList()
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    emitClose() {
      this.dialogFormVisible = false
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.getDataList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.getDataList()
    },
  },
}
</script>

<style lang="less" scoped>
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
</style>