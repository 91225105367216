<template>
  <div>
    <el-dialog top="5vh"
               title="分配订单"
               :visible.sync="dialogFormVisible"
               @close="handleToClose"
               @open="open"
               :close-on-click-modal="false"
               width="657px">
      <el-form :model="dataForm"
               label-position="left"
               ref="formRules"
               :rules="rules"
               label-width="100px"
               size="small">
        <el-form-item label="匹配师："
                      prop="allocation_manage_ids">
          <el-select v-model="dataForm.allocation_manage_ids"
                     placeholder="请选择"
                     clearable
                     style="width: 100%"
                     filterable
                     @change="$forceUpdate()">
            <el-option v-for="(item, index) in $store.state.staffOptions.filter((v) => {
                return (
                  v.depts.findIndex((t) => {
                    return t.id == 5 && v.status == 1;
                  }) != -1
                );
              })"
                       :key="index"
                       :label="item.nickname"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="权重类型："
                      prop="weightType">
          <el-select v-model="dataForm.weightType"
                     placeholder="请选择"
                     clearable
                     style="width: 100%"
                     filterable>
            <el-option v-for="(item, index) in weightType "
                       :key="index"
                       :label="item.name"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div v-if="dataForm.weightType==0">
        <div style="marginTop:5px;marginBottom:5px">综合权重：</div>
        <el-table :data="weight_obj_all_replace"
                  :header-cell-style="{
          backgroundColor: '#F2F6FC',
          color: '#909399',
          fontSize: '14px',
        }"
                  :cell-style="{ fontSize: '13px' }"
                  border>
          <el-table-column prop="sort"
                           label="优先级"
                           align="center"
                           width="150">
          </el-table-column>
          <el-table-column prop="allocation_manage_nickname"
                           label="匹配师名"
                           align="center"
                           width="155">
          </el-table-column>
          <el-table-column prop="count_in_use"
                           label="当前权重值"
                           align="center"
                           width="150">
          </el-table-column>
        </el-table>
      </div>

      <div v-if="dataForm.weightType==1">
        <div style="marginTop:5px;marginBottom:5px">外包权重：</div>
        <el-table :data="weight_obj_out_source"
                  :header-cell-style="{
          backgroundColor: '#F2F6FC',
          color: '#909399',
          fontSize: '14px',
        }"
                  :cell-style="{ fontSize: '13px' }"
                  border>
          <el-table-column prop="sort"
                           label="优先级"
                           align="center"
                           width="150">
          </el-table-column>
          <el-table-column prop="allocation_manage_nickname"
                           label="匹配师名"
                           align="center"
                           width="155">
          </el-table-column>
          <el-table-column prop="count_in_use"
                           label="当前权重值"
                           align="center"
                           width="150">
          </el-table-column>
        </el-table>
      </div>
      <div v-if="dataForm.weightType==2">
        <div style="marginTop:20px;marginBottom:5px">代找权重：</div>
        <el-table :data="weight_obj_find_replace"
                  :header-cell-style="{
          backgroundColor: '#F2F6FC',
          color: '#909399',
          fontSize: '14px',
        }"
                  :cell-style="{ fontSize: '13px' }"
                  border>
          <el-table-column prop="sort"
                           label="优先级"
                           align="center"
                           width="150">
          </el-table-column>
          <el-table-column prop="allocation_manage_nickname"
                           label="匹配师名"
                           align="center"
                           width="155">
          </el-table-column>
          <el-table-column prop="count_in_use"
                           label="当前权重值"
                           align="center"
                           width="150">
          </el-table-column>
        </el-table>
      </div>
      <div v-if="dataForm.weightType==3">
        <div style="marginTop:20px;marginBottom:5px">代签权重：</div>
        <el-table :data="weight_obj_sign_replace"
                  :header-cell-style="{
          backgroundColor: '#F2F6FC',
          color: '#909399',
          fontSize: '14px',
        }"
                  :cell-style="{ fontSize: '13px' }"
                  border>
          <el-table-column prop="sort"
                           label="优先级"
                           align="center"
                           width="150">
          </el-table-column>
          <el-table-column prop="allocation_manage_nickname"
                           label="匹配师名"
                           align="center"
                           width="155">
          </el-table-column>
          <el-table-column prop="count_in_use"
                           label="当前权重值"
                           align="center"
                           width="150">
          </el-table-column>
        </el-table>
      </div>
      <div v-if="dataForm.weightType==4">
        <div style="marginTop:20px;marginBottom:5px">共享派单权重：</div>
        <el-table :data="weight_obj_share_appointer"
                  :header-cell-style="{
          backgroundColor: '#F2F6FC',
          color: '#909399',
          fontSize: '14px',
        }"
                  :cell-style="{ fontSize: '13px' }"
                  border>
          <el-table-column prop="sort"
                           label="优先级"
                           align="center"
                           width="150">
          </el-table-column>
          <el-table-column prop="allocation_manage_nickname"
                           label="匹配师名"
                           align="center"
                           width="155">
          </el-table-column>
          <el-table-column prop="count_in_use"
                           label="当前权重值"
                           align="center"
                           width="150">
          </el-table-column>
        </el-table>
      </div>
      <div class="footer">
        <el-button @click="handleToClose()">取消</el-button>
        <el-button type="primary"
                   @click="submit('formRules')"
                   slot="">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
    
   <script>
import { crmUrl, hostUrl } from '@/utils/helper.js'
import { sysAllocationWeightValue } from '@/request/api'
export default {
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null
      },
    },
    row: {
      type: Object,
      default: () => {
        return {}
      },
    },
    // second_subject_status: {},
    type: {
      type: String,
      default: () => {
        return ''
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      weight_obj_all_replace: [],
      weight_obj_find_replace: [],
      weight_obj_out_source: [],
      weight_obj_sign_replace: [],
      weight_obj_share_appointer: [],
      dataForm: {
        allocation_manage_ids: null,
        weightType: 0,
      },
      matcherId: '',
      weightType: [
        {
          value: 0,
          name: '综合权重',
        },
        {
          value: 1,
          name: '外包权重',
        },
        {
          value: 2,
          name: '代找权重',
        },
        {
          value: 3,
          name: '代签权重',
        },
        {
          value: 4,
          name: '共享派单权重',
        },
      ],

      rules: {
        allocation_manage_ids: [
          { required: true, message: '请选择匹配师', trigger: 'blur' },
        ],
      },
    }
  },

  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val
    },
  },
  methods: {
    open() {
      if (this.type == '师资推荐') {
        this.dataForm.allocation_manage_ids = this.row
          .ref_appointer_manage_user_obj
          ? this.row.ref_appointer_manage_user_obj.id
          : null

        let str = crmUrl()
        sysAllocationWeightValue().then((res) => {
          this.weight_obj_find_replace =
            res.data.data.weight_obj_find_replace.sort(function (a, b) {
              return a.count_in_use - b.count_in_use
            })
          this.weight_obj_out_source = res.data.data.weight_obj_out_source.sort(
            function (a, b) {
              return a.count_in_use - b.count_in_use
            }
          )
          this.weight_obj_sign_replace =
            res.data.data.weight_obj_sign_replace.sort(function (a, b) {
              return a.count_in_use - b.count_in_use
            })
          this.weight_obj_share_appointer =
            res.data.data.weight_obj_share_appointer.sort(function (a, b) {
              return a.count_in_use - b.count_in_use
            })
          const allItems = [
            ...this.weight_obj_find_replace,
            ...this.weight_obj_out_source,
            ...this.weight_obj_sign_replace,
            ...this.weight_obj_share_appointer,
          ]

          // 按 name 分组求和
          const result = {}
          allItems.forEach((item) => {
            const allocation_manage_id = item.allocation_manage_id
            const allocation_manage_nickname = item.allocation_manage_nickname
            if (!result[allocation_manage_id]) {
              result[allocation_manage_id] = {
                allocation_manage_id,
                allocation_manage_nickname,
                count_in_use: 0,
              }
            }
            result[allocation_manage_id].count_in_use =
              Math.round(
                (result[allocation_manage_id].count_in_use +
                  item.count_in_use) *
                  100
              ) / 100
          })
          this.weight_obj_all_replace = Object.values(result)
          this.weight_obj_all_replace.sort(function (a, b) {
            return a.count_in_use - b.count_in_use
          })
          // 增加排序值
          let allId = 1
          this.weight_obj_all_replace.map((item) => {
            item.sort = allId++
          })
          let findId = 1
          this.weight_obj_find_replace.map((item) => {
            item.sort = findId++
          })
          let outId = 1
          this.weight_obj_out_source.map((item) => {
            item.sort = outId++
          })
          let signId = 1
          this.weight_obj_sign_replace.map((item) => {
            item.sort = signId++
          })
          let shareId = 1
          this.weight_obj_share_appointer.map((item) => {
            item.sort = shareId++
          })
        })
      } else {
        this.dataForm.allocation_manage_ids = this.row
          .teacher_allocation_manage_obj
          ? this.row.teacher_allocation_manage_obj.id
          : null

        let str = crmUrl()
        sysAllocationWeightValue().then((res) => {
          this.weight_obj_find_replace =
            res.data.data.weight_obj_find_replace.sort(function (a, b) {
              return a.count_in_use - b.count_in_use
            })
          this.weight_obj_out_source = res.data.data.weight_obj_out_source.sort(
            function (a, b) {
              return a.count_in_use - b.count_in_use
            }
          )
          this.weight_obj_sign_replace =
            res.data.data.weight_obj_sign_replace.sort(function (a, b) {
              return a.count_in_use - b.count_in_use
            })
          this.weight_obj_share_appointer =
            res.data.data.weight_obj_share_appointer.sort(function (a, b) {
              return a.count_in_use - b.count_in_use
            })
          const allItems = [
            ...this.weight_obj_find_replace,
            ...this.weight_obj_out_source,
            ...this.weight_obj_sign_replace,
            ...this.weight_obj_share_appointer,
          ]

          // 按 name 分组求和
          const result = {}
          allItems.forEach((item) => {
            const allocation_manage_id = item.allocation_manage_id
            const allocation_manage_nickname = item.allocation_manage_nickname
            if (!result[allocation_manage_id]) {
              result[allocation_manage_id] = {
                allocation_manage_id,
                allocation_manage_nickname,
                count_in_use: 0,
              }
            }
            result[allocation_manage_id].count_in_use =
              Math.round(
                (result[allocation_manage_id].count_in_use +
                  item.count_in_use) *
                  100
              ) / 100
          })
          this.weight_obj_all_replace = Object.values(result)
          this.weight_obj_all_replace.sort(function (a, b) {
            return a.count_in_use - b.count_in_use
          })
          // 增加排序值
          let allId = 1
          this.weight_obj_all_replace.map((item) => {
            item.sort = allId++
          })
          let findId = 1
          this.weight_obj_find_replace.map((item) => {
            item.sort = findId++
          })
          let outId = 1
          this.weight_obj_out_source.map((item) => {
            item.sort = outId++
          })
          let signId = 1
          this.weight_obj_sign_replace.map((item) => {
            item.sort = signId++
          })
          let shareId = 1
          this.weight_obj_share_appointer.map((item) => {
            item.sort = shareId++
          })
        })
      }
    },
    handleToClose() {
      this.$emit('close')
    },

    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          if (this.type == '师资推荐') {
            let str = crmUrl()
            this.$axios({
              url: `${str}/api/v1/sharePlatform/tool/appointOrder`,
              method: 'post',
              data: {
                find_order_id: this.id,
                appointer_manage_user_id: this.dataForm.allocation_manage_ids,
              },
            }).then((res) => {
              this.$message.success('操作成功')
              this.$emit('submit')
              this.handleToClose()
              this.dataForm.allocation_manage_ids = ''
            })
          } else {
            let str = hostUrl()
            this.$axios({
              url: `${str}/api/v1/teacher/collegeMajorStatistic/updateExtendInfo`,
              method: 'post',
              data: {
                id: this.id,
                teacher_allocation_manage_id:
                  this.dataForm.allocation_manage_ids,
              },
            }).then((res) => {
              this.$message.success('操作成功')
              this.$emit('submit')
              this.handleToClose()
              this.dataForm.allocation_manage_ids = ''
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>
    
    <style lang="less" scoped>
::v-deep .el-table {
  width: 74% !important;
  margin-left: 90px;
}
</style>