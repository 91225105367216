<template>
  <div class="content">
    <!-- <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>
          <div style="font-weight: 800;">学员管理工作台</div>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->

    <el-card style="margin-top: 20px">
      <div class="statistic"
           v-if="statisticList[1]">
        <div class="statusBar">
          <div>待对接</div>
          <div>{{ statisticList[1].count_to_do_all }}</div>
          <div>
            今日已处理<span>{{ statisticList[1].count_has_do_today }}</span>
          </div>
        </div>
        <div class="line"></div>
        <div class="statusBar">
          <div>匹配中</div>
          <div>{{ statisticList[2].count_to_do_all }}</div>
          <div>
            今日已处理<span>{{ statisticList[2].count_has_do_today }}</span>
          </div>
        </div>
        <div class="line"></div>
        <div class="statusBar">
          <div>待确认</div>
          <div>{{ statisticList[3].count_to_do_all }}</div>
          <div>
            今日已处理<span>{{ statisticList[3].count_has_do_today }}</span>
          </div>
        </div>
        <div class="line"></div>
        <div class="statusBar">
          <div>计划中</div>
          <div>{{ statisticList[4].count_to_do_all }}</div>
          <div>
            今日已处理<span>{{ statisticList[4].count_has_do_today }}</span>
          </div>
        </div>
        <div class="line"></div>
        <div class="statusBar">
          <div>待上课</div>
          <div>{{ statisticList[5].count_to_do_all }}</div>
          <div>
            今日已处理<span>{{ statisticList[5].count_has_do_today }}</span>
          </div>
        </div>
        <div class="line"></div>
        <div class="statusBar">
          <div>上课中</div>
          <div>{{ statisticList[6].count_to_do_all }}</div>
          <div>
            今日已处理<span>{{ statisticList[6].count_has_do_today }}</span>
          </div>
        </div>
      </div>
    </el-card>

    <el-card style="margin-top: 20px">
      <el-form class="search-wrap"
               ref="searchForm"
               style="display: flex; flex-wrap: wrap"
               :inline="true"
               :model="queryForm"
               size="small"
               label-position="right"
               label-width="80px">
        <el-form-item label="学员姓名">
          <el-input v-model.trim="queryForm.student_keyword"
                    placeholder="输入姓名"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="学长姓名">
          <el-input v-model.trim="queryForm.teacher_keyword"
                    placeholder="输入姓名"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="匹配师">
          <el-select v-model="queryForm.allocation_manage_ids"
                     placeholder="请选择"
                     clearable
                     multiple
                     collapse-tags>
            <el-option v-for="item in $store.state.staffOptions.filter((v) => {
                return (
                  v.depts.findIndex((t) => {
                    return t.id == 5 && v.status == 1;
                  }) != -1
                );
              })"
                       :key="item.id"
                       :label="item.nickname"
                       :value="item.id">
            </el-option>
          </el-select>
          <!-- <el-input v-model.trim="queryForm.allocation_manage_keyword" placeholder="输入姓名" clearable></el-input> -->
        </el-form-item>
        <el-form-item label="学管师">
          <el-select v-model="queryForm.learn_manage_id"
                     placeholder="请选择"
                     clearable
                     collapse-tags>
            <el-option v-for="item in $store.state.staffOptions.filter((v) => {
                return (
                  v.depts.findIndex((t) => {
                    return t.id == 4 && v.status == 1;
                  }) != -1
                );
              })"
                       :key="item.id"
                       :label="item.nickname"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="阶段状态">
          <el-select v-model="queryForm.task_stages"
                     placeholder="请选择"
                     clearable>
            <el-option v-for="item in taskStageOptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="提前查看">
          <el-date-picker v-model="date_time"
                          type="datetime"
                          style="position: relative"
                          placeholder="选择日期"
                          format="yyyy-MM-dd HH:mm"
                          :picker-options="pickerOption"
                          @change="lookChange"
                          value-format="timestamp">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-select v-model="create_time"
                     placeholder="请选择开始时间"
                     clearable>
            <el-option v-for="(item, index) in timeArr"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
          <!-- <el-date-picker v-model="queryForm.start_do_time" type="datetime" style="position: relative;" placeholder="选择日期"
            format="yyyy-MM-dd HH:mm" value-format="timestamp">
          </el-date-picker> -->
        </el-form-item>
        <el-form-item label="完成时间">
          <el-select v-model="do_time"
                     placeholder="请选择完成时间"
                     clearable>
            <el-option v-for="(item, index) in timeArr"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
          <!-- <el-date-picker v-model="queryForm.end_do_time" type="datetime" style="position: relative;" placeholder="选择日期"
            format="yyyy-MM-dd HH:mm" value-format="timestamp">
          </el-date-picker> -->
        </el-form-item>
        <el-form-item style="margin-left: auto">
          <el-button type="primary"
                     size="small"
                     @click="hanledSearch">搜索</el-button>
          <el-button size="small"
                     @click="hanledReset">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
        <el-form size="small"
                 :inline="true">
          <el-form-item label-width="32px">
            <el-radio-group v-model="queryForm.task_statuses"
                            @input="tabChange">
              <el-radio-button label="1,2,3">全部</el-radio-button>
              <el-radio-button label="2,3">待完成</el-radio-button>
              <el-radio-button label="1">已完成</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>

      <el-table :data="tableData"
                ref="multipleTable"
                v-loading="loading"
                :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                :cell-style="{ fontSize: '13px' }"
                border
                style="width: 100%; margin-top: 20px">
        <el-table-column type="index"
                         label="序号"
                         width="80"
                         align="center">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="date"
                         label="当前阶段"
                         width="80"
                         align="center">
          <template slot-scope="scope">
            <div>
              {{ getTaskStage(scope.row.task_stage) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="task_content"
                         label="提醒事项"
                         width="450"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-html="
                getTaskContent(scope.row.task_content, scope.row.task_type)
              "
                 style="
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              "></div>
          </template>
        </el-table-column>
        <el-table-column prop="date"
                         label="状态"
                         width="100"
                         align="center">
          <template slot-scope="scope">
            <el-tag :type="
                scope.row.task_status == 1
                  ? 'success'
                  : scope.row.task_status == 2
                  ? 'warning'
                  : 'danger'
              ">
              {{
                scope.row.task_status == 1
                  ? "已完成"
                  : scope.row.task_status == 2
                  ? "待完成"
                  : "已超期"
              }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column prop="date"
                         label="关联学员"
                         width="120"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="color-409EFF"
                 @click="handleToSudentDetail(scope.row)"
                 style="
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              ">
              {{ targetStudentName(scope.row.arr_target_student) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name"
                         label="关联老师"
                         width="120"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="color-409EFF"
                 @click="handleToDetail(scope.row)"
                 style="
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              ">
              {{ targetTeacherName(scope.row.arr_target_teacher) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="grade_obj.name"
                         label="班级名称"
                         width="180"
                         align="left"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <div @click="scope.row.grade_obj && handleToClass(scope.row)"
                 class="color-409EFF"
                 style="
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              ">
              {{ scope.row.grade_obj ? scope.row.grade_obj.name : "-" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="arr_target_allocation_manage"
                         label="匹配师"
                         width="120"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              ">
              {{ getMatchmaker(scope.row.arr_target_allocation_manage) }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="learn_manage.nickname"
                         label="学管师"
                         width="180"
                         align="center">
        </el-table-column>
        <el-table-column label="创建时间"
                         width="150"
                         align="center">
          <template slot-scope="scope">
            {{
              scope.row.create_time
                ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="完成时间"
                         width="150 "
                         align="center">
          <template slot-scope="scope">
            {{
              scope.row.update_time
                ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         width="200"
                         align="center"
                         fixed="right">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     v-if="scope.row.task_type == 1 && scope.row.task_status != 1"
                     @click="handleToChangeStatus(scope.row)">
              已对接
            </el-link>
            <el-dropdown trigger="click"
                         v-if="scope.row.task_type == 2 && scope.row.task_status != 1">
              <el-link type="primary"
                       :underline="false"> 分配班级 </el-link>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="a">
                  <span class="dropdown-item"
                        @click="AddStudentClass(scope.row)">新建班级</span>
                </el-dropdown-item>
                <el-dropdown-item command="b">
                  <span class="dropdown-item"
                        @click="joinClass(scope.row)">加入班级</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

            <el-link type="primary"
                     :underline="false"
                     v-if="scope.row.task_type == 3 && scope.row.task_status != 1"
                     @click="handleToMatching(scope.row)">
              提交匹配
            </el-link>
            <el-link type="primary"
                     :underline="false"
                     v-if="scope.row.task_type == 3"
                     @click="handleToClass(scope.row)">
              班级详情
            </el-link>
            <!-- 新增记录 -->
            <el-link type="primary"
                     :underline="false"
                     v-if="
                ((scope.row.task_type >= 4 && scope.row.task_type <= 9) ||
                  (scope.row.task_type >= 13 && scope.row.task_type <= 17) ||
                  (scope.row.task_type >= 22 && scope.row.task_type <= 24) ||
                  scope.row.task_type == 26 ||
                  scope.row.task_type == 28) &&
                scope.row.task_status != 1
              "
                     @click="handleQuestLog(scope.row)">
              新增记录
            </el-link>
            <!-- 匹配进度 -->
            <el-link type="primary"
                     :underline="false"
                     v-if="scope.row.task_type >= 4 && scope.row.task_type <= 11"
                     @click="handleToOrderRecord(scope.row)">
              匹配进度
            </el-link>
            <!-- 确认列表 -->
            <el-link type="primary"
                     :underline="false"
                     v-if="scope.row.task_type == 11"
                     @click="openConfirmList(scope.row)">
              确认列表
            </el-link>
            <!-- 邀请制定 -->
            <el-link type="primary"
                     :underline="false"
                     v-if="scope.row.task_type == 12 || scope.row.task_type == 26">
              <div :data-clipboard-text="text"
                   class="planCopy"
                   @click="planCopy(scope.row)">
                邀请制定
              </div>
            </el-link>
            <!-- 已经发送 -->
            <el-link type="primary"
                     :underline="false"
                     v-if="scope.row.task_type == 12 && scope.row.task_status != 1"
                     @click="
                handleAddLog(
                  scope.row.id,
                  '我已经将计划制定链接发送给老师，等待提交中...'
                )
              ">
              已经发送
            </el-link>

            <!-- 计划详情 -->
            <el-link type="primary"
                     :underline="false"
                     v-if="
                (scope.row.task_type >= 13 && scope.row.task_type <= 18) ||
                scope.row.task_type == 28 ||
                scope.row.task_type == 29
              "
                     @click="planDetail(scope.row)">
              计划详情
            </el-link>
            <!-- 计划审核 -->
            <el-link type="primary"
                     :underline="false"
                     v-if="scope.row.task_type == 27"
                     @click="planDetail(scope.row)">
              计划审核
            </el-link>
            <!-- 提醒话术 -->
            <el-link type="primary"
                     :underline="false"
                     v-if="
                ((scope.row.task_type >= 19 && scope.row.task_type <= 23) ||
                  scope.row.task_type == 30 ||
                  scope.row.task_type == 31) &&
                scope.row.task_status != 1
              ">
              <div :data-clipboard-text="text"
                   class="teacherCopy"
                   ref="teacherCopy"
                   @click="copy(scope.row, 'teacherCopy')">
                提醒话术
              </div>
            </el-link>
            <!-- 已经提醒 -->
            <el-link type="primary"
                     :underline="false"
                     v-if="
                ((scope.row.task_type >= 19 && scope.row.task_type <= 21) ||
                  scope.row.task_type == 30 ||
                  scope.row.task_type == 31) &&
                scope.row.task_status != 1
              "
                     @click="
                handleRemind(
                  scope.row.id,
                  `针对上课时间为${$moment(scope.row.plan_start_time).format(
                    'YYYY-MM-DD HH:mm'
                  )} ,上课主题为${
                    scope.row.course_plan_obj.course_theme
                  }的课程计划，我已经提醒对应的学员与老师`
                )
              ">
              已经提醒
            </el-link>

            <!-- 课时详情 -->
            <el-link type="primary"
                     :underline="false"
                     @click="openPeriod(scope.row)"
                     v-if="24 <= scope.row.task_type && scope.row.task_type <= 25">
              课时详情
            </el-link>

            <el-link type="primary"
                     :underline="false"
                     v-if="queryForm.task_statuses == 1"
                     @click="associatedRecord(scope.row)">
              关联记录
            </el-link>

            <el-link type="danger"
                     :underline="false"
                     v-if="$buttonPermission('overview:close')"
                     @click="handleQuestLog(scope.row,'关闭')">
              关闭
            </el-link>
          </template>
        </el-table-column>
      </el-table>

      <div style="margin-top: 16px">
        <div class="block">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(queryForm.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(queryForm.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>

    <ClassTeacherListIndex :id="ClassTeacherListId"
                           :isVisible="ClassTeacherListDialog"
                           @close="ClassTeacherListDialog = false"
                           @submit="getDataList" />
    <SeniorDetail :isVisible="SeniorDetailDialog"
                  @close="SeniorDetailDialog = false"
                  :id="detailId" />

    <StudentDetail :isVisible="StudentDetailDialog"
                   @close="StudentDetailDialog = false"
                   :id="studentId"></StudentDetail>
    <ClassStudentList :id="ClassStudentListId"
                      :isVisible="ClassStudentListDialog"
                      @close="ClassStudentListDialog = false"
                      @submit="getDataList" />

    <AddStudentClass :student_id="student_id"
                     :student_realname="student_realname"
                     :service_hour="service_hour"
                     :isVisible="createDialog"
                     @close="createDialog = false"
                     @submit="getDataList" />
    <ChooseClass :isVisible="chooseDialog"
                 @close="chooseDialog = false"
                 @submit="getDataList"
                 :student_id="chooseId"
                 :student_realname="student_realname"
                 :service_hour="service_hour" />

    <Matching :id="matchId"
              :isVisible="matchDialog"
              @close="matchDialog = false"
              @submit="getDataList" />

    <Record :teacher_allocation_order_id="orderId"
            :isVisible="recordDialog"
            @close="recordDialog = false"
            @submit="getDataList(true)"
            :grade_id="recordGradeId"
            :type="'grade_read'"></Record>

    <ConfirmList :ids="confirmIds"
                 :isVisible="confirmDialog"
                 :grade_id="grade_id"
                 :order_teachers="order_teachers"
                 :order_id="confirmOrder_id"
                 @close="confirmDialog = false"
                 @submit="getDataList(true)" />

    <QuestLog ref="questLog"
              @submit="getDataList(true)"></QuestLog>

    <ClassTable ref="classTable"></ClassTable>

    <AssociatedRecord ref="associatedRecord"></AssociatedRecord>

  </div>
</template>

<script>
import TableTittle from '@/components/TableTittle/TableTittle.vue'
import SeniorDetail from '@/components/DialogComponents/TeacherResources/SeniorDetail.vue'
import ClassTeacherListIndex from '@/components/DialogComponents/ClassService/ClassTeacherListIndex.vue'
import StudentDetail from '@/components/DialogComponents/TeacherResources/StudentDetail.vue'
import ClassStudentList from '@/components/DialogComponents/ClassService/ClassStudentList.vue'
import AddStudentClass from '@/components/DialogComponents/ClassService/AddStudentClass.vue'
import ChooseClass from '@/components/DialogComponents/ClassService/ChooseClass.vue'
import Matching from '@/components/DialogComponents/TeacherResources/Matching.vue'
import Record from '@/components/DialogComponents/TeacherResources/Record.vue'
import ConfirmList from '@/components/DialogComponents/TeacherResources/ConfirmList.vue'
import QuestLog from '@/components/OverViewTable/QuestLog/QuestLog'
import ClassTable from '@/components/OverViewTable/ClassTable/ClassTable'

import AssociatedRecord from '@/components/OverViewTable/AssociatedRecord/AssociatedRecord'
// import StudentDetail from "@/components/DialogComponents/TeacherResources/StudentDetail.vue";
import {
  learnManageTask,
  statistic,
  editstudent,
  learnManageRecordCreate,
  doTask,
  coursePlanlist,
  handPull,
} from '@/request/api'

import { copyWord } from '../utils/copy'
import Clipboard from 'clipboard'
import { im } from 'mathjs'
export default {
  components: {
    TableTittle,
    SeniorDetail,
    ClassTeacherListIndex,
    StudentDetail,
    ClassStudentList,
    AddStudentClass,
    ChooseClass,
    Matching,
    Record,
    ConfirmList,
    QuestLog,
    ClassTable,
    AssociatedRecord,
  },
  data() {
    return {
      date_time: '',
      create_time: [],
      do_time: [],
      timeArr: [
        {
          label: '全部',
          value: [],
        },
        {
          label: '一天内',
          value: [
            Number(this.$moment().format('x')) - 86400000,
            Number(this.$moment().format('x')),
          ],
        },
        {
          label: '七天内',
          value: [
            Number(this.$moment().format('x')) - 604800000,
            Number(this.$moment().format('x')),
          ],
        },
        {
          label: '十五天内',
          value: [
            Number(this.$moment().format('x')) - 1296000000,
            Number(this.$moment().format('x')),
          ],
        },
        {
          label: '一个月内',
          value: [
            Number(this.$moment().format('x')) - 2592000000,
            Number(this.$moment().format('x')),
          ],
        },
      ],
      pickerOption: {
        disabledDate(time) {
          // console.log('time', time)
          let now = new Date().getTime()
          let sevenAfter = 6 * 24 * 3600 * 1000
          let res = now + sevenAfter

          return time.getTime() < Date.now() - 8.64e7 || time.getTime() > res
        },
      },
      loading: false,
      tableData: [],
      TableName: '待办事项',
      queryForm: {
        pageIndex: 1,
        pageSize: 10,
        task_statuses: '2,3',
        task_stage: '',
        learn_manage_id: [],
        allocation_manage_ids: [],
        end_create_time: '',
      },
      pageTotal: 0,
      statisticList: {},
      taskStageOptions: [
        {
          label: '待对接',
          value: '1',
        },
        {
          label: '匹配中',
          value: '2',
        },
        {
          label: '待确认',
          value: '3',
        },
        {
          label: '计划中',
          value: '4',
        },
        {
          label: '待上课',
          value: '5',
        },
        {
          label: '上课中',
          value: '6',
        },
      ],
      SeniorDetailDialog: false,
      detailId: null,
      ClassTeacherListDialog: false,
      ClassTeacherListId: null,
      // confirmOrder_id: null,
      StudentDetailDialog: false,
      studentId: null,
      ClassStudentListId: null,
      ClassStudentListDialog: false,

      // 分配班级
      student_id: null,
      service_hour: null,
      chooseId: null,
      student_realname: null,
      createDialog: false,
      chooseDialog: false,
      // 提交匹配
      matchId: null,
      matchDialog: false,
      // 沟通记录
      orderId: null,
      recordGradeId: null,
      recordDialog: false,
      // 确认列表
      grade_id: null,
      confirmOrder_id: null,
      confirmDialog: false,
      order_teachers: [],
      confirmIds: '',
      // 话术
      text: null,
      timer: null,

      like: true,
      value1: 4154.564,
      value2: 1314,
      title: '增长人数',
    }
  },
  computed: {
    // userInfo: function () {
    //   return JSON.parse(localStorage.getItem("userInfo"));
    // },
  },

  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
  created() {
    this.getDataList(true)
    this.getStatistic()
    this.timer = setInterval(() => {
      this.getStatistic()
    }, 60000)
  },
  methods: {
    associatedRecord(row) {
      this.$refs.associatedRecord.open(row)
      // this.$alert(row.task_record_content_text, "关联记录", {
      //   confirmButtonText: "确定",
      //   callback: (action) => {

      //   },
      // });
    },

    tabChange() {
      this.queryForm.pageIndex = 1
      this.getDataList()
    },
    lookChange(e) {
      console.log(e)
      if (e) {
        handPull({ hand_pull_date_time: e }).then((res) => {
          // if (!res.data.data) {
          // } else {
          // }
          // console.log("dsdjs", res);
        })
      }
      this.getDataList(true)
    },

    getTaskContent(value, task_type) {
      // console.log("kk",new RegExp("确认报名信息", "g"))
      const style = '<span style="color: #e6a23c;">$&</span>' // $& 表示匹配到的字符串
      let result = ''
      switch (task_type) {
        case 1:
          result =
            value.replace(new RegExp('确认报名信息', 'g'), style) || value
          break
        case 2:
          result =
            value.replace(new RegExp('创建或关联班级', 'g'), style) || value
          break
        case 3:
          result =
            value.replace(new RegExp('班级尽早提交匹配', 'g'), style) || value
          break
        case 4:
          result =
            value.replace(new RegExp('匹配已经超过3天', 'g'), style) || value
          break
        case 5:
          result =
            value.replace(new RegExp('匹配已经超过7天', 'g'), style) || value
          break
        case 6:
          result =
            value.replace(new RegExp('匹配已经超过10天', 'g'), style) || value
          break
        case 7:
          result =
            value.replace(new RegExp('匹配已经超过15天', 'g'), style) || value
          break
        case 8:
          result =
            value.replace(new RegExp('匹配已经超过20天', 'g'), style) || value
          break
        case 9:
          result =
            value.replace(new RegExp('匹配已经超过25天', 'g'), style) || value
          break
        case 10:
          result =
            value.replace(new RegExp('匹配已经严重超期', 'g'), style) || value
          break
        case 11:
          result =
            value.replace(new RegExp('联系老师，确认个人信息', 'g'), style) ||
            value
          break
        case 12:
          result =
            value.replace(new RegExp('给老师发送计划制定链接', 'g'), style) ||
            value
          break
        case 13:
          result =
            value.replace(new RegExp('计划制定已经3天', 'g'), style) || value
          break
        case 14:
          result =
            value.replace(new RegExp('计划制定已经5天', 'g'), style) || value
          break
        case 15:
          result =
            value.replace(new RegExp('计划制定已经7天', 'g'), style) || value
          // console.log("result",result)
          break
        case 16:
          result =
            value.replace(new RegExp('计划制定已经9天', 'g'), style) || value
          break
        case 17:
          result =
            value.replace(new RegExp('计划制定已经10天', 'g'), style) || value
          break
        case 18:
          result =
            value.replace(new RegExp('计划制定已经超过10天', 'g'), style) ||
            value
          break
        case 19:
          result =
            value.replace(new RegExp('明天就是首次开课', 'g'), style) || value
          break
        case 20:
          result =
            value.replace(new RegExp('明天计划有课', 'g'), style) || value
          break
        case 21:
          console.log(
            'cc',
            value.substring(value.indexOf('今'), value.indexOf('课') + 1)
          )
          result =
            value.replace(
              new RegExp(
                `${value.substring(
                  value.indexOf('今'),
                  value.indexOf('课') + 1
                )}`,
                'g'
              ),
              style
            ) || value
          break
        case 22:
          result =
            value.replace(new RegExp('课时已超期未确认', 'g'), style) || value
          break
        case 23:
          result =
            value.replace(new RegExp('课时确认学员还未确认', 'g'), style) ||
            value
          break
        case 24:
          result =
            value.replace(new RegExp('课时确认学员确认有异常', 'g'), style) ||
            value
          break
        case 26:
          if (value.includes('请联系老师')) {
            result =
              value.replace(
                new RegExp(
                  `${value.substring(
                    value.indexOf('计'),
                    value.indexOf('定') + 1
                  )}`,
                  'g'
                ),
                style
              ) || value
          } else {
            result = value.replace(new RegExp('课时确认学员已确认', 'g'), style)
          }
          break
        case 27:
          result =
            value.replace(new RegExp('新增待审核计划', 'g'), style) || value
          break
        default:
          result = value
      }
      // console.log('99',result)
      return result
    },

    recordCreate() {
      // learnManageRecordCreate(data).then((response) => {
      //       let res = response.data
      //       if (res.code == 0) {
      //         this.$message.success('操作成功')
      //         this.$emit('submit')
      //         this.handleToClose()
      //       }
      //     })
    },

    // 已经发送
    handleAddLog(id, text) {
      doTask({
        task_id: id,
        do_log_obj: {
          content_text: text,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.getDataList()
        }
      })
    },
    handleRemind(id, text) {
      this.$confirm('是否确认提醒', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        doTask({
          task_id: id,
          do_log_obj: {
            content_text: text,
          },
        }).then((res) => {
          if (res.data.code == 0) {
            this.getDataList()
          }
        })
      })
    },

    // 班级详情
    handleToClass(row) {
      this.$refs.classTable.open(row.grade_obj.name)
    },

    // 匹配师
    getMatchmaker(list) {
      if (list && list.length > 0) {
        return list.map((item) => item.nickname).join(',')
      } else {
        return '-'
      }
    },
    // 任务状态
    getTaskStage(type) {
      switch (type) {
        case 1:
          return '待对接'
        case 2:
          return '匹配中'
        case 3:
          return '待确认'
        case 4:
          return '计划中'
        case 5:
          return '待上课'
        case 6:
          return '上课中'
      }
    },
    // 搜索
    hanledSearch() {
      this.queryForm.pageIndex = 1
      this.getDataList(true)
    },
    // 获取列表
    getDataList(type) {
      // console.log('学管师', this.queryForm.learn_manage_id.toString())
      if (type) {
        let time = {}
        if (this.create_time && this.create_time.length > 0) {
          time.start_create_time = this.create_time[0]
          time.end_create_time = this.create_time[1]
        }
        if (this.do_time && this.do_time.length > 0) {
          time.start_do_time = this.do_time[0]
          time.end_do_time = this.do_time[1]
        }

        learnManageTask({
          ...this.queryForm,
          ...time,
          learn_manage_id: this.queryForm.learn_manage_id.toString(),
          allocation_manage_ids:
            this.queryForm.allocation_manage_ids.toString(),
        }).then((res) => {
          if (res.data.code == 0) {
            this.pageTotal = res.data.data.count
            this.tableData = res.data.data.list
            this.loading = false
          }
          // console.log("列表", res)
        })
      } else {
        this.loading = true
        learnManageTask(this.queryForm).then((res) => {
          if (res.data.code == 0) {
            this.pageTotal = res.data.data.count
            this.tableData = res.data.data.list
            this.loading = false
          }
          // console.log("列表", res)
        })
        // setTimeout(() => {

        // });
      }
    },

    // 任务记录
    handleQuestLog(row, type) {
      if (type) {
        this.$refs.questLog.open(row.id, type, row.learn_manage.id)
      } else {
        this.$refs.questLog.open(row.id)
      }
    },

    // 已对接
    handleToChangeStatus(row) {
      this.$confirm('确定已对接该学员吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let query = {
          id: row.arr_target_student.map((item) => item.id).toString(),
          docking_status: 1,
        }
        editstudent(query).then((res) => {
          this.getDataList()
        })
      })
    },
    // 分配班级
    AddStudentClass(row) {
      this.student_id = row.arr_target_student[0].id
      this.service_hour = row.service_hours
      this.student_realname = row.realname
      this.createDialog = true
    },
    joinClass(row) {
      // console.log('row',row)
      this.service_hour = row.service_hours
      this.chooseDialog = true
      this.student_realname = row.realname
      this.chooseId = [row.arr_target_student[0].id]
    },

    // 提交匹配
    handleToMatching(row) {
      if (row.str_student_ids) {
        this.matchId = row.grade_obj.id
        this.matchDialog = true
      } else {
        this.$message.error('请选择上课学员')
      }
    },
    // 沟通记录
    handleToOrderRecord(row) {
      // console.log("r",row)
      this.orderId = row.allocation_order_obj.id
      this.recordGradeId = row.id
      this.recordDialog = true
    },
    // 确认列表
    openConfirmList(row) {
      this.grade_id = row.grade_obj.id
      this.confirmOrder_id = row.allocation_order_obj.id
      this.confirmDialog = true
    },

    // 计划详情
    planDetail(row) {
      coursePlanlist({
        teacher_id: row.teacher_id,
        is_all: 1,
        grade_id: row.grade_id, //班级id
      }).then((res) => {
        if (res.data.code == 0) {
          if (res.data.data.list.length <= 0) {
            this.$message({
              type: 'warning',
              message: '暂无计划!',
            })
          } else {
            window.open(
              `/planReview?grade_id=${row.grade_id}&teacher_id=${row.teacher_id}`
            )
          }
        }
      })
    },

    // 确认课时
    openPeriod(row) {
      window.open(
        `/classservice/record/confirm?lesson_log_id=${row.lesson_log_id}`
      )
    },

    planCopy(row) {
      if (location) {
        if (location.host.includes('manage.yanshizi.com')) {
          this.text = `https://lesson.kaoyanbox.com/#/pagesA/curriculumPlanning/index?grade_id=${row.grade_obj.id}`
        } else {
          this.text = `https://lesson.kaoyanbox.com/#/pagesA/curriculumPlanning/index?grade_id=${row.grade_obj.id}`
        }
      }
      copyWord('planCopy')
    },

    // 话术
    copy(row, className) {
      // console.log("pppp")
      if (
        row.task_type == 19 ||
        row.task_type == 20 ||
        row.task_type == 21 ||
        row.task_type == 30 ||
        row.task_type == 31
      ) {
        this.text = `
【课程提醒】

课程主题：${row.course_plan_obj.course_theme}
上课时间:${this.$moment(row.course_plan_obj.plan_start_time).format(
          'YYYY-MM-DD HH:mm'
        )}

这个是最近的课程提醒，请提前做好上课准备！
若计划有任何变动一定要及时跟我联系。`
      } else if (row.task_type == 22) {
        let link = ''
        if (location) {
          if (location.host.includes('manage.yanshizi.com')) {
            link = `https://lesson.kaoyanbox.com/#/pages/creativeIndustry/index?grade_id=${row.grade_obj.id}`
          } else {
            link = `http://localhost:8081/#/pages/creativeIndustry/index?grade_id=${row.grade_obj.id}`
          }
        }
        this.text = `
【课程确认提醒】

课程主题：${row.course_plan_obj.course_theme}
上课时间:${this.$moment(row.course_plan_obj.plan_start_time).format(
          'YYYY-MM-DD HH:mm'
        )}

本次课程计划是否已经按时上课呢？
若已上课，请及时发起确认，以便月底的课时费用结算；

若计划有任何变动一定要及时跟我联系。
课时确认链接
${link}`
      } else if (row.task_type == 23) {
        let link = ''
        if (location) {
          if (location.host.includes('manage.yanshizi.com')) {
            link = `https://lesson.kaoyanbox.com/#/pages/student/confirm?log_id=${
              row.lesson_log_obj.id
            }&&course_plan_id=${
              row.lesson_log_obj.course_plan_id
            }&&linkFlag=${true}`
          } else {
            link = `http://localhost:8081/#/pages/student/confirm?log_id=${
              row.lesson_log_obj.id
            }&&course_plan_id=${
              row.lesson_log_obj.course_plan_id
            }&&linkFlag=${true}`
          }
        }

        this.text = `
【课时确认提醒】        

课程主题：${row.course_plan_obj.course_theme}
上课时长：${row.lesson_log_obj.teacher_real_lesson_hour}小时

老师发起了课时确认，请针对实际上课情况进行确认及评价，有任何问题也可以随时联系我，点击链接即可确认
${link} `
      }

      copyWord(className)
    },

    getStatistic() {
      statistic({}).then((res) => {
        if (res.data.code == 0) {
          this.statisticList = res.data.data.obj_task_stage_statistic
          // console.log("cc",res)
        }
      })
    },

    // 重置
    hanledReset() {
      this.queryForm = {
        pageIndex: 1,
        pageSize: 10,
        task_statuses: '2,3',
        task_stage: '',
        learn_manage_id: [],
      }
      this.getDataList(true)
    },
    // 获取学员名称
    targetStudentName(list) {
      // if (val && val.length) {
      //   return val
      //     .map(
      //       (item) =>
      //         `${item.student_name}${
      //           item.archive_status == 1 && item.terminal_status == 1
      //             ? "(归档且终止)"
      //             : item.archive_status == 1
      //             ? "(归档)"
      //             : item.terminal_status == 1
      //             ? "(终止)"
      //             : ""
      //         }`
      //     )
      //     .join(",");
      // } else {
      //   return "无";
      // }

      if (!list || list.length <= 0) {
        return '-'
      }

      let tag = ''
      if (list[0].archive_status == 1 && list[0].terminal_status == 1) {
        tag = '(归档且终止)'
      } else if (list[0].archive_status == 1) {
        tag = '(归档)'
      } else if (list[0].terminal_status == 1) {
        tag = '(终止)'
      } else {
        tag = ''
      }
      if (list.length > 1) {
        return list[0].realname + '等' + tag
      }
      return list[0].realname + tag
      // return list.map(item => item.realname).join(' ')
    },
    handleToSudentDetail(row) {
      if (row.arr_target_student.length <= 0) {
        return false
      }
      if (row.arr_target_student.length == 1) {
        this.studentId = row.arr_target_student[0].id
        this.StudentDetailDialog = true
      } else {
        this.ClassStudentListId = row.grade_obj.id
        this.ClassStudentListDialog = true
      }
    },

    // 获取老师列表
    targetTeacherName(list) {
      if (!list || list.length <= 0) {
        return '-'
      }
      if (list.length > 1) {
        return list[0].teacher_realname + '等'
      }
      return list[0].teacher_realname
      // return list.map(item => item.teacher_realname).join(' ')
    },
    handleToDetail(row) {
      if (row.arr_target_teacher.length <= 0) {
        return
      }
      if (row.arr_target_teacher.length == 1) {
        this.detailId = row.arr_target_teacher[0].id
        this.SeniorDetailDialog = true
      } else {
        this.ClassTeacherListId = row.arr_target_teacher
          .map((item) => item.id + '')
          .toString()
        this.ClassTeacherListDialog = true
      }
    },

    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.getDataList(true)
    },
    handleCurrentChange(val) {
      this.queryForm.pageIndex = val
      this.getDataList(true)
    },
  },
}
</script>

<style lang="less" scoped>
.content {
}

.statistic {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: PingFang SC-Regular, PingFang SC;

  // line-height: 16px;
  .line {
    width: 1px;
    height: 56px;
    background: #e7e7e7;
  }

  .statusBar {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > div:nth-of-type(1) {
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.9);
    }

    & > div:nth-of-type(2) {
      font-size: 40px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.9);
    }

    & > div:nth-of-type(3) {
      font-size: 12px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.6);

      span {
        margin-left: 5px;
        color: #078d5c;
      }
    }
  }
}

.filtrate {
  display: flex;
  justify-content: space-between;
}

.search-wrap {
  /deep/.el-form-item {
    margin-bottom: 0 !important;
  }

  /deep/.el-input__inner {
    width: 220px !important;
    // margin-top: 5px;
  }

  /deep/.el-form-item {
    margin-bottom: 10px !important;
  }
}

.color-409EFF {
  color: #409eff;
  cursor: pointer;
}
</style>