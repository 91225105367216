<template>
  <div>
    <el-dialog top="5vh"
               title="自动分单"
               :visible.sync="dialogFormVisible"
               @close="handleToClose"
               :close-on-click-modal="false"
               width="600px">
      <el-form :model="dataForm"
               label-position="right"
               ref="formRules"
               :rules="rules"
               size="small"
               label-width="130px">
        <el-form-item label="开关："
                      prop="status">
          <el-switch v-model="dataForm.status"
                     :active-value="1"
                     :inactive-value="0">
          </el-switch>
        </el-form-item>
        <el-form-item label="相同优先："
                      prop="AllocationSameOrderFirstStatus">
          <el-switch v-model="dataForm.AllocationSameOrderFirstStatus"
                     :active-value="1"
                     :inactive-value="0">
          </el-switch>
        </el-form-item>

        <el-form-item label="分单类型：">
          <el-select v-model="dataForm.order_type"
                     placeholder="请选择"
                     clearable>
            <el-option label="综合分单"
                       :value="1">
            </el-option>
            <el-option label="指定分单"
                       :value="2">
            </el-option>
          </el-select>
        </el-form-item>
        <template v-if="dataForm.order_type==1">
          <el-form-item label="匹配师："
                        prop="ids">
            <el-select v-model="dataForm.ids"
                       placeholder="请选择"
                       clearable
                       multiple
                       style="width: 360px;">

              <el-option v-for="item in $store.state.staffOptions.filter(v=>{
                return v.depts.findIndex(t=>{
                  return t.id 
                })!=-1
              })"
                         :key="item.id"
                         :label="item.nickname"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </template>
        <template v-if="dataForm.order_type==2">
          <el-form-item label="外包订单匹配师："
                        prop="outsourse_ids">
            <el-select v-model="dataForm.outsourse_ids"
                       placeholder="请选择"
                       clearable
                       multiple
                       style="width: 360px;">

              <el-option v-for="item in $store.state.staffOptions.filter(v=>{
                return v.depts.findIndex(t=>{
                  return t.id && v.status == 1
                })!=-1
              })"
                         :key="item.id"
                         :label="item.nickname"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="代找订单匹配师："
                        prop="findreplace_ids">
            <el-select v-model="dataForm.findreplace_ids"
                       placeholder="请选择"
                       clearable
                       multiple
                       style="width: 360px;">

              <el-option v-for="item in $store.state.staffOptions.filter(v=>{
                return v.depts.findIndex(t=>{
                  return t.id && v.status == 1
                })!=-1
              })"
                         :key="item.id"
                         :label="item.nickname"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="代签订单匹配师："
                        prop="signreplace_ids">
            <el-select v-model="dataForm.signreplace_ids"
                       placeholder="请选择"
                       clearable
                       multiple
                       style="width: 360px;">

              <el-option v-for="item in $store.state.staffOptions.filter(v=>{
                return v.depts.findIndex(t=>{
                  return t.id && v.status == 1
                })!=-1
              })"
                         :key="item.id"
                         :label="item.nickname"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </template>

      </el-form>
      <div class="footer">
        <el-button @click="handleToClose()">取消</el-button>
        <el-button type="primary"
                   @click="submit('formRules')">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
  <script>
import { configureList, configureEdit, configureListEdit } from '@/request/api'
import {} from '@/utils/helper'
export default {
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {},
      rules: {
        /*       status: [{ required: true, message: "请选择开关", trigger: "blur" }],
              ids: [{ required: true, message: "请选择匹配师", trigger: "blur" }], */
      },
    }
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val
      if (val) {
        this.fetchDetail()
      }
    },
  },
  methods: {
    fetchDetail() {
      configureList().then((response) => {
        let res = response.data
        let status = res.data.list.find((v) => {
          return v.code == 'AllocationAutoOrderStatus'
        })
        let AllocationSameOrderFirstStatus = res.data.list.find((v) => {
          return v.code == 'AllocationSameOrderFirstStatus'
        })
        this.$set(
          this.dataForm,
          'AllocationSameOrderFirstStatus',
          Number(AllocationSameOrderFirstStatus.value)
        )
        this.$set(
          this.dataForm,
          'signreplace_ids_id',
          Number(AllocationSameOrderFirstStatus.id)
        )
        this.$set(this.dataForm, 'status', Number(status.value))
        this.$set(this.dataForm, 'status_id', Number(status.id))
        let ids = res.data.list.find((v) => {
          return v.code == 'AllocationManageIds'
        })
        this.$set(
          this.dataForm,
          'ids',
          ids.value
            ? ids.value.split(',').map((v) => {
                return Number(v)
              })
            : []
        )
        this.$set(this.dataForm, 'ids_id', Number(ids.id))

        let order_type = res.data.list.find((v) => {
          return v.code == 'AllocationAutoOrderType'
        })
        this.$set(this.dataForm, 'order_type', Number(order_type.value))
        this.$set(this.dataForm, 'order_type_id', Number(order_type.id))

        let outsourse_ids = res.data.list.find((v) => {
          return v.code == 'AllocationManageIdsOutSource'
        })
        console.log('outsourse_ids', outsourse_ids)
        this.$set(
          this.dataForm,
          'outsourse_ids',
          outsourse_ids.value
            ? outsourse_ids.value.split(',').map((v) => {
                return Number(v)
              })
            : []
        )
        this.$set(this.dataForm, 'outsourse_ids_id', Number(outsourse_ids.id))
        let findreplace_ids = res.data.list.find((v) => {
          return v.code == 'AllocationManageIdsFindReplace'
        })
        this.$set(
          this.dataForm,
          'findreplace_ids',
          findreplace_ids.value
            ? findreplace_ids.value.split(',').map((v) => {
                return Number(v)
              })
            : []
        )
        this.$set(
          this.dataForm,
          'findreplace_ids_id',
          Number(findreplace_ids.id)
        )

        let signreplace_ids = res.data.list.find((v) => {
          return v.code == 'AllocationManageIdsSignReplace'
        })
        this.$set(
          this.dataForm,
          'signreplace_ids',
          signreplace_ids.value
            ? signreplace_ids.value.split(',').map((v) => {
                return Number(v)
              })
            : []
        )
        this.$set(
          this.dataForm,
          'signreplace_ids_id',
          Number(signreplace_ids.id)
        )
        let SameOrderFirs_ids = res.data.list.find((v) => {
          return v.code == 'AllocationSameOrderFirstStatus'
        })
        this.$set(
          this.dataForm,
          'SameOrderFirs_ids',
          SameOrderFirs_ids.value
            ? SameOrderFirs_ids.value.split(',').map((v) => {
                return Number(v)
              })
            : []
        )
        this.$set(
          this.dataForm,
          'SameOrderFirs_ids_id',
          Number(SameOrderFirs_ids.id)
        )
      })
    },
    handleToClose() {
      this.$emit('close')
      this.dataForm = {}
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let rows = [
            {
              id: this.dataForm.status_id,
              code: 'AllocationAutoOrderStatus',
              value: this.dataForm.status,
            },
            {
              id: this.dataForm.ids_id,
              code: 'AllocationManageIds',
              value: this.dataForm.ids ? this.dataForm.ids.join(',') : '',
            },
            {
              id: this.dataForm.order_type_id,
              code: 'AllocationAutoOrderType',
              value: this.dataForm.order_type,
            },
            {
              id: this.dataForm.outsourse_ids_id,
              code: 'AllocationManageIdsOutSource',
              value: this.dataForm.outsourse_ids
                ? this.dataForm.outsourse_ids.join(',')
                : '',
            },
            {
              id: this.dataForm.findreplace_ids_id,
              code: 'AllocationManageIdsFindReplace',
              value: this.dataForm.findreplace_ids
                ? this.dataForm.findreplace_ids.join(',')
                : '',
            },
            {
              id: this.dataForm.signreplace_ids_id,
              code: 'AllocationManageIdsSignReplace',
              value: this.dataForm.signreplace_ids
                ? this.dataForm.signreplace_ids.join(',')
                : '',
            },
            {
              id: this.dataForm.SameOrderFirs_ids_id,
              code: 'AllocationSameOrderFirstStatus',
              value: this.dataForm.AllocationSameOrderFirstStatus,
            },
          ]
          configureListEdit({ rows }).then((response) => {
            let res = response.data
            if (res.code == 0) {
              this.$message.success('操作成功')
              this.$emit('submit')
              this.handleToClose()
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>
  <style lang="less" scoped>
</style>