<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>课时</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '' }">班级管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
        <el-button icon="el-icon-download"
                   @click="handleExport"
                   size="small">导出</el-button>
        <el-button type="primary"
                   size="small"
                   @click="openCreateDialog"
                   v-if="!this.$route.query.teacher_id">新建</el-button>
      </div>
    </div>
    <el-card style="margin-top: 20px">
      <el-form class="search-wrap"
               ref="searchForm"
               :inline="true"
               :model="query"
               size="small">
        <el-form-item label="机构简称">
          <el-input v-model.trim="query.student_real_company_keyword"
                    placeholder="请输入机构简称"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="学员信息">
          <el-input v-model.trim="query.student_keyword"
                    placeholder="输入学员姓名或手机号"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="报名时间">
          <el-date-picker class="datetime"
                          v-model="create_times"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          @change="fetchList(true)"
                          clearable
                          :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
        </el-form-item>

        <el-form-item style="float: right">
          <el-radio-group v-model="query.archive"
                          @change="fetchList(true)">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button label="1">已归档</el-radio-button>
            <el-radio-button label="0">未归档</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <deviceLine style="margin-bottom: 16px"></deviceLine>

        <el-form-item label="班级名称">
          <el-input v-model.trim="query.name_keyword"
                    placeholder="请输入班级名称或ID"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="班级类型">
          <el-select v-model="query.types"
                     placeholder="请选择"
                     @change="fetchList(true)"
                     clearable
                     multiple
                     collapse-tags>
            <el-option v-for="(item, index) in filters.types"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <!--  <el-form-item label="班级状态">
          <el-select
            v-model="query.statuses"
            placeholder="请选择"
            @change="fetchList(true)"
            clearable
            multiple
            collapse-tags
          >
            <el-option
              v-for="(item,index) in filters.status"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="班级状态">
          <el-select v-model="query.grade_sync_statuses"
                     placeholder="请选择"
                     @change="fetchList(true)"
                     clearable
                     multiple
                     collapse-tags>
            <el-option v-for="(item, index) in filters.grade_sync_statuses"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="老师信息">
          <el-input v-model.trim="query.teacher_keyword"
                    placeholder="输入老师姓名或手机号"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="考研年份">
          <el-select v-model="query.apply_years"
                     placeholder="请选择"
                     @change="fetchList(true)"
                     clearable>
            <el-option v-for="(item, index) in filters.apply_years"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="报考信息">
          <el-input v-model.trim="query.college_info_keyword"
                    placeholder="输入报考院校/学院/专业/方向"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>

        <el-form-item label="辅导科目">
          <el-input v-model.trim="query.subject_keyword"
                    placeholder="输入辅导科目"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>

        <el-form-item label="跟进标签"
                      prop="watcher_id">
          <el-select @change="fetchList(true)"
                     v-model="query.follow_up_tag_idses"
                     placeholder="请选择"
                     multiple
                     filterable
                     clearable>
            <el-option v-for="(item, index) in followUpTag"
                       :key="index"
                       :label="item.tag"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="跟进备注">
          <el-input v-model.trim="query.follow_up_remark_keyword"
                    placeholder="输入跟进备注"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>

        <!-- <el-form-item label="院校">
          <el-input
            v-model.trim="query.apply_college_keyword"
            placeholder="请输入院校"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="专业">
          <el-input
            v-model.trim="query.apply_major_keyword"
            placeholder="请输入专业"
            @input="fetchList(true)"
            clearable
          ></el-input>
        </el-form-item> -->

        <el-form-item label="学管记录">
          <el-select v-model="query.last_manage"
                     placeholder="请选择订单状态"
                     @change="fetchList(true)"
                     clearable
                     collapse-tags>
            <el-option v-for="item in timestampList"
                       :key="item.id"
                       :label="item.label"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学管老师">
          <el-select v-model="query.learn_managers"
                     placeholder="请选择"
                     @change="fetchList(true)"
                     clearable
                     multiple
                     collapse-tags>
            <el-option v-for="item in $store.state.staffOptions.filter((v) => {
                return (
                  v.depts.findIndex((t) => {
                    return t.id == 4 && v.status == 1;
                  }) != -1
                );
              })"
                       :key="item.id"
                       :label="item.nickname"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="归档状态">
          <el-select
            v-model="query.archive"
            placeholder="请选择"
            @change="fetchList(true)"
            clearable
            collapse-tags
          >
            <el-option
              v-for="(item, index) in filters.archives"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <!-- query.archive -->
        <!-- <el-form-item label="报名时间">
          <el-date-picker
            class="datetime"
            v-model="create_time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="fetchList(true)"
            clearable
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item> -->

        <!--  <el-form-item label="待确认师资">
          <el-select
            v-model="query.is_has_allocation_confirm "
            placeholder="请选择"
            @change="fetchList(true)"
            clearable
          >
            <el-option
              label="是"
              :value="1"
            >
            </el-option>
            <el-option
              label="否"
              :value="0"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px"
             class="card">
      <!-- <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
        <el-form
          label-width="70px"
          size="small"
          style="margin-bottom: 0px; display: flex"
          label-position="right"
        >
          <el-form-item label-width="32px">
            <el-radio-group v-model="query.archive" @change="fetchList(true)">
              <el-radio-button label="">全部</el-radio-button>
              <el-radio-button label="1">已归档</el-radio-button>
              <el-radio-button label="0">未归档</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label-width="32px">
            <el-badge :value="uncheck" class="item" :hidden="uncheck == 0">
              <el-checkbox
                v-model="query.is_has_allocation_confirm"
                label="待确认师资"
                border
                :true-label="1"
                :false-label="0"
                @change="fetchList(true)"
              ></el-checkbox>
            </el-badge>
          </el-form-item>
        </el-form>
      </div> -->

      <div class="tabulation">
        <div class="radio-group">
          <el-radio-group v-model="filtrate"
                          @change="filtrateChange"
                          size="small">
            <el-radio-button label="1">全部</el-radio-button>
            <el-radio-button label="2">仅关注</el-radio-button>
            <el-badge :value="uncheck"
                      :hidden="uncheck == 0">
              <el-radio-button label="3">待确认</el-radio-button>
            </el-badge>
          </el-radio-group>
        </div>
        <el-tabs v-model="activeName"
                 @tab-click="handleClick">
          <el-tab-pane label="订单列表"
                       name="first">
            <el-table :data="tableData"
                      :header-cell-style="{
                color: '#909399',
                fontFamily: 'PingFang SC',
                fontSize: '14px',
                fontWeight: '400',
                backgroundColor: '#F2F6FC',
              }"
                      :cell-style="{ fontSize: '13px' }"
                      @selection-change="handleSelectionChange"
                      border>
              <el-table-column type="selection"
                               width="50"
                               align="center">
              </el-table-column>
              <!-- <el-table-column
                prop="date"
                label="关注"
                width="50"
                fixed="left"
                align="center"
              >
                <template slot-scope="scope">
                  <img
                    alt=""
                    srcset=""
                    class="star"
                    src="@/assets/images/star-fill.png"
                    @click="followDelete(scope.row)"
                    v-show="scope.row.follow"
                  />
                  <img
                    src="@/assets/images/star.png"
                    alt=""
                    srcset=""
                    class="star"
                    v-show="!scope.row.follow"
                    @click="followCreate(scope.row)"
                  />
                </template>
              </el-table-column> -->

              <el-table-column fixed="left"
                               label="学习记录"
                               align="center"
                               width="120">
                <template slot-scope="scope">
                  <el-link type="primary"
                           :underline="false"
                           @click="handleToRecord(scope.row)">课时</el-link>
                  <el-link type="primary"
                           :underline="false"
                           @click="handleToPlanCalendar(scope.row)">计划</el-link>
                </template>
              </el-table-column>
              <!-- <el-table-column
          prop="id"
          label="ID"
          width="80"
          align="center"
          show-overflow-tooltip
        > 
        </el-table-column>-->

              <el-table-column prop="name"
                               label="班级名称"
                               width="230"
                               align="left"
                               show-overflow-tooltip
                               fixed="left">
                <template slot-scope="scope">
                  ID{{ scope.row.id }} - {{ scope.row.name }}
                </template>
              </el-table-column>

              <el-table-column label="班级状态"
                               width="80"
                               align="center"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.grade_sync_status == 1"
                          size="mini"
                          type="warning">待匹配</el-tag>
                  <el-tag v-if="scope.row.grade_sync_status == 2"
                          size="mini">匹配中</el-tag>
                  <el-tag v-if="scope.row.grade_sync_status == 3"
                          size="mini"
                          type="warning">待确认</el-tag>
                  <el-tag v-if="scope.row.grade_sync_status == 4"
                          size="mini"
                          type="warning">待上课</el-tag>
                  <el-tag v-if="scope.row.grade_sync_status == 5"
                          size="mini">上课中</el-tag>
                  <el-tag v-if="scope.row.grade_sync_status == 6"
                          size="mini"
                          type="danger">已过半</el-tag>
                  <el-tag v-if="scope.row.grade_sync_status == 7"
                          size="mini"
                          type="info">已终止</el-tag>
                  <el-tag v-if="scope.row.grade_sync_status == 8"
                          size="mini"
                          type="danger">已结束</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="学员列表"
                               width="120"
                               align="center"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-link type="primary"
                           :underline="false"
                           @click="ChooseStudent(scope.row)">
                    {{ formateMember(scope.row.active_students) }}
                  </el-link>
                </template>
              </el-table-column>
              <el-table-column label="授课老师"
                               width="120"
                               align="center"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="badge"
                       style="
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    ">
                    <div v-if="
                        scope.row.grade_sync_status != 1 &&
                        scope.row.grade_sync_status != 2 &&
                        scope.row.grade_sync_status != 3
                      ">
                      <span v-if="scope.row.teacher_realnames">
                        <el-link type="primary"
                                 :underline="false"
                                 @click="ChooseTeacher(scope.row)">
                          {{ getTeachers(scope.row.teachers) }}
                        </el-link>
                      </span>

                      <span v-else-if="scope.row.teacher_realnames_end">
                        <el-link type="info"
                                 :underline="false"
                                 @click="ChooseTeacher(scope.row)">
                          {{ getTeachers(scope.row.teachers_end) }}
                        </el-link>
                      </span>
                      <el-link v-else
                               type="success"
                               :underline="false"
                               @click="openConfirmList(scope.row)">确认列表</el-link>
                      <!-- <span v-else> - </span> -->
                    </div>

                    <el-link v-if="
                        scope.row.grade_sync_status == 1 &&
                        !scope.row.allocation_order_id
                      "
                             type="warning"
                             :underline="false"
                             @click="handleToMatching(scope.row)">提交匹配</el-link>

                    <el-badge v-if="
                        scope.row.grade_sync_status == 2 ||
                        scope.row.grade_sync_status == 3 ||
                        (scope.row.grade_sync_status == 1 &&
                          scope.row.allocation_order_id)
                      "
                              :value="
                        scope.row.allocation_order_teacher_unconfirm_count
                      "
                              class="item"
                              :hidden="
                        !scope.row.allocation_order_teacher_unconfirm_count
                      ">
                      <el-link type="success"
                               :underline="false"
                               @click="openConfirmList(scope.row)">确认列表</el-link>
                    </el-badge>
                  </div>

                  <!-- <div
              class="badge"
              v-if="scope.row.allocation_order_obj"
            >
              <div v-if="scope.row.allocation_order_obj.status==4">
                <el-link
                  type="primary"
                  :underline="false"
                  @click="ChooseTeacher(scope.row)"
                >
                {{formateMember(scope.row.teacher_realnames)}}
                 {{getTeacherName(scope.row.teachers)}}
                </el-link>
              </div>
              <div v-else>
                <el-badge
                  :value="scope.row.allocation_order_teacher_unconfirm_count"
                  class="item"
                  :hidden="!scope.row.allocation_order_teacher_unconfirm_count"
                >
                  <el-link
                    type="primary"
                    :underline="false"
                    @click="openConfirmList(scope.row)"
                  >确认列表</el-link>
                </el-badge>
                <el-badge
                  :value="scope.row.record_msg_unreaded_count"
                  class="item"
                  :hidden="!scope.row.record_msg_unreaded_count"
                >
                  <el-link
                    type="warning"
                    :underline="false"
                    @click="handleToOrderRecord(scope.row)"
                  >沟通记录</el-link>
                </el-badge>
              </div>
            </div>
            <div
              class="badge"
              v-else
            >
              <el-link
                type="success"
                :underline="false"
                @click="ChooseTeacher(scope.row)"
              >老师列表</el-link>
              <el-link
                type="primary"
                :underline="false"
                @click="ChooseTeacher(scope.row)"
                v-if="scope.row.teacher_realnames"
              >{{getTeacherName(scope.row.teachers)}}</el-link>
              <el-link
                type="warning"
                :underline="false"
                @click="handleToMatching(scope.row)"
                v-else
              >提交匹配</el-link> 
            </div> -->
                </template>
              </el-table-column>
              <el-table-column width="120"
                               show-overflow-tooltip
                               align="center"
                               label="计划进度">
                <template slot-scope="scope">
                  <el-progress :text-inside="true"
                               :stroke-width="20"
                               :percentage="planProgress(scope.row)"></el-progress>
                </template>
              </el-table-column>
              <el-table-column width="120"
                               show-overflow-tooltip
                               align="center">
                <template slot="header">
                  <el-popover ref="popover">
                    <span class="el-dropdown-link"
                          slot="reference">
                      课程进度<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <div class="popover-item"
                         @click="handleCommandOther(1)">
                      进度
                    </div>
                    <div class="popover-item"
                         @click="handleCommandOther(2)">
                      小时
                    </div>
                  </el-popover>
                </template>
                <template slot-scope="scope">
                  <div v-if="unitConversion2 == 1">
                    <el-progress :text-inside="true"
                                 :stroke-width="20"
                                 :percentage="
                        scope.row.course_progress
                          ? parseFloat(
                              (Number(scope.row.course_progress) * 100).toFixed(
                                2
                              )
                            )
                          : 0
                      "></el-progress>
                  </div>
                  <div v-if="unitConversion2 == 2">
                    {{
                      scope.row.sum_grade_lesson_hour
                        ? scope.row.sum_grade_lesson_hour
                        : 0
                    }}小时
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="报考信息"
                               width="400"
                               show-overflow-tooltip
                               align="left">
                <template slot-scope="scope">
                  <span>
                    {{
                      scope.row.apply_year ? scope.row.apply_year + "年" : ""
                    }} </span>/
                  <span class="pointer"
                        @click="institutionOpen(scope.row)">
                    {{ scope.row.apply_college_name }}</span>
                  / ({{ scope.row.apply_major_code }}){{
                    scope.row.apply_major_name
                  }}
                  / {{ scope.row.apply_academy_name }}/

                  <span v-if="scope.row.apply_direction_name">
                    {{
                      scope.row.apply_direction_code
                        ? `(${scope.row.apply_direction_code})${scope.row.apply_direction_name}`
                        : scope.row.apply_direction_name
                    }}
                  </span>
                  <!-- |
                  <span
                    v-for="(item, index) in scope.row.apply_subjects || []"
                    :key="index"
                  >
                    ({{ item.subject_code }}){{ item.subject_name }}
                  </span> -->
                  <!-- <span
                    v-if="
                      scope.row.apply_direction_code &&
                      scope.row.apply_direction_name
                    "
                  >
                    | ({{ scope.row.apply_direction_code }}){{
                      scope.row.apply_direction_name
                    }}
                  </span> -->
                </template>
              </el-table-column>
              <el-table-column label="辅导科目"
                               width="160"
                               align="left"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-for="(item, index) in scope.row.apply_subjects || []"
                        :key="index">
                    ({{ item.subject_code }}){{ item.subject_name }}
                  </span>
                </template>
              </el-table-column>

              <el-table-column width="120"
                               label="报名课时"
                               show-overflow-tooltip
                               align="center">
                <template slot-scope="scope">
                  {{
                    scope.row.cocah_lesson_hour
                      ? scope.row.cocah_lesson_hour
                      : 0
                  }}小时
                </template>
                <!-- <template slot="header">
                  <el-popover ref="popover">
                    <span class="el-dropdown-link" slot="reference">
                      报名课时<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <div class="popover-item" @click="handleCommand(1)">
                      进度
                    </div>
                    <div class="popover-item" @click="handleCommand(2)">
                      小时
                    </div>
                  </el-popover>
                </template>
                <template slot-scope="scope">
                  <div v-if="unitConversion == 1">
                    <el-progress
                      :text-inside="true"
                      :stroke-width="20"
                      :percentage="formateProgress(scope.row)"
                    ></el-progress>
                  </div>
                  <div v-if="unitConversion == 2">
                    {{
                      scope.row.grade_plan_data &&
                      scope.row.grade_plan_data.plan_exist_lesson_hour
                        ? scope.row.grade_plan_data.plan_exist_lesson_hour
                        : 0
                    }}小时
                  </div>
                </template> -->
              </el-table-column>
              <el-table-column label="课程备注"
                               width="200"
                               align="left"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ scope.row.remark ? scope.row.remark : "无" }}
                </template>
              </el-table-column>

              <el-table-column label="复习基础"
                               width="240"
                               align="left"
                               prop="coach_need"
                               show-overflow-tooltip>
              </el-table-column>
              <el-table-column label="参考书目"
                               width="240"
                               align="left"
                               prop="refer_book"
                               show-overflow-tooltip>
              </el-table-column>

              <el-table-column label="跟进标签"
                               align="center"
                               width="200">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.follow_up_tag_ids"
                             multiple
                             filterable
                             allow-create
                             default-first-option
                             placeholder="请选择跟进标签"
                             @change="
                      (...event) => {
                        tagsChange(event, scope.row);
                      }
                    ">
                    <el-option v-for="item in followUpTag"
                               :key="item.id"
                               :label="item.tag"
                               :value="item.id">
                    </el-option>
                  </el-select>

                  <!-- <el-select
                    v-model="scope.row.manage_tags_arr"
                    multiple
                    placeholder="请选择"
                    allow-create
                    default-first-option
                    @change="
                      (...event) => {
                        tagsChange(event, scope.row);
                      }
                    "
                    size="small"
                  >
                    <el-option
                      v-for="item in tagOptions"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select> -->
                </template>
              </el-table-column>

              <el-table-column label="机构简称"
                               width="120"
                               show-overflow-tooltip
                               prop="student_real_company_names"
                               align="center">
              </el-table-column>
              <el-table-column label="学管老师"
                               align="center"
                               width="140">
                <template slot-scope="scope">
                  {{
                    scope.row.learn_manager
                      ? scope.row.learn_manager.nickname
                      : ""
                  }}
                </template>
              </el-table-column>
              <el-table-column label="最近一条学管记录创建时间"
                               width="200"
                               align="center">
                <template slot-scope="scope">
                  {{
                    scope.row.last_manage_record_create_time
                      ? $moment(
                          scope.row.last_manage_record_create_time
                        ).format("YYYY-MM-DD HH:mm")
                      : ""
                  }}
                </template>
              </el-table-column>
              <el-table-column label="创建时间"
                               align="center"
                               width="150">
                <template slot-scope="scope">
                  {{
                    scope.row.create_time
                      ? $moment(scope.row.create_time).format(
                          "YYYY-MM-DD HH:mm"
                        )
                      : ""
                  }}
                </template>
              </el-table-column>
              <el-table-column label="更新时间"
                               align="center"
                               width="150">
                <template slot-scope="scope">
                  {{
                    scope.row.update_time
                      ? $moment(scope.row.update_time).format(
                          "YYYY-MM-DD HH:mm"
                        )
                      : ""
                  }}
                </template>
              </el-table-column>

              <el-table-column label="跟进备注"
                               align="left"
                               width="260"
                               fixed="right"
                               prop=""
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-input @input="followUpRemark($event, scope.row)"
                            v-model="scope.row.follow_up_remark"
                            placeholder="请输入跟进备注"></el-input>
                </template>
              </el-table-column>

              <el-table-column fixed="right"
                               label="操作"
                               align="center"
                               width="150">
                <template slot-scope="scope">
                  <el-link type="primary"
                           :underline="false"
                           @click="handleToGradeRecord(scope.row)">记录</el-link>
                  <el-link type="primary"
                           :underline="false"
                           @click="handleToLogDetail(scope.row)">查看</el-link>
                  <el-link type="danger"
                           :underline="false"
                           @click="handleToEdit(scope.row)"
                           v-if="scope.row.status != 2">变更</el-link>
                  <el-link type="success"
                           :underline="false"
                           @click="changeStatus(scope.row)"
                           v-if="scope.row.grade_sync_status==7">恢复</el-link>

                  <!--    <el-link
              type="primary"
              :underline="false"
              v-if="scope.row.old_id"
              disabled
            >已同步</el-link>
            <el-link
              type="primary"
              :underline="false"
              @click="handleToSync(scope.row)"
              v-else
            >同步</el-link> -->
                  <!--     <el-link
              type="danger"
              :underline="false"
              @click="handleToRemove(scope.row.id)"
            >删除</el-link> -->
                </template>
              </el-table-column>

              <!-- <el-table-column
          prop="type"
          label="班级类型"
          align="center"
          width="120"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ formateType(scope.row.type) }}
          </template>
        </el-table-column>
        <el-table-column
          label="考研年份"
          width="80"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.apply_year ? scope.row.apply_year + "年" : "暂无" }}
          </template>
        </el-table-column>
       
       

       
        
       
        <el-table-column
          label="匹配周期"
          width="120"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.grade_plan_data.match_period_day }}天
          </template>
        </el-table-column> -->

              <!--         <el-table-column
          width="120"
          show-overflow-tooltip
          label="辅导时长"
        >
          <template slot-scope="scope">
            {{scope.row.cocah_lesson_hour}}小时
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          show-overflow-tooltip
          label="当前已计划课时"
        >
          <template slot-scope="scope">
            {{scope.row.grade_plan_data&&scope.row.grade_plan_data.plan_exist_lesson_hour?scope.row.grade_plan_data.plan_exist_lesson_hour:0}}小时
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          show-overflow-tooltip
          label="当前以上课时"
        >
          <template slot-scope="scope">
            {{scope.row.grade_plan_data&&scope.row.grade_plan_data.sum_grade_lesson_hour?scope.row.grade_plan_data.sum_grade_lesson_hour:0}}小时
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          show-overflow-tooltip
          label="总课时"
        >
          <template slot-scope="scope">
            {{scope.row.grade_plan_data&&scope.row.grade_plan_data.plan_total_lesson_hour?scope.row.grade_plan_data.plan_total_lesson_hour:0}}小时
          </template>
        </el-table-column> -->
              <!--    <el-table-column
          label="课程资料"
          align="center"
          width="180"
        >
          <template>
            <el-link
              type="primary"
              :underline="false"
            >查看资料</el-link>
          </template>
        </el-table-column> -->
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="数据列表"
                       name="second"> 数据列表 </el-tab-pane>
        </el-tabs>
      </div>

      <div class="operation">
        <div>
          <el-button size="mini"
                     type="primary"
                     @click="chooseAllEdit(1, '您确定要归档吗')"
                     v-show="query.archive == 0 && query.archive != ''">归档</el-button>
          <el-button size="mini"
                     type="primary"
                     @click="chooseAllEdit(0, '您确定要取消归档吗')"
                     v-show="query.archive == 1">取消归档</el-button>
          <el-button size="mini"
                     type="danger"
                     @click="chooseAllEditStatus()">终止</el-button>
          <el-button size="mini"
                     type="danger"
                     @click="handleToDeleteAll()">删除</el-button>
        </div>

        <div class="block"
             style="margin-bottom: 0px">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>
    <AddClass :isVisible="createDialog"
              @close="createDialog = false"
              @submit="fetchList(true)"
              :number="classNumber" />
    <EditClass :id="editId"
               :isVisible="editDialog"
               @close="editDialog = false"
               @submit="fetchList(true)" />
    <GradeStatusChange :id="editId"
                       :isVisible="changeDialog"
                       @close="changeDialog = false"
                       @submit="fetchList(true)" />
    <ChooseStudent :id="ChooseStudentId"
                   :isVisible="chooseDialog"
                   @close="chooseDialog = false"
                   @submit="fetchList(true)" />
    <ClassStudentList :id="ClassStudentListId"
                      :isVisible="ClassStudentListDialog"
                      @close="ClassStudentListDialog = false"
                      @submit="fetchList()" />
    <ClassTeacherList :id="ClassTeacherListId"
                      :isVisible="ClassTeacherListDialog"
                      @close="ClassTeacherListDialog = false"
                      @submit="fetchList()"
                      :order_id="confirmOrder_id" />
    <Matching :id="matchId"
              :isVisible="matchDialog"
              @close="matchDialog = false"
              @submit="fetchList()" />
    <ConfirmList :ids="confirmIds"
                 :isVisible="confirmDialog"
                 :grade_id="grade_id"
                 :order_teachers="order_teachers"
                 :order_id="confirmOrder_id"
                 @close="confirmDialog = false"
                 @submit="fetchList()" />
    <Record :teacher_allocation_order_id="orderId"
            :isVisible="recordDialog"
            @close="recordDialog = false"
            @submit="fetchList"
            :grade_id="grade_id"
            :type="'grade_read'"></Record>

    <Log :isVisible="logDialog"
         @close="logDialog = false"
         @submit="fetchList"
         :grade_id="grade_id"></Log>

    <CreatePlanDialog ref="createPlanDialog"></CreatePlanDialog>
    <InstitutionDetails ref="institutionDetails"></InstitutionDetails>

    <ClassGrade ref="classGrade"
                @submit="fetchList()"></ClassGrade>
  </div>
</template>

<script>
import TableTittle from '../../../components/TableTittle/TableTittle.vue'
import AddClass from '@/components/DialogComponents/ClassService/AddClass.vue'
import EditClass from '@/components/DialogComponents/ClassService/EditClass.vue'
import ChooseStudent from '@/components/DialogComponents/ClassService/ChooseStudent.vue'
import ClassStudentList from '@/components/DialogComponents/ClassService/ClassStudentList.vue'
import GradeStatusChange from '@/components/DialogComponents/ClassService/GradeStatusChange.vue'
import Matching from '@/components/DialogComponents/TeacherResources/Matching.vue'
import Record from '@/components/DialogComponents/TeacherResources/Record.vue'
import CreatePlanDialog from '@/components/PlanComponents/CreatePlanDialog/index.vue'
import InstitutionDetails from '@/components/DialogComponents/InstitutionDetails/index.vue'

import ClassTeacherList from '@/components/DialogComponents/ClassService/ClassTeacherList.vue'
import ConfirmList from '@/components/DialogComponents/TeacherResources/ConfirmList.vue'
import ClassGrade from '../../../components/DialogComponents/ClassGrade/ClassGrade.vue'

import deviceLine from '../../../components/Line/Line.vue'
import Log from '@/components/DialogComponents/ClassService/Log.vue'
import {
  gradelist,
  gradeistDelete,
  gradeDelete,
  gradelistEdit,
  gradeSync,
  gradeSyncEdit,
  gradeEdit,
  gradeExport,
  followUpTagList,
  createuserfollow,
  deleteuserfollow,
  followUpTagAdd,
} from '@/request/api'
import { formateGradeInfo } from '@/utils/helper'
export default {
  components: {
    deviceLine,
    TableTittle,
    AddClass,
    EditClass,
    ChooseStudent,
    ClassStudentList,
    ClassTeacherList,
    GradeStatusChange,
    Matching,
    ConfirmList,
    Record,
    CreatePlanDialog,
    InstitutionDetails,
    ClassGrade,
    Log,
  },
  //  watch: {
  //   // 时间数据监听
  //   create_times(value) {
  //     if (value) {
  //       this.query.create_time = value[0] && this.$moment(value[0]).valueOf();
  //       console.log( this.query,'11111');
  //     } else {
  //       this.query.create_time = "";
  //       this.fetchList(true);
  //     }
  //   },
  // },
  data() {
    return {
      create_times: '',
      logDialog: false,
      TableName: '班级列表',
      activeName: 'first',
      confirmedCount: 0,
      filtrate: '1',
      tableData: [],
      createDialog: false,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        archive: '0',
      },
      pageTotal: 0,
      chooseList: [],
      editDialog: false,
      editId: null,
      ChooseStudentId: null,
      chooseDialog: false,
      ClassStudentListId: null,
      ClassStudentListDialog: false,
      ClassTeacherListDialog: false,
      ClassTeacherListId: null,
      timestampList: [
        {
          label: '1天内',
          value: 86400000,
          id: 1,
          type: 1,
        },
        {
          label: '7天内',
          value: 86400000 * 7,
          id: 2,
          type: 1,
        },
        {
          label: '超7天',
          value: 86400000 * 7,
          id: 3,
          type: 0,
        },
        {
          label: '超15天',
          value: 86400000 * 15,
          id: 4,
          type: 0,
        },
        {
          label: '超30天',
          value: 86400000 * 30,
          id: 5,
          type: 0,
        },
      ],
      filters: {
        archives: [
          {
            label: '全部',
            value: '',
          },
          {
            label: '已归档',
            value: '1',
          },
          {
            label: '未归档',
            value: '0',
          },
        ],
        types: [
          { label: '一对一', value: 0 },
          { label: '小班课', value: 1 },
          { label: '大班课', value: 2 },
          { label: '试听课', value: 3 },
        ],
        status: [
          { label: '正在对接', value: 0 },
          { label: '正在上课', value: 1 },
          { label: '课程终止', value: 2 },
          { label: '课程结束', value: 3 },
        ],
        apply_years: [
          { label: '2023考研', value: '2023' },
          { label: '2024考研', value: '2024' },
          { label: '2025考研', value: '2025' },
          { label: '2026考研', value: '2026' },
          { label: '2027考研', value: '2027' },
        ],
        grade_sync_statuses: [
          { label: '待匹配', value: 1 },
          { label: '匹配中', value: 2 },
          { label: '待确认', value: 3 },
          { label: '待上课', value: 4 },
          { label: '上课中', value: 5 },
          { label: '已过半', value: 6 },
          { label: '已终止', value: 7 },
          { label: '已结束', value: 8 },
        ],
      },
      create_time: '',
      classNumber: 1,
      unitConversion: 1,
      unitConversion2: 1,
      changeDialog: false,
      matchId: null,
      matchDialog: false,
      confirmIds: '',
      confirmDialog: false,
      grade_id: null,
      order_teachers: [],
      orderId: null,
      recordDialog: false,
      recordGradeId: null,
      confirmOrder_id: null,
      uncheck: 0,
      followUpTag: [],
    }
  },
  created() {
    this.parseQuery()
    this.fetchList()
    this.getFollowUpTagList()
    this.$store.dispatch('getAllColleges')
    this.$store.dispatch('getStaffOptions')
  },
  computed: {},

  methods: {
    planProgress(row) {
      if (
        row.grade_plan_data.plan_exist_lesson_hour &&
        row.grade_plan_data.plan_total_lesson_hour
      ) {
        return parseFloat(
          (
            (row.grade_plan_data.plan_exist_lesson_hour /
              row.grade_plan_data.plan_total_lesson_hour) *
            100
          ).toFixed(2)
        )
      } else {
        return 0
      }
    },
    handleToLogDetail(row) {
      window.open(`/classservice/student/record?grade_id=${row.id}`)
      // this.$router.push(`/classservice/student/record?grade_id=${row.id}`);
    },
    followUpRemark(e, row) {
      gradeEdit({
        id: row.id,
        follow_up_remark: e,
      }).then((res) => {
        // this.$message.success("修改成功");
        // this.fetchList();
      })
    },
    tagsChange(e, row) {
      let name = e[0].filter((item) => typeof item === 'string')
      let ids = e[0].filter((item) => typeof item === 'number') || []
      if (name && name.length > 0) {
        followUpTagAdd({
          tag: name[0],
          sort: 1,
        }).then((res) => {
          ids.push(res.data.data.insertId)
          this.getFollowUpTagList()
          gradeEdit({
            id: row.id,
            follow_up_tag_ids: ids.join(','),
          }).then((res) => {
            this.$message.success('修改成功')
            this.fetchList()
          })
        })
      } else {
        gradeEdit({
          id: row.id,
          follow_up_tag_ids: ids.join(','),
        }).then((res) => {
          this.$message.success('修改成功')
          this.fetchList()
        })
      }
    },
    followDelete(item) {
      let flag = false
      if (this.lock) {
        this.lock = false
        let query = {
          type: 8,
          follow_id: item.id,
        }
        deleteuserfollow(query).then((res) => {
          this.fetchList()
        })
        clearTimeout(flag)
        flag = setTimeout(() => {
          this.lock = true
        }, 300)
      }
    },
    followCreate(item) {
      let flag = false
      if (this.lock) {
        this.lock = false
        let query = {
          type: 8,
          follow_id: item.id,
        }
        createuserfollow(query).then((res) => {
          this.fetchList()
        })
        clearTimeout(flag)
        flag = setTimeout(() => {
          this.lock = true
        }, 500)
      }
    },
    getFollowUpTagList() {
      followUpTagList({ isPaging: 0 }).then((res) => {
        this.followUpTag = res.data.data.list || []
      })
    },
    handleClick(tab, event) {
      if (tab.name == 'first') {
        // this.query.is_statistic_data_table = "";
        this.fetchList(true)
      } else {
        // this.query.is_statistic_data_table = 1;
        // this.fetchList(true);
      }
    },
    filtrateChange(e) {
      this.query.follow = 0
      this.query.is_has_allocation_confirm = ''
      if (e == 2) {
        this.query.follow = 1
      } else if (e == 3) {
        this.query.is_has_allocation_confirm = 1
      }
      this.fetchList(true)
    },

    getTeachers(list) {
      if (list && list.length > 0) {
        return list
          .map((item) =>
            item.teacher_hide_name_status
              ? `${item.teacher_realame}(${item.teacher_nickname})`
              : item.teacher_realame
          )
          .join(',')
      }
      return '-'
    },
    institutionOpen(row) {
      this.$refs.institutionDetails.open(row.apply_college_code)
    },
    getTeacherName(list) {
      if (list.length == 1) {
        if (list[0].teacher_hide_name_status == 1) {
          return `${list[0].teacher_realame}(${list[0].teacher_nickname})`
        } else {
          return list[0].teacher_realame
        }
      } else if (list.length > 1) {
        if (list[0].teacher_hide_name_status == 1) {
          return `${list[0].teacher_realame}(${list[0].teacher_nickname})等`
        } else {
          return list[0].teacher_realame + '等'
        }
      }
    },
    fetchCheckCount() {
      gradelist({
        is_has_allocation_confirm: 1,
        isPaging: 0,
      }).then((response) => {
        let res = response.data
        this.uncheck = res.data.count
      })
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.fetchList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.fetchList()
    },
    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      const params = Object.assign({}, this.query)
      if (params.types) {
        params.types = params.types.join(',')
      }
      if (params.statuses) {
        params.statuses = params.statuses.join(',')
      }
      if (params.learn_managers) {
        params.learn_managers = params.learn_managers.join(',')
      }
      if (params.grade_sync_statuses) {
        params.grade_sync_statuses = params.grade_sync_statuses.join(',')
      }
      if (params.follow_up_tag_idses) {
        params.follow_up_tag_idses = params.follow_up_tag_idses.join(',')
      }
      if (this.create_times) {
        params.start_create_time = this.$moment(this.create_times[0]).valueOf()
        params.end_create_time = this.$moment(this.create_times[1]).valueOf()
      } else {
        params.start_create_time = ''
        params.end_create_time = ''
      }
      if (this.query.last_manage) {
        let obj = this.timestampList.find(
          (item) => item.id == this.query.last_manage
        )
        params.is_exist_manage_record = obj.type
        params.start_last_manage_record_time =
          this.$moment().unix() * 1000 - obj.value
        params.end_last_manage_record_time = this.$moment().unix() * 1000
      }
      delete params.last_manage

      console.log(params, 'params')
      gradelist(params).then((response) => {
        let res = response.data
        // this.tableData = res.data.list || [];
        this.tableData =
          res.data.count > 0
            ? res.data.list.map((v) => {
                return {
                  ...v,
                  follow_up_tag_ids:
                    v.follow_up_tag_ids && v.follow_up_tag_ids.length
                      ? v.follow_up_tag_ids.split(',').map((t) => Number(t))
                      : [],
                }
              })
            : []

        this.pageTotal = res.data.count
        this.fetchCheckCount()
      })
      this.syncQuery()
    },
    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key]
      })
      if (this.query.types && this.query.types.length) {
        this.query.types = this.query.types.split(',').map((v) => {
          return Number(v)
        })
      }
      if (this.query.statuses && this.query.statuses.length) {
        this.query.statuses = this.query.statuses.split(',').map((v) => {
          return Number(v)
        })
      }
      if (this.query.learn_managers && this.query.learn_managers.length) {
        this.query.learn_managers = this.query.learn_managers
          .split(',')
          .map((v) => {
            return Number(v)
          })
      }
      if (
        this.query.grade_sync_statuses &&
        this.query.grade_sync_statuses.length
      ) {
        this.query.grade_sync_statuses = this.query.grade_sync_statuses
          .split(',')
          .map((v) => {
            return Number(v)
          })
      }
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query)

      let isSync = Object.keys(params).some((key) => {
        this.$route.query[key] != params[key]
      })

      if (params.types) {
        params.types = params.types.filter((v) => v).join(',')
      }
      if (params.statuses) {
        params.statuses = params.statuses.filter((v) => v).join(',')
      }
      if (params.learn_managers) {
        params.learn_managers = params.learn_managers.filter((v) => v).join(',')
      }
      if (params.grade_sync_statuses) {
        params.grade_sync_statuses = params.grade_sync_statuses
          .filter((v) => v)
          .join(',')
      }

      console.log(Object.assign({}, this.$route.query, params))
      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        })
    },
    handleSelectionChange(val) {
      this.chooseList = val
    },
    formateType(type) {
      let types = {
        0: '一对一',
        1: '小班课',
        2: '大班课',
        3: '试听课',
      }
      return types[type]
    },
    formateMember(val) {
      // console.log("val:", val);
      if (val && val.length) {
        return val
          .map(
            (item) =>
              `${item.student_name}${
                item.archive_status == 1 && item.terminal_status == 1
                  ? '(归档且终止)'
                  : item.archive_status == 1
                  ? '(归档)'
                  : item.terminal_status == 1
                  ? '(终止)'
                  : ''
              }`
          )
          .join(',')
      } else {
        return '无'
      }
    },
    handleToGo(url, row) {
      this.$router.push(`${url}?class_id=${row.id}`)
    },
    handleToEdit(row) {
      this.editId = row.id
      this.editDialog = true
    },
    handleToDeleteAll() {
      if (this.chooseList.length == 0) {
        this.$message.error('还没有选择要删除的数据')
        return
      }
      if (
        this.chooseList.findIndex((v) => {
          return v.status != 0
        }) != -1
      ) {
        this.$message.error('仅可删除正在对接状态下的班级')
        return
      }
      this.$confirm('确定要删除这些信息吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let ids = this.chooseList.map((v) => {
          return v.id
        })
        gradeistDelete({ ids }).then((res) => {
          this.fetchList(this.query)
        })
      })
    },
    ChooseStudent(row) {
      if (row.student_ids) {
        this.ClassStudentListId = row.id
        this.ClassStudentListDialog = true
      } else {
        this.ChooseStudentId = row.id
        this.chooseDialog = true
      }
    },
    ChooseTeacher(row) {
      this.$refs.classGrade.open({
        id: row.id,
        confirmOrder_id: row.allocation_order_obj
          ? row.allocation_order_obj.id
          : null,
      })

      // this.ClassTeacherListId = row.id;
      // this.confirmOrder_id = row.allocation_order_obj
      //   ? row.allocation_order_obj.id
      //   : null;
      // this.ClassTeacherListDialog = true;
    },
    handleToRemove(id) {
      this.$confirm('确定要删除此班级吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        gradeDelete({ id }).then((res) => {
          this.fetchList(this.query)
        })
      })
    },
    openCreateDialog() {
      let sort = 1
      if (this.tableData.length) {
        sort = this.tableData[0].id + 1
      }
      this.classNumber = sort
      this.createDialog = true
    },
    handleToRecord(row) {
      window.open(`/classservice/record?grade_id=${row.id}`)
      /*    this.$router.push(`/classservice/record?grade_id=${row.id}`) */
    },

    handleToPlanCalendar(row) {
      this.$refs.createPlanDialog.open(row.id)
      // window.open(`/classservice/plan/calendar?grade_id=${row.id}`)

      /*   this.$router.push(`/classservice/plan/calendar?grade_id=${row.id}`) */
    },
    chooseAllEdit(archive_status, text) {
      if (this.chooseList.length == 0) {
        this.$message.error('还没有选择要修改的数据')
        return false
      }
      this.$confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let query = {
            rows: this.chooseList.map((v) => {
              return {
                id: v.id,
                archive: archive_status,
              }
            }),
          }
          gradelistEdit(query).then((res) => {
            this.fetchList()
          })
        })
        .catch(() => {
          this.$notify.error({
            title: '提示',
            message: '已取消操作',
          })
        })
    },
    handleCommand(e) {
      this.unitConversion = e
      this.$refs['popover'].doClose()
    },
    handleCommandOther(e) {
      this.unitConversion2 = e
      this.$refs['popover'].doClose()
    },
    handleToGradeRecord(row) {
      this.logDialog = true
      this.grade_id = row.id
      // this.$router.push(`/classservice/class/record/${row.id}`);
    },
    handleToSync(row) {
      gradeSync(formateGradeInfo(row, 'create')).then((res) => {
        if (res.data.data.message == 'ok') {
          gradeEdit({ id: row.id, old_id: res.data.data.data.id }).then(
            (resp) => {
              // console.log(resp);
              this.fetchList()
            }
          )
        } else {
          this.$message.error(res.data.data.message)
        }
      })
    },
    openChangeDialog(row) {
      this.editId = row.id
      this.changeDialog = true
    },
    formateProgress(row) {
      if (row.grade_plan_data) {
        let total = row.grade_plan_data.plan_total_lesson_hour
          ? Number(row.grade_plan_data.plan_total_lesson_hour)
          : 0
        let now = row.grade_plan_data.plan_exist_lesson_hour
          ? Number(row.grade_plan_data.plan_exist_lesson_hour)
          : 0
        return Number(((now / total) * 100).toFixed(2)) || 0
      } else {
        return 0
      }
    },
    handleExport() {
      this.$confirm('是否导出数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const params = Object.assign({}, this.query)
        if (params.types) {
          params.types = params.types.join(',')
        }
        if (params.statuses) {
          params.statuses = params.statuses.join(',')
        }
        if (params.learn_managers) {
          params.learn_managers = params.learn_managers.join(',')
        }
        if (this.create_times) {
          params.start_create_time = this.$moment(
            this.create_times[0]
          ).valueOf()
          params.end_create_time = this.$moment(this.create_times[1]).valueOf()
        } else {
          params.start_create_time = ''
          params.end_create_time = ''
        }
        gradeExport(params).then((res) => {
          if (res.data.code == 0) {
            this.$message.success('导出成功')
            //创建a标签用于跳转至下载链接
            // console.log("src",src)
            var str = res.data.data.url.replace(
              /http:/,
              window.location.protocol
            )
            this.$store.commit('downLoad', str)
          }
        })
      })
    },
    handleToMatching(row) {
      if (row.student_ids) {
        this.matchId = row.id
        this.matchDialog = true
      } else {
        this.$message.error('请选择上课学员')
      }
    },
    openConfirmList(row) {
      this.$refs.classGrade.open({
        id: row.id,
        confirmOrder_id: row.allocation_order_obj
          ? row.allocation_order_obj.id
          : null,
        type: '确认列表',
      })
      // this.grade_id = row.id;
      // this.confirmOrder_id = row.allocation_order_obj.id;
      // this.confirmDialog = true;
    },
    handleToOrderRecord(row) {
      this.orderId = row.allocation_order_obj.id
      this.recordGradeId = row.id
      this.recordDialog = true
    },
    chooseAllEditStatus() {
      if (this.chooseList.length == 0) {
        this.$message.error('还没有选择要修改的数据')
        return false
      }
      if (
        this.chooseList.findIndex((v) => {
          return [2, 3].includes(v.grade_sync_status)
        }) > -1
      ) {
        this.$message.error(
          '选中班级里有匹配中，待确认的状态，请先取消匹配后再终止班级！'
        )
        return false
      }
      this.$confirm('您确定要终止班级吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let query = {
            rows: this.chooseList.map((v) => {
              return {
                id: v.id,
                status: 2,
                // archive: 1,
              }
            }),
          }
          gradelistEdit(query).then((res) => {
            this.fetchList()
          })
        })
        .catch(() => {
          this.$notify.error({
            title: '提示',
            message: '已取消操作',
          })
        })
    },
    changeStatus(row) {
      this.$confirm('您确定要恢复班级吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let query = {
            id: row.id,
            status: 50,
          }
          gradeEdit(query).then((res) => {
            this.fetchList()
          })
        })
        .catch(() => {
          this.$notify.error({
            title: '提示',
            message: '已取消操作',
          })
        })
    },
  },
}
</script>

<style lang="less" scoped>
.card {
  /deep/.el-input__inner {
    border: none !important;
    background-color: transparent;
  }
}
.star {
  width: 18px;
  height: 18px;
}
.content {
  .operation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
  }
  .left {
    text-align: left;
  }
  .badge {
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
